import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { TargetEditNewComponent } from "@planard/admin/planning/guidance/target-edit/target-edit-new.component";
import { DeadlineAddComponent } from "@planard/admin/planning/guidance/deadline-edit/add/deadline-add.component";
import { PlanningCycleNameAddComponent } from "@planard/admin/planning/guidance/planning-cycle-name-edit/add/planning-cycle-name-add.component";
import { MatDialog } from "@angular/material/dialog";
import { GuidanceTarget } from "@planard/@core/entities/guidanceTarget";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { Guidance } from "@planard/@core/entities/guidance";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { InflationRateEditNewComponent } from "./inflation-rate-edit/inflation-rate-edit-new.component";
import { GuidanceFxRate } from "@planard/@core/entities/guidanceFxRate";
import { GuidanceInflationRate } from "@planard/@core/entities/guidanceInflationRate";
import { FaqService } from "@planard/@core/backend/service/faq.service";
import { Faq } from "@planard/@core/entities/faq";
import { KeyContactEditNewComponent } from "./key-contacts-edit/key-contact-edit-new.component";
import { GuidanceKeyContact } from "@planard/@core/entities/guidanceKeyContact";
import { FaqEditNewComponent } from "./faq-edit/faq-edit-new.component";
import { FxRateEditNewComponent } from "./fx-rate-edit/fx-rate-edit-new.component";
import { PlanningEditNewComponent } from "./planning-edit/planning-edit-new.component";
import { ToastrService } from "ngx-toastr";
import { GranularityAddComponent } from "./granularity/add/granularity-add.component";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { TranslateService } from "@ngx-translate/core";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { EnumTask } from "@planard/@core/enums/EnumTask";
import { Process } from "@planard/@core/entities/process";
import { RoleService } from "@planard/@core/backend/service/role.service";

@Component({
  templateUrl: "./guidance.component.html",
  styleUrls: ["./guidance.component.scss"],
  styles: [
    `
      .toRight {
        flex: 0 0 95%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class GuidanceComponent implements OnInit {
  loadingIndicator: boolean;
  loadingIndicator2: boolean;
  guidanceFxRateList: GuidanceFxRate[];
  guidanceInflationRateList: GuidanceInflationRate[];
  guidanceKeyContactList: GuidanceKeyContact[];
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  period: GuidancePlanningPeriod = new GuidancePlanningPeriod();
  guidanceTargetList: GuidanceTarget[];
  guidance: Guidance = new Guidance();
  currencyList: EnumCurrency[] = EnumCurrency.values;
  faqList: Faq[];
  guidanceList: Guidance[] = [];
  guidanceId: number;
  edit: boolean;
  copyGuidanceId: number;
  isRoleValid: boolean;
  package: string;
  numberFormat: string;
  decimalFormat: string;
  fxflexvalue: number;
  process: Process;
  adminRole;
  currency;
  scenarioLength;
  monthList = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  granularityList = [
    { value: "0", label: "Product" },
    { value: "1", label: "Product-Channel" },
    { value: "2", label: "Product-Form" },
    { value: "3", label: "Product-Form-Channel" },
  ];

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public companySetupService: CompanySetupService,
    public faqService: FaqService,
    public toastrService: ToastrService,
    private numberDecimal: NumberDecimalPipe,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public authService: AuthService,
    private router: Router,
    public scenarioService: ScenarioService,
    public translate: TranslateService,
    public timelineService: TimelineService,
    private roleService: RoleService,
    private guidanceService: GuidanceService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.package = this.authService.getCompanyPackage();

      if (this.package == "premium") this.fxflexvalue = 70;
      else this.fxflexvalue = 40;
    }, 150);
  }

  ngOnInit() {
    this.roleService.list().subscribe((resultList) => {
      resultList.forEach((element) => {
        this.adminRole = element.id;
      });
    });

    this.scenarioService.listAll().subscribe((data) => {
      this.scenarioLength = data.length;
    });
    this.companySetupService.get().subscribe((result: any) => {
      this.currency = EnumCurrency[`${result.currency}`]; //.displayValue;
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidance = result;
      this.guidanceId = result.id;

      if (this.guidance.start != null && this.package == "premium") {
        this.edit = false;
      } else {
        this.edit = true;
      }

      this.guidanceService
        .getPlanningRecord(this.guidance.id)
        .subscribe((result) => {
          this.guidancePlanningPeriodList = result;

          if (
            this.package == "basic" &&
            this.guidancePlanningPeriodList.length == 0
          ) {
            this.period.guidanceId = this.guidanceId;
            this.period.year = new Date().getFullYear();
            this.period.planningType = "Monthly";
            this.period.startMonth = new Date().getMonth();

            this.guidanceService
              .addPeriod(this.period)
              .subscribe((backendResult) => {
                if (backendResult.isSuccess) {
                  this.translate
                    .get(`messages.${backendResult.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.success(data);
                      this.refresh();
                    });
                } else {
                  this.translate
                    .get(`messages.${backendResult.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.error(data);
                    });
                }
              });

            this.guidance.planningCycleName = "Basic Plan1";
            this.guidanceService.updateCycleName(this.guidance).subscribe();

            this.autoProcessAndStartCycle();
          }
        });

      this.guidanceService
        .getTargetRecord(this.guidance.id)
        .subscribe((result) => {
          this.guidanceTargetList = result;
        });
      this.guidanceService
        .getKeyContact(this.guidance.id)
        .subscribe((result) => {
          this.guidanceKeyContactList = result;
        });
      this.guidanceService.getFxRate(this.guidance.id).subscribe((result) => {
        this.guidanceFxRateList = result;
      });
      this.guidanceService
        .getInflationRate(this.guidance.id)
        .subscribe((result) => {
          this.guidanceInflationRateList = result;
        });
      this.guidanceService.listAll().subscribe((result) => {
        this.guidanceList = [];
        result.forEach((element) => {
          if (element.id != this.guidanceId) {
            this.guidanceList.push(element);
          }
        });
        this.guidanceList = this.guidanceList.sort((a, b) =>
          a.id < b.id ? 1 : -1
        );
      });
    });
    this.faqService.listAll().subscribe((result) => {
      this.faqList = result;
    });
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  planningDialog() {
    const dialogRef = this.dialog.open(PlanningEditNewComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  targetDialog() {
    const dialogRef = this.dialog.open(TargetEditNewComponent, {
      width: "900px",
      data: {
        guidanceId: this.guidance.id,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  fxAddDialog() {
    const dialogRef = this.dialog.open(FxRateEditNewComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  deadlineAddDialog() {
    const dialogRef = this.dialog.open(DeadlineAddComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
        guidancePre: this.guidance.preSubmission,
        guidanceFinal: this.guidance.finalSubmission,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  granularityAddDialog() {
    const dialogRef = this.dialog.open(GranularityAddComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
        granularity: this.guidance.granularityType,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
        this.loadingIndicator = false;
      }
    });
  }

  inflationrateAddDialog() {
    const dialogRef = this.dialog.open(InflationRateEditNewComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
        //guidanceInf1: this.guidance.inflationRate1,
        //guidanceInf2: this.guidance.inflationRate2
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  planningCycleNameAddDialog() {
    const dialogRef = this.dialog.open(PlanningCycleNameAddComponent, {
      width: "700px",
      data: {
        guidanceId: this.guidance.id,
        guidanceName: this.guidance.planningCycleName,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  keycontactsAddDialog() {
    const dialogRef = this.dialog.open(KeyContactEditNewComponent, {
      width: "900px",
      data: {
        guidanceId: this.guidance.id,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  faqAddDialog() {
    const dialogRef = this.dialog.open(FaqEditNewComponent, {
      width: "900px",
      data: {
        guidanceId: this.guidance.id,
      },
    });
    this.loadingIndicator = true;
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
      this.loadingIndicator = false;
    });
  }

  getMonth(month: number) {
    if (month != null) {
      for (let mt of this.monthList) {
        if (month == mt.value) {
          return mt.label;
        }
      }
    }
  }

  refresh() {
    this.guidanceService.onGuidanceReload.emit();
    this.changeDetectorRefs.detectChanges();
    this.ngOnInit();
  }

  getCycleStart(start: any) {
    var current: any = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    current = new Date(
      (typeof current === "string"
        ? new Date(current)
        : current
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    var start: any = new Date(
      (typeof start === "string" ? new Date(start) : start).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
    if (current < start) {
      return true;
    } else {
      return false;
    }
  }

  startCycle() {
    this.translate.get(`guidance.start`).subscribe((data: any) => {
      if (
        this.guidance.planningCycleName != null &&
        this.guidancePlanningPeriodList.length != 0
      ) {
        if (this.scenarioLength < 2) {
          this.translate.get(`messages.162`).subscribe((result: any) => {
            const dialogRef0 = this.dialog.open(ConfirmationDialogComponent, {
              width: "350px",
              data: result,
            });
            dialogRef0.afterClosed().subscribe((result) => {
              if (result) {
                this.loadingIndicator2 = true;
                let body = { guidanceId: this.guidanceId };
                this.guidanceService.startGuidance(body).subscribe((result) => {
                  if (result.isSuccess) {
                    this.translate
                      .get(`messages.${result.messageId}`)
                      .subscribe((data: any) => {
                        this.toastrService.success(data);
                      });
                    this.translate
                      .get(`guidance.msg2`)
                      .subscribe((data: any) => {
                        this.toastrService.warning(data);
                      });
                    this.ngOnInit();
                    this.loadingIndicator2 = false;
                  } else {
                    this.translate
                      .get(`messages.${result.messageId}`)
                      .subscribe((data: any) => {
                        this.toastrService.error(data);
                      });
                    this.loadingIndicator2 = false;
                  }
                });
              } else {
                this.dialog.closeAll();
              }
            });
          });
        } else {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "350px",
            data: data,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.loadingIndicator2 = true;
              let body = { guidanceId: this.guidanceId };
              this.guidanceService.startGuidance(body).subscribe((result) => {
                if (result.isSuccess) {
                  this.translate
                    .get(`messages.${result.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.success(data);
                    });
                  this.translate.get(`guidance.msg2`).subscribe((data: any) => {
                    this.toastrService.warning(data);
                  });
                  this.ngOnInit();
                  this.loadingIndicator2 = false;
                } else {
                  this.translate
                    .get(`messages.${result.messageId}`)
                    .subscribe((data: any) => {
                      this.toastrService.error(data);
                    });
                  this.loadingIndicator2 = false;
                }
              });
            } else {
              this.dialog.closeAll();
            }
          });
        }
      } else {
        this.translate.get(`guidance.msg3`).subscribe((data: any) => {
          this.toastrService.warning(data);
        });
      }
    });
  }

  endCycle() {
    this.translate.get(`guidance.end`).subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let body = { guidanceId: this.guidanceId };
          this.guidanceService.endGuidance(body).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.router.navigate(["admin-planning/guidance"]);
              //window.location.reload(); //testte bunda sıkıntı oluyor sanırım
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        }
      });
    });
  }

  copyGuidance() {
    this.translate.get(`guidance.copysure`).subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.edit == false) {
            this.translate.get("guidance.msg1").subscribe((data: any) => {
              this.toastrService.error(data);
            });
            return;
          }

          let body = { GuidanceId: this.copyGuidanceId };
          this.guidanceService.copyGuidance(body).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                  this.ngOnInit();
                });
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        }
      });
    });
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }

  startCycleAuto() {
    this.loadingIndicator2 = true;
    let body = { guidanceId: this.guidanceId };
    this.guidanceService.startGuidance(body).subscribe((result) => {
      if (result.isSuccess) {
        this.ngOnInit();
        this.loadingIndicator2 = false;
      } else {
        this.loadingIndicator2 = false;
      }
    });
  }

  autoProcessAndStartCycle() {
    let processlist = new Array(
      EnumTask.PRICE_PLANNING,
      EnumTask.COST_PLANNING,
      EnumTask.FORECAST_PLANNING
    );
    for (let i = 0; i < processlist.length; i++) {
      this.process = new Process();
      this.process.selectedProcessRoles = [this.adminRole];
      this.process.startDate = new Date();
      this.process.endDate = new Date("2222-12-31 00:00:00");
      this.process.daysToExecute = 99999;
      this.process.task = processlist[i];
      this.timelineService.add(this.process).subscribe();
    }
    this.startCycleAuto();
  }
}
