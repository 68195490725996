import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ProcessResult } from "@planard/@core/entities/process-result";
import { MatTableDataSource } from "@angular/material/table";
import { Role } from "@planard/@core/entities/role";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { RolesAddComponent } from "@planard/admin/general/roles/add/roles-add.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { EnumResponsibility } from "@planard/@core/enums/EnumResponsibility";
import { EnumReportAccess } from "@planard/@core/enums/EnumReportAccess";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
@Component({
  templateUrl: "./roles.component.html",
  styles: [
    `
      .MakeSmaller {
        flex: 0 0 15%;
      }
    `,
  ],
})
export class RolesComponent implements OnInit {
  editing = {};
  rows = [];

  processResult: ProcessResult;
  loadingIndicator: boolean = true;
  responbilityList: EnumResponsibility[] = EnumResponsibility.values;
  reportAccessList: EnumReportAccess[] = EnumReportAccess.values;

  displayedColumns = ["name", "responsibility", "reportaccess"];
  isRoleValid: boolean;
  isCompanyPackageBasic: boolean;

  admin = {
    resp: "All",
    reportABase: "All",
    reportAPremium: "",
  };
  pricePlanner = {
    resp: "Dashboard, Presenter, Instructions, Price & Discount entry, Risk & Opps",
    reportABase: "Price Trend, Economic reports",
    reportAPremium: ", Launch Dates, Risk & Opportunities",
  };
  centralCostPlanner = {
    resp: "Dashboard, Presenter, Instructions, Central Cost plannig, Cost Narrative",
    reportABase: "",
    reportAPremium: "Economic reports",
  };
  businessUnitPlanner = {
    resp: "Dashboard, Presenter, Instructions, Sales Forecast planning, Risk & Opps, Sales Narrative",
    reportABase:
      "Sales Overview, Product Overview, Channel Overview, Sales Trend, Price Trend, Actual vs Plan, Scenario Comparison, Economic Reports",
    reportAPremium:
      ", Growth Drivers, Sales Bridge (Narratives), Price-Volume, Launch Dates, Risk & Opportunities",
  };
  businessUnitApprover = {
    resp: "Dashboard, Presenter, Instructions, Sales Forecast planning, Sales Forecast approval, Risk & Opps, Sales Narrative",
    reportABase:
      "Sales Overview, Product Overview, Channel Overview, Sales Trend, Price Trend, Actual vs Plan, Scenario Comparison, Economic Reports, Growth Drivers, Sales Bridge (Narratives), Price-Volume, Launch Dates, Risk & Opportunities",
    reportAPremium: "",
  };
  supportFunctionPlanner = {
    resp: "Dashboard, Presenter, Instructions, Cost planning, Cost Narrative",
    reportABase:
      "Cost Overview, Cost Trend, Scenario Comparison, Economic Reports",
    reportAPremium: ", Opex Bridge (Narratives), Risk & Opportunities",
  };
  supportFunctionApprover = {
    resp: "Dashboard, Presenter, Instructions, Cost planning, Cost Approval, Cost Narrative",
    reportABase:
      "Cost Overview, Cost Trend, Scenario Comparison, Economic Reports, Opex Bridge (Narratives), Risk & Opportunities",
    reportAPremium: "",
  };

  dataSource = new MatTableDataSource<Role>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public roleService: RoleService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public authService: AuthService,
    private router: Router,
    public toastrService: ToastrService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      this.isCompanyPackageBasic =
        this.authService.getCompanyPackage() == "basic";
      if (this.isRoleValid == false || this.isCompanyPackageBasic)
        this.router.navigate(["auth/404"]);
    }, 150);

    this.roleService.onRoleReload.subscribe(() => {
      this.getData();
    });
  }

  ngOnInit() {
    this.getData();
    setTimeout(() => {
      this.loadingIndicator = false;
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;
      }
    }, 1500);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getData() {
    this.roleService.list().subscribe((data) => {
      let roleList = [];
      data.forEach((element) => {
        if (element.name != "Super Admin") {
          roleList.push(element);
        }
      });
      this.dataSource = new MatTableDataSource(roleList);
      this.rows = data;
    });
  }

  addDialog() {
    const dialogRef = this.dialog.open(RolesAddComponent, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.push(result);
        this.refresh();
      }
    });
  }

  refresh() {
    this.rows = [...this.rows];
    this.roleService.onRoleReload.emit();
    this.changeDetectorRef.detectChanges();
    this.dataSource.data = [...this.dataSource.data];
  }
}
