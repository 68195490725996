<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div
            class="div"
            style="width: 30%"
            *ngIf="granularity != 0 && granularity != null"
          >
            <mat-card-title
              >{{ "reports.costBridge" | translate }}<br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <div
            class="div"
            style="width: 40%"
            *ngIf="granularity == 0 || granularity == null"
          >
            <mat-card-title
              >{{ "reports.costBridge" | translate }}<br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <div class="div" style="width: 15%">
            <mat-form-field style="width: 90%">
              <mat-select
                [(ngModel)]="requestId"
                placeholder="{{ 'reports.narrativeRequest' | translate }}"
                [formControl]="requestControl"
                required
              >
                <mat-option
                  *ngFor="let cost of costRequestList"
                  [value]="cost.id"
                  value="option"
                >
                  <strong>{{ cost.firstPlanningCycleName }}</strong
                  >&nbsp;{{ cost.firstYear }}
                  &nbsp;&nbsp;&nbsp;vs&nbsp;&nbsp;&nbsp;
                  <strong>{{ cost.secondPlanningCycleName }}</strong
                  >&nbsp;{{ cost.secondYear }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="requestControl.hasError('required')">{{
                "reports.narrativeRequestChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 15%">
            <mat-form-field style="width: 90%">
              <mat-select
                [(ngModel)]="categoryId"
                [formControl]="categoryControl"
                required
                placeholder="{{ 'costNarrative.costCategory' | translate }}"
              >
                <mat-option
                  *ngFor="let o of costCategoryList"
                  [value]="o.id"
                  value="option"
                >
                  <span *ngIf="'category.' + o.name as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="categoryControl.hasError('required')">{{
                "reports.narrativeRequestCatChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="granularity"
                name="granularity"
                placeholder="{{ 'reports.granularity' | translate }}"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div
            class="div"
            style="width: 10%"
            *ngIf="granularity == 1 && (!isAdmin || !isApprover)"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="departmentId"
                name="departmentId"
                placeholder="{{ 'reports.department' | translate }}"
                [formControl]="depControl"
                required
              >
                <mat-option
                  *ngFor="let o of departmentList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="depControl.hasError('required')">{{
                "reports.departmentChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div
          class="div"
          style="width: 10%"
          *ngIf="granularity == 1 && isAdmin && isApprover"
        >
          <mat-form-field style="width: 85%">
            <mat-select
              [(ngModel)]="departmentId"
              name="departmentId"
              placeholder="{{ 'reports.department' | translate }}"
              [formControl]="depControl"
              required
              multiple
            >
              <mat-option
                *ngFor="let o of departmentList"
                [value]="o.id"
                value="option"
              >
                {{ o.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="depControl.hasError('required')">{{
              "reports.departmentChoose" | translate
            }}</mat-error>
          </mat-form-field>
        </div>

          <div
            class="div"
            style="width: 10%"
            *ngIf="granularity == 2 && (!isAdmin || !isApprover)"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="subDepartmentId"
                name="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subControl"
                required
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="subControl.hasError('required')">{{
                "reports.subdepartment" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div
            class="div"
            style="width: 10%"
            *ngIf="granularity == 2 && isAdmin && isApprover"
          >
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="subDepartmentId"
                name="subDepartmentId"
                placeholder="{{ 'reports.subdepartment' | translate }}"
                [formControl]="subControl"
                multiple
                required
              >
                <mat-option
                  *ngFor="let o of subDeptList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="subControl.hasError('required')">{{
                "reports.subdepartment" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div>
          <div class="div" style="width: 15%"></div>

          <div class="div" style="width: 70%" #screen>
            <canvas
              *ngIf="isRun == true"
              baseChart
              [datasets]="barChartData"
              [plugins]="waterFallPlugin"
              [labels]="reportData.chartLabels"
              [options]="barChartOptions"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.run" | translate
        }}</span>

        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reports.reset" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">
            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title>Cost Bridge</mat-card-title>
              </div>
            </div>
            <br>
            <br>
            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
