import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { FxRate } from "@planard/@core/entities/fxRate";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { PlanService } from "@planard/@core/backend/service/plan.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { CostType } from "@planard/@core/entities/costType";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";

@Component({
  selector: "cost-trend",
  templateUrl: "./cost-trend.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe],
})
export class CostTrendComponent implements OnInit {
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];

  //Lists
  currencyList: EnumCurrency[] = EnumCurrency.values;
  planningPeriodList: GuidancePlanningPeriod[];
  scenarioList: Scenario[];
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  costList: CostType[];
  costFilteredList: CostType[] = [];

  categoryList: Category[];
  subcategoryList: SubCategory[];
  subcategoryCatList: SubCategory[] = [];
  isRoleValid: boolean;
  //Values
  fullData: any;
  years: any;
  scenarioId;
  selectedYear: number;
  timescale: number = 0;
  currency: any = 0;
  indicator: any = "Units";
  rate: number = 1;
  img = "";
  currencyName: string;
  guidanceId: number;
  period: any;
  reportScreenshot: ReportScreenshot = {};
  isRun: boolean;
  max: number;
  min: number;
  subDeptList: any[];
  subDepartmentId = 0;
  granularity = 0;
  granularityList = [
    { value: 0, label: "Total Company" },
    { value: 1, label: "Sub Department" },
  ];
  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [
    //{ backgroundColor: 'red' }
  ];

  isAdmin;
  isApprover;

  numberFormat: string;
  decimalFormat: string;

  companyCurrency: string;
  companyNumberFormat: string;
  package: string;
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  periodGroup: any;

  timescaleList = [
    { value: 0, label: "Monthly" },
    { value: 1, label: "Yearly" },
  ];

  categoryId: number;
  subcategoryId: number = 0;
  guidanceIsStart: boolean = null;
  costId;

  actualsName;
  currentPlanName;
  isCurrenctYearExist = false;

  userSubDeptId = null;

  fullYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  scenarioControl = new FormControl("", [Validators.required]);
  catControl = new FormControl("", [Validators.required]);
  subControl = new FormControl("", [Validators.required]);
  costControl = new FormControl("", [Validators.required]);
  scaleControl = new FormControl("", [Validators.required]);
  periodControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);
  subDeptControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);

  @ViewChild("screen", { static: true }) screen: any;
  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public costService: CostService,
    private numberDecimal: NumberDecimalPipe,
    public planService: PlanService,
    public actualsService: ActualsService,
    public translate: TranslateService,
    private router: Router,
    public authService: AuthService,
    public accountsService: AccountsService,
    public reportScreenshotService: ReportScreenshotService,
    public guidanceService: GuidanceService,
    public departmentService: DepartmentService,
    private captureService: NgxCaptureService,
    public productsService: ProductsService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      var companyPackage = this.authService.getCompanyPackage();

      if (companyPackage == "basic")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isSupportFunctionPlanner();
      if (companyPackage == "premium")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isSupportFunctionApprover() ||
          this.authService.isSupportFunctionPlanner();

      this.isAdmin = this.authService.isAdmin();
      this.isApprover = this.authService.isApprover();
      this.package = this.authService.getCompanyPackage();
      this.departmentService
        .listAllSub /*planService.listApproverUsersSubDepartments*/
        ()
        .subscribe((result: any) => {
          this.subDeptList = result;
        });

      if (!this.isApprover && !this.isAdmin) {
        this.accountsService.getSignedUser().subscribe((result: any) => {
          this.subDeptList.forEach((element) => {
            if (element.id == result.subDepartmentId)
              this.subDeptList = [element];
          });
        });

        this.granularityList = [{ value: 1, label: "Sub Department" }];
      } else {
        this.granularityList = [
          { value: 0, label: "Total Company" },
          { value: 1, label: "Sub Department" },
        ];
      }

      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.companyNumberFormat =
        this.authService.getNumberFormat() == "noScale"
          ? ""
          : this.authService.getNumberFormat() == "thousands"
          ? "k"
          : this.authService.getNumberFormat() == "millions"
          ? "m"
          : "n/a";
      this.companyCurrency = this.authService.getCompanyCurrency();
    }, 150);
  }

  ngOnInit() {
    this.scenarioService
      .listScenariosWithImpact("C")
      .subscribe((result: any) => {
        this.scenarioList = result;
        result.forEach((element) => {
          if (element.isBase == true) {
            this.scenarioId = element.id;
          }
        });
      });
    this.costService.listCategories().subscribe((result: any) => {
      this.categoryList = result;
    });
    this.costService.listSubcategories().subscribe((result: any) => {
      this.subcategoryList = result;
    });
    this.costService.listAll().subscribe((result: any) => {
      this.costList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      var guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceIsStart = result.start ? true : false;
      if (this.guidanceIsStart) {
        this.guidanceService
          .getPlanningRecord(result.id)
          .subscribe((result2: any) => {
            this.planningPeriodList = result2;
            result2.forEach((element) => {
              if (element.year == this.fullYear)
                this.isCurrenctYearExist = true;
              let ele = {
                value: element.id,
                label: `${guidanceName}'${element.year}`,
                isActuals: false,
              };
              this.currentPlanPeriodList.push(ele);
            });
            this.setPeriod();
          });
        this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
          this.guidanceFxRateList = data;
        });
      }
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
  }

  setPeriod() {
    //actuals yılları
    this.actualsService.listFilePeriod("Cost").subscribe((data) => {
      // central cosr actualleri dahil edilmemilş ??
      data.forEach((element) => {
        //if ( element.period == this.fullYear && this.isCurrenctYearExist ) return;
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });

    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    }, 1000);
  }

  setSubcategoryCat() {
    this.subcategoryCatList.length = 0;
    for (let index in this.subcategoryList) {
      if (this.categoryId == this.subcategoryList[index].categoryId) {
        this.subcategoryCatList.push(this.subcategoryList[index]);
      }
    }
  }

  setCost() {
    this.costFilteredList = [];
    if (this.subcategoryId == 0) return;
    for (let index in this.costList) {
      if (this.subcategoryId == this.costList[index].subCategoryId) {
        this.costFilteredList.push(this.costList[index]);
      }
    }
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  getCategory(id) {
    for (let sc of Object.keys(this.categoryList)) {
      if (id == this.categoryList[sc].id) {
        return this.categoryList[sc].name;
      }
    }
  }

  getSubCategory(id) {
    for (let sc of Object.keys(this.subcategoryList)) {
      if (id == this.subcategoryList[sc].id) {
        return this.subcategoryList[sc].name;
      }
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(
      this.scenarioId
    )} / ${this.getCategory(this.categoryId)} / ${
      this.subcategoryId == 0
        ? "All Sub Categories"
        : this.getSubCategory(this.subcategoryId)
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Cost Trend";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.catControl.valid == false ||
      this.subControl.valid == false ||
      this.scaleControl.valid == false ||
      this.currencyControl.valid == false ||
      (this.subcategoryId != 0 && this.costControl.valid == false) ||
      (this.granularity != 0 && this.subDeptControl.valid == false)
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.catControl.valid == true &&
      this.subControl.valid == true &&
      this.scaleControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      if (this.timescale == 0) {
        if (this.periodControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      } else {
        this.run();
      }
    }
  }

  run() {
    this.isRun = false;
    var actuals: any;
    var periodIds: any;

    if (this.period != null) {
      if (this.period.isActuals == true) {
        actuals = this.period.value;
        periodIds = 0;
      } else {
        actuals = 0;
        periodIds = this.period.value;
      }
    }

    this.barChartData = [];
    this.reportData.chartLabels = [];
    this.barChartOptions = {};

    if (this.timescale == 1) {
      actuals = 0;
      periodIds = 0;
    }
    if (this.granularity == 0) this.subDepartmentId = 0;
    if (this.categoryId == 0) {
      this.subcategoryId = 0;
      this.costId = 0;
    }
    if (this.subcategoryId == 0) this.costId = 0;

    let colors = [
      "darkblue",
      "lightgreen",
      "red",
      "blue",
      "purple",
      "orange",
      "yellow",
      "pink",
      "darkgreen",
      "brown",
      "DarkTurquoise",
      "rose",
      "grey",
    ];

    this.planService
      .costTrend(
        this.guidanceIsStart ? this.scenarioId : 0,
        this.categoryId,
        this.subcategoryId,
        this.timescale,
        actuals,
        periodIds,
        this.currency,
        this.subDepartmentId,
        this.costId
      )
      .subscribe((result) => {
        if (!result.data[0]) {
          this.translate.get("reports.warning3").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }

        let min = result.data[0].line[0];
        let max = result.data[0].line[0];

        for (let index in result.data) {
          for (let ind in result.data[index].line) {
            //if ( result.data[index].line[ind] < min){
            //min = result.data[index].line[ind];
            //}
            if (result.data[index].line[ind] > max) {
              max = result.data[index].line[ind];
            }
            result.data[index].line[ind] =
              result.data[index].line[ind].toFixed(2);
          }
        }

        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;

        this.reportData.chartLabels = result.chartLabels;
        for (let index in result.data) {
          this.barChartData.push({
            type: "line",
            fill: false,
            borderWidth: 2,
            data: result.data[index].line,
            label: `${
              result.data[index].label != ""
                ? result.data[index].label
                : this.costFilteredList.find((x) => x.id === this.costId).name
            }`,
            backgroundColor: `${colors[index]}`,
            borderColor: `${colors[index]}`,
          });
        }

        //setTimeout(() => {
        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            mode: "index",
            intersect: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: min,
                  suggestedMax: max + 10,
                },
              },
            ],
          },
          title: {
            display: true,
            text: "Cost Trend",
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              display: "auto",
              color: "red",
              align: "top",
              formatter: function (value, context) {
                //if ( indicator == 1 )

                return numberDecimal.transform(
                  Number(value),
                  decimalFormat,
                  numberFormat
                );
                //return value ;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
          },
        };
        //}, 1000);

        //setTimeout(() => {
        this.isRun = true;
        //}, 1500);
      });
  }
}
