import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { PriceService } from "@planard/@core/backend/service/price.service";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { Channel } from "@planard/@core/entities/channel";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./price-trend.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class PriceTrendComponent implements OnInit {
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];
  currencyList: EnumCurrency[] = EnumCurrency.values;
  productList: Products[];
  product2List: Products[];
  scenarioProductList: Products[] = [];
  scenarioList: Scenario[];
  planningPeriodList: GuidancePlanningPeriod[];
  chartData3: number[];
  chartData2: number[];
  chartData1: number[];
  fullData: any;
  scenarioId: number;
  productId: number;
  period: any;
  data: any;
  isRun: boolean;
  imgName: any;
  imgDescription: any;
  guidanceId: number;
  currency: number = 0;
  guidanceIsStart: boolean;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  outliner: any;
  reportScreenshot: ReportScreenshot = {};
  indicatorList = ["Gross Price", "Discount", "Net Price"];
  outlinerList = ["Include", "Exclude"];
  periodList = [2020, 2021, 2022, 2023, 2024];
  scenarioName: string;
  productName: string;
  currencyName: string;
  img = "";
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  periodGroup: any;
  formList: ProductForm[] = [];
  channelList: Channel[] = [];
  formId: any = null;
  channelId: any = null;
  isRoleValid: boolean;
  companyPackage: string;
  companyCurrency: string;
  companyNumberFormat: string;

  discounts;
  prices;
  netprices;

  actualsName;
  currentPlanName;

  isCurrenctYearExist = false;

  fullYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [];

  @ViewChild("screen", { static: true }) screen: any;

  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public guidanceService: GuidanceService,
    public priceService: PriceService,
    public actualsService: ActualsService,
    public authService: AuthService,
    public channelService: ChannelService,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    public translate: TranslateService,
    private router: Router,
    public productsService: ProductsService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      var companyPackage = this.authService.getCompanyPackage();

      if (companyPackage == "basic")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isPricePlanner() ||
          this.authService.isBusinessUnitPlanner();

      if (companyPackage == "premium")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isPricePlanner() ||
          this.authService.isBusinessUnitApprover() ||
          this.authService.isBusinessUnitPlanner();

      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

      this.companyNumberFormat =
        this.authService.getNumberFormat() == "noScale"
          ? ""
          : this.authService.getNumberFormat() == "thousands"
          ? "k"
          : this.authService.getNumberFormat() == "millions"
          ? "m"
          : "n/a";
      this.companyCurrency = this.authService.getCompanyCurrency();
    }, 150);

    this.translate.get("reports.prices").subscribe((data: any) => {
      this.prices = data;
    });
    this.translate.get("reports.netPrices").subscribe((data: any) => {
      this.netprices = data;
    });
    this.translate.get("reports.discounts").subscribe((data: any) => {
      this.discounts = data;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.prices").subscribe((data: any) => {
        this.prices = data;
      });
      this.translate.get("reports.netPrices").subscribe((data: any) => {
        this.netprices = data;
      });
      this.translate.get("reports.discounts").subscribe((data: any) => {
        this.discounts = data;
      });
    });
  }

  scenarioControl = new FormControl("", [Validators.required]);
  productControl = new FormControl("", [Validators.required]);
  formControl = new FormControl("", [Validators.required]);
  channelControl = new FormControl("", [Validators.required]);
  periodControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.scenarioService
      .listScenariosWithImpact("P")
      .subscribe((result: any) => {
        this.scenarioList = result;
        result.forEach((element) => {
          if (element.isBase == true) {
            this.scenarioId = element.id;
            this.setScenarioLists();
          }
        });
      });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      var guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceIsStart = result.start ? true : false;
      if (this.guidanceIsStart) {
        this.guidanceService
          .getPlanningRecord(result.id)
          .subscribe((result2: any) => {
            this.planningPeriodList = result2;
            result2.forEach((element) => {
              if (element.year == this.fullYear)
                this.isCurrenctYearExist = true;
              let ele = {
                value: element.id,
                label: `${guidanceName}'${element.year}`,
                isActuals: false,
              };
              this.currentPlanPeriodList.push(ele);
            });
            this.setYears();
          });

        this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
          this.guidanceFxRateList = data;
        });
      }
    });
  }

  setYears() {
    this.actualsService.listFilePeriod("price").subscribe((data) => {
      data.forEach((element) => {
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });

    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName,
          period: this.actualsList,
        },
        {
          name: this.currentPlanName,
          period: this.currentPlanPeriodList,
        },
      ];
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName,
          period: this.actualsList,
        },
        {
          name: this.currentPlanName,
          period: this.currentPlanPeriodList,
        },
      ];
      this.period = this.currentPlanPeriodList[0];
    }, 1000);
  }

  setScenarioLists() {
    this.priceService
      .listPriceProducts(this.scenarioId)
      .subscribe((result: any) => {
        this.productList = result;
        this.product2List = [...result];
        if (result.length == 0) {
          this.translate.get("reports.warning7").subscribe((data: any) => {
            this.toastr.error(data);
          });
        }
      });
  }

  setForm() {
    // productın formları
    this.formId = null;
    this.formList = [];
    this.priceService
      .listPriceForms(this.scenarioId, this.productId)
      .subscribe((result: any) => {
        this.formList = result;
        if (!this.formList[0]) {
          this.translate.get("reports.warning10").subscribe((data: any) => {
            this.toastr.error(data);
          });
        }
      });
  }

  setChannel() {
    // productın channeları
    this.channelId = null;
    this.channelList = [];
    this.priceService
      .listPriceChannels(this.scenarioId, this.productId)
      .subscribe((result: any) => {
        this.channelList = result;
        if (!this.channelList[0]) {
          this.translate.get("reports.warning13").subscribe((data: any) => {
            this.toastr.error(data);
          });
        }
      });
  }

  isValid() {
    if (this.productId && !this.formList[0]) {
      this.translate.get("reports.warning10").subscribe((data: any) => {
        this.toastr.error(data);
      });
    }

    if (this.productId && !this.channelList[0]) {
      this.translate.get("reports.warning13").subscribe((data: any) => {
        this.toastr.error(data);
      });
    }

    if (
      this.scenarioControl.valid == false ||
      this.productControl.valid == false ||
      this.formControl.valid == false ||
      this.channelControl.valid == false ||
      this.periodControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.productControl.valid == true &&
      this.formControl.valid == true &&
      this.channelControl.valid == true &&
      this.periodControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    this.isRun = false;

    var actuals: any;
    var periodIds: any;

    if (this.period != null) {
      if (this.period.isActuals == true) {
        actuals = this.period.value;
        periodIds = 0;
      } else {
        actuals = 0;
        periodIds = this.period.value;
      }
    }

    this.barChartData = [];
    this.reportData.chartLabels = [];
    this.barChartOptions = {};

    this.priceService
      .listPriceTrend(
        this.guidanceIsStart ? this.scenarioId : 0,
        this.productId,
        this.formId,
        this.channelId,
        actuals,
        periodIds,
        this.currency
      )
      .subscribe((result) => {
        if (
          result.price.length == 0 ||
          result.discount.length == 0 ||
          result.netPrice.length == 0
        ) {
          this.translate.get("reports.warning3").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }

        let min = result.price[0];
        let max = result.price[0];

        let minDiscount = result.discount[0];
        let maxDiscount = result.discount[0];

        for (let index in result.price) {
          if (result.price[index] < min) {
            min = result.price[index];
          }
          if (result.price[index] > max) {
            max = result.price[index];
          }
        }
        for (let index in result.netPrice) {
          if (result.netPrice[index] < min) {
            min = result.netPrice[index];
          }
          if (result.netPrice[index] > max) {
            max = result.netPrice[index];
          }
        }
        for (let index in result.discount) {
          if (result.discount[index] < minDiscount) {
            minDiscount = result.discount[index];
          }
          if (result.discount[index] > maxDiscount) {
            maxDiscount = result.discount[index];
          }
        }

        this.chartData1 = result.price;
        this.chartData2 = result.discount;
        this.chartData3 = result.netPrice;

        this.chartData1 = [
          Number(result.price[0].toFixed(2)),
          Number(result.price[1].toFixed(2)),
          Number(result.price[2].toFixed(2)),
          Number(result.price[3].toFixed(2)),
          Number(result.price[4].toFixed(2)),
          Number(result.price[5].toFixed(2)),
          Number(result.price[6].toFixed(2)),
          Number(result.price[7].toFixed(2)),
          Number(result.price[8].toFixed(2)),
          Number(result.price[9].toFixed(2)),
          Number(result.price[10].toFixed(2)),
          Number(result.price[11].toFixed(2)),
        ];
        this.chartData2 = [
          Number(result.discount[0].toFixed(2)),
          Number(result.discount[1].toFixed(2)),
          Number(result.discount[2].toFixed(2)),
          Number(result.discount[3].toFixed(2)),
          Number(result.discount[4].toFixed(2)),
          Number(result.discount[5].toFixed(2)),
          Number(result.discount[6].toFixed(2)),
          Number(result.discount[7].toFixed(2)),
          Number(result.discount[8].toFixed(2)),
          Number(result.discount[9].toFixed(2)),
          Number(result.discount[10].toFixed(2)),
          Number(result.discount[11].toFixed(2)),
        ];
        this.chartData3 = [
          Number(result.netPrice[0].toFixed(2)),
          Number(result.netPrice[1].toFixed(2)),
          Number(result.netPrice[2].toFixed(2)),
          Number(result.netPrice[3].toFixed(2)),
          Number(result.netPrice[4].toFixed(2)),
          Number(result.netPrice[5].toFixed(2)),
          Number(result.netPrice[6].toFixed(2)),
          Number(result.netPrice[7].toFixed(2)),
          Number(result.netPrice[8].toFixed(2)),
          Number(result.netPrice[9].toFixed(2)),
          Number(result.netPrice[10].toFixed(2)),
          Number(result.netPrice[11].toFixed(2)),
        ];

        this.reportData.chartLabels = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        this.barChartData = [
          {
            type: "line",
            fill: false,
            borderWidth: 2,
            data: this.chartData1,
            label: this.prices,
            backgroundColor: "blue",
            borderColor: "blue",
            yAxisID: "01",
            datalabels: {
              display: "auto",
              color: "blue",
              align: "top",
            },
          },
          {
            type: "bar",
            barThickness: 35,
            data: this.chartData2,
            label: this.discounts,
            backgroundColor: "rgba(236, 168, 65, 0.623)",
            borderColor: "rgba(236, 168, 65, 0.623)",
            yAxisID: "02",
            datalabels: {
              color: "red",
              display: "auto",
              anchor: "start",
              align: "start",
              offset: -25,
              formatter: function (value, context) {
                return "%" + value;
              },
            },
          },
          {
            type: "line",
            fill: false,
            borderWidth: 2,
            data: this.chartData3,
            label: this.netprices,
            backgroundColor: "green",
            borderColor: "green",
            yAxisID: "01",
            datalabels: {
              display: "auto",
              color: "green",
              align: "bottom",
            },
          },
        ];

        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            mode: "index",
            intersect: true,
          },
          scales: {
            yAxes: [
              {
                id: "01",
                position: "left",
                ticks: {
                  min: 0,
                  suggestedMax: max + 10,
                },
              },
              {
                id: "02",
                position: "right",
                ticks: {
                  min: 0,
                  suggestedMax: maxDiscount + 2 * maxDiscount,
                  callback: function (label, index, labels) {
                    return "%" + label;
                  },
                },
              },
            ],
          },
          title: {
            display: false,
            text: "Price Trend",
          },
          plugins: {
            datalabels: {
              yAxisID: "02",
              font: {
                weight: "normal",
              },
              formatter: function (value, context) {
                if (
                  context.dataset.label == "Discounts" ||
                  context.dataset.label == "İskontolar"
                ) {
                  return "%" + value;
                }
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
          },
        };
        this.isRun = true;
      });
  }

  take() {
    if (this.isRun == true) {
      this.captureService
        .getImage(this.screen.nativeElement, true, 400)
        .pipe(
          tap((img) => {
            this.img = img;
          })
        )
        .subscribe();
    } else {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    }
  }

  reset() {
    this.img = "";
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  setProductName(id: number) {
    for (let sc of Object.keys(this.productList)) {
      if (id == this.productList[sc].id) {
        return this.productList[sc].name;
      }
    }
  }

  getPeriod(period) {
    let periods = "";
    period.forEach((element) => {
      periods += element.label + ", ";
    });
    return periods;
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(
      this.scenarioId
    )} / ${this.setProductName(this.productId)} / ${
      this.period.label
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Price Trend";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }

  setProduct(scenarioId: number) {
    this.productList.length = 0;
    for (let scenario of this.scenarioList) {
      if (scenarioId === scenario.id) {
        for (let product of scenario.impactedProducts) {
          this.productList.push(product);
        }
        if (scenario.name === "Base Scenario") {
          for (let product of this.product2List) {
            this.productList.push(product);
          }
        }
      }
    }
  }
}
