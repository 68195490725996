import { Injectable, EventEmitter } from "@angular/core";
import { Http, Headers, RequestOptions, Response } from "@angular/http";
import { Observable } from "rxjs/Rx";

import { ApplicationUser } from "@planard/@core/entities/applicationUser";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { UserManager, User } from "oidc-client";
import { environment } from "../../../environments/environment";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { CompanySetupService } from "@planard/@core/backend/service/company-setup.service";
import { Optional } from "ag-grid-community";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { LocalStorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";

const settings: any = {
  authority: environment.authority,
  client_id: "spa-client",
  response_type: "code",
  scope: "Planard.WebApi",
  redirect_uri: environment.callback + "#/auth/callback",
  post_logout_redirect_uri: environment.callback,
  silent_redirect_uri: environment.callback + "renew-callback.html",
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 4,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export class UserServiceConfig {
  user;
}

@Injectable({
  providedIn: "root",
})
@Injectable()
export class AuthService {
  private manager: UserManager = new UserManager(settings);
  private user: User = null;
  private applicationUser: ApplicationUser = null;

  private timelineTasks: any = [];
  private pricePlanningValue: any = null;
  private forecastPlanningValue: any = null;
  private costPlanningValue: any = null;
  private centralCostPlanningValue: any = null;
  private priceApprovalValue: any = null;
  private forecastApprovalValue: any = null;
  private costApprovalValue: any = null;
  private centralCostApprovalValue: any = null;
  private riskOppValue: any = null;
  private salesNarrativesValue: any = null;
  private costNarrativesValue: any = null;

  private companyNumberFormat: any = null;
  private companyDecimalFormat: any = null;
  private companyPackage: any = null;
  private companyUserCount: any = null;
  private companyLanguage: any = null;
  private companyCurrency: any = null;
  private companyName: any = null;

  today;

  constructor(
    private accountsService: AccountsService,
    private companyService: CompanySetupService,
    private timelineService: TimelineService,
    public storageService: LocalStorageService,
    public translate: TranslateService,
    @Optional() config?: UserServiceConfig
  ) {
    if (config) {
      this.user = config.user;
    }

    this.manager.getUser().then((user) => {
      this.user = user;
    });

    let date = new Date();
    this.today = new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: "Europe/Berlin" }
      )
    );
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getClaims(): any {
    return this.user.profile;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(
      (user) => {
        this.user = user;

        this.companyService.get().subscribe((result) => {
          var lang = result.language;
          this.translate.use(lang);
        });

        this.getAppUser();
      },
      (err) => {
        alert("You are Redirecting ...");
        console.log("Error caught in signinRedirectCallback().");
        console.error(err);
      }
    );
  }

  completeLogout(): Promise<any> {
    this.storageService.remove("companyDecimalFormat");
    this.storageService.remove("companyNumberFormat");
    return this.manager.signoutRedirect();
  }

  /* Application User Start */

  getAppUser(): void {
    if (this.applicationUser == null) {
      this.accountsService.getUser().subscribe((response) => {
        this.applicationUser = response;

        /* ALL ROLES HAS BEEN GIVEN */
        //this.applicationUser.roles = ["Admin","Price Planner","Central Cost Planner","Sales Planner","Sales Approver","Cost Planner","Cost Approver"];

        console.log("Application User Information :");
        console.log(this.applicationUser);

        this.accountsService
          .isApprover(this.applicationUser.userId)
          .subscribe((result) => {
            this.applicationUser.isApprover = result;
          });

        /* company */
        this.companyService.get().subscribe((result) => {
          this.companyPackage = result.package;
          this.companyDecimalFormat = result.decimalFormat;
          this.companyNumberFormat = result.numberFormat;
          this.companyUserCount = result.userCount;
          this.companyLanguage = result.language;
          this.companyCurrency = result.currency;
          this.companyName = result.name;
        });

        /* timeline tasks start and end dates */
        this.timelineService.listProcessesTime().subscribe((result) => {
          this.timelineTasks = result;

          result.forEach((element) => {
            if (element.task == "PRICE_PLANNING")
              this.pricePlanningValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "FORECAST_PLANNING")
              this.forecastPlanningValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "COST_PLANNING")
              this.costPlanningValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "CENTRAL_COST_PLANNING")
              this.centralCostPlanningValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "PRICE_APPROVAL")
              this.priceApprovalValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "FORECAST_APPROVAL")
              this.forecastApprovalValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "COST_APPROVAL")
              this.costApprovalValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "CENTRAL_COST_APPROVAL")
              this.centralCostApprovalValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "RISK_OPPORTUNITIES")
              this.riskOppValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "SALES_NARRATIVES")
              this.salesNarrativesValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
            if (element.task == "COST_NARRATIVES")
              this.costNarrativesValue = {
                startDate: element.startDate,
                endDate: element.endDate,
              };
          });
        });
      });
    }
  }

  getRoles(): any {
    return this.applicationUser.roles;
  }

  /* is user approver */
  isApprover(): boolean {
    this.getAppUser();
    return this.applicationUser.isApprover;
  }
  /* roles */
  isAdmin(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Admin"
    );
  }
  isPricePlanner(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Price Planner"
    );
  }
  isCentralCostPlanner(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Central Cost Planner"
    );
  }
  isBusinessUnitPlanner(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Sales Planner"
    );
  }
  isBusinessUnitApprover(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Sales Approver"
    );
  }
  isSupportFunctionPlanner(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Cost Planner"
    );
  }
  isSupportFunctionApprover(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Cost Approver"
    );
  }
  isSuperAdmin(): boolean {
    this.getAppUser();
    /*if(this.applicationUser == null){return false}*/ return this.applicationUser.roles.includes(
      "Super Admin"
    );
  }
  /* Application User End */

  /* Timeline tasks Start */

  pricePlanning(): boolean {
    this.getAppUser();
    if (this.pricePlanningValue == null) return false;
    let start = new Date(
      (typeof this.pricePlanningValue.startDate === "string"
        ? new Date(this.pricePlanningValue.startDate)
        : this.pricePlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.pricePlanningValue.endDate === "string"
        ? new Date(this.pricePlanningValue.endDate)
        : this.pricePlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  forecastPlanning(): boolean {
    this.getAppUser();
    if (this.forecastPlanningValue == null) return false;
    let start = new Date(
      (typeof this.forecastPlanningValue.startDate === "string"
        ? new Date(this.forecastPlanningValue.startDate)
        : this.forecastPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.forecastPlanningValue.endDate === "string"
        ? new Date(this.forecastPlanningValue.endDate)
        : this.forecastPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  costPlanning(): boolean {
    this.getAppUser();
    if (this.costPlanningValue == null) return false;
    let start = new Date(
      (typeof this.costPlanningValue.startDate === "string"
        ? new Date(this.costPlanningValue.startDate)
        : this.costPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.costPlanningValue.endDate === "string"
        ? new Date(this.costPlanningValue.endDate)
        : this.costPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  centralCostPlanning(): boolean {
    this.getAppUser();
    if (this.centralCostPlanningValue == null) return false;
    let start = new Date(
      (typeof this.centralCostPlanningValue.startDate === "string"
        ? new Date(this.centralCostPlanningValue.startDate)
        : this.centralCostPlanningValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.centralCostPlanningValue.endDate === "string"
        ? new Date(this.centralCostPlanningValue.endDate)
        : this.centralCostPlanningValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  priceApproval(): boolean {
    this.getAppUser();
    if (this.priceApprovalValue == null) return false;
    let start = new Date(
      (typeof this.priceApprovalValue.startDate === "string"
        ? new Date(this.priceApprovalValue.startDate)
        : this.priceApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.priceApprovalValue.endDate === "string"
        ? new Date(this.priceApprovalValue.endDate)
        : this.priceApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }
  forecastApproval(): boolean {
    this.getAppUser();
    if (this.forecastApprovalValue == null) return false;
    let start = new Date(
      (typeof this.forecastApprovalValue.startDate === "string"
        ? new Date(this.forecastApprovalValue.startDate)
        : this.forecastApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.forecastApprovalValue.endDate === "string"
        ? new Date(this.forecastApprovalValue.endDate)
        : this.forecastApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }
  costApproval(): boolean {
    this.getAppUser();
    if (this.costApprovalValue == null) return false;
    let start = new Date(
      (typeof this.costApprovalValue.startDate === "string"
        ? new Date(this.costApprovalValue.startDate)
        : this.costApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.costApprovalValue.endDate === "string"
        ? new Date(this.costApprovalValue.endDate)
        : this.costApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }
  centralCostApproval(): boolean {
    this.getAppUser();
    if (this.centralCostApprovalValue == null) return false;
    let start = new Date(
      (typeof this.centralCostApprovalValue.startDate === "string"
        ? new Date(this.centralCostApprovalValue.startDate)
        : this.centralCostApprovalValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.centralCostApprovalValue.endDate === "string"
        ? new Date(this.centralCostApprovalValue.endDate)
        : this.centralCostApprovalValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  riskOpp(): boolean {
    this.getAppUser();
    if (this.riskOppValue == null) return false;
    let start = new Date(
      (typeof this.riskOppValue.startDate === "string"
        ? new Date(this.riskOppValue.startDate)
        : this.riskOppValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.riskOppValue.endDate === "string"
        ? new Date(this.riskOppValue.endDate)
        : this.riskOppValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }
  salesNarratives(): boolean {
    this.getAppUser();
    if (this.salesNarrativesValue == null) return false;
    let start = new Date(
      (typeof this.salesNarrativesValue.startDate === "string"
        ? new Date(this.salesNarrativesValue.startDate)
        : this.salesNarrativesValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.salesNarrativesValue.endDate === "string"
        ? new Date(this.salesNarrativesValue.endDate)
        : this.salesNarrativesValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  costNarratives(): boolean {
    this.getAppUser();
    if (this.costNarrativesValue == null) return false;
    let start = new Date(
      (typeof this.costNarrativesValue.startDate === "string"
        ? new Date(this.costNarrativesValue.startDate)
        : this.costNarrativesValue.startDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    let end = new Date(
      (typeof this.costNarrativesValue.endDate === "string"
        ? new Date(this.costNarrativesValue.endDate)
        : this.costNarrativesValue.endDate
      ).toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    );
    if (start <= this.today && end >= this.today) return true;
    else return false;
  }

  /* Timeline tasks End */

  /* Company Info */

  getNumberFormat(): string {
    this.getAppUser();
    return this.companyNumberFormat;
  }

  getDecimalFormat(): string {
    this.getAppUser();
    return this.companyDecimalFormat;
  }

  getCompanyPackage(): string {
    this.getAppUser();
    return this.companyPackage;
  }

  getCompanyUserCount(): number {
    this.getAppUser();
    return this.companyUserCount;
  }

  getCompanyLanguage(): string {
    this.getAppUser();
    return this.companyLanguage;
  }

  getCompanyCurrency(): string {
    this.getAppUser();
    return EnumCurrency[`${this.companyCurrency}`];
  }

  getCompanyName(): string {
    this.getAppUser();
    return this.companyName;
  }

  /* Company Info Ends */
}
