import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { Forecast } from "@planard/@core/entities/forecast";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { AuthService } from "@planard/auth/services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { TemplatePortal } from "@angular/cdk/portal";
import { fromEvent, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { isNumeric } from "rxjs/internal-compatibility";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import * as XLSX from "xlsx";
@Component({
  selector: "forecast-approver",
  templateUrl: "./forecast-approver.component.html",
  styleUrls: ["./forecast-approver.component.scss"],
  providers: [NumberDecimalPipe],
})
export class ForecastApproverComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  numberFormat;
  decimalFormat;

  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  yearsCopyActual: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  formList: ProductForm[];
  incomeList = [
    { value: 0, label: "Operating Income" },
    { value: 1, label: "Non-Operating Income" },
  ];
  income: any = 0;
  cellNote: any; //gösterilecek olan not.
  volume: boolean;
  amount: boolean;
  scenario: Scenario;
  scenarioId: number;
  copyScenarioId: number;
  guidanceId: number;
  copyPlanningGuidanceId: number;
  currentGuidanceId: number;
  percent2coloring: Number = 40;
  carryForwardClicked: boolean;
  increaseMonthlyClicked: boolean;
  decreaseMonthlyClicked: boolean;
  increaseMonthlyPClicked: boolean;
  decreaseMonthlyPClicked: boolean;
  increaseAllMonthlyClicked: boolean;
  decreaseAllMonthlyClicked: boolean;
  increaseAllMonthlyPClicked: boolean;
  decreaseAllMonthlyPClicked: boolean;
  sustainTrendClicked: boolean;
  rulesClicked: boolean;
  exportClicked: boolean;
  importClicked: boolean;
  exelExportClicked: boolean;
  exelImportClicked: boolean;
  copyClicked: boolean;
  pasteClicked: boolean;
  copyHeadersClicked: boolean;
  notesClicked: boolean;
  priceNotesClicked: boolean;
  discountNotesClicked: boolean;
  copyActualsClicked: boolean;
  copyScenarioClicked: boolean;
  copyPlannngClicked: boolean;
  sheetName: string;
  colorPopup: boolean = false;
  rulesPopup: boolean = false;
  notesPopup: boolean = false;
  input: boolean;
  notes: boolean;
  guidaneSet: boolean;
  exelPriceData: any;
  exelDiscountData: any;
  salesForecastId: number;
  exelYear: number = 2020;
  exelName: string;
  //for exel import and read
  file: File;
  filelist: any;
  arrayBuffer: any;
  forecastLenght: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  increaseValueP: number;
  decreaseValueP: number;
  increaseAllValue: number;
  decreaseAllValue: number;
  increaseAllValueP: number;
  decreaseAllValueP: number;
  noteValue: any;
  file_name: string;
  formConversionRate: number = 1;
  guidanceName: any;
  guidancePlanningPeriodList: GuidancePlanningPeriod[];
  name = "Angular";
  data2Exel: any;
  headData: any; // excel row header
  rowData: any;
  fullData: any;
  fullData4Import: any;
  year4actual: number;
  currentYear: number = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();
  rowDataS: any;
  fullDataS: any;
  // agGridUtilities: AgGridUtilities;
  firstYear: any;
  isApprover: boolean;
  isRejected: boolean = true;
  nextApproverId: any;
  rejectButton: boolean = true;
  rejectReason = "";
  approverRequestId: any;
  isScreenValid: boolean;
  isRoleValid: boolean;
  granularity: number;
  x: number;
  y4c: number;
  y4d: number;
  y4n: number;
  isOpen: boolean = false;

  defaultColDef = {
    //editable: true, //this.cellEditable,
    sortable: false,
    flex: 1,
    minWidth: 80,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];
  loadingTemplate;
  noRowsTemplate;
  productName;
  //#endregion

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public authService: AuthService,
    public forecastService: ForecastService,
    public productsService: ProductsService,
    public guidanceService: GuidanceService,
    public translate: TranslateService,
    private numberDecimal: NumberDecimalPipe,
    public approverRequestService: ApproverRequestService,
    public scenarioService: ScenarioService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.loading").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    if (this.translate.currentLang == "en") this.productName = "Product";
    if (this.translate.currentLang == "tr") this.productName = "Ürün";

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.loading").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") this.productName = "Product";
      if (this.translate.currentLang == "tr") this.productName = "Ürün";

      this.changeAmount();
    });

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.authService.getAppUser();
    setTimeout(() => {
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.isRoleValid = this.authService.isBusinessUnitApprover();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
      this.isScreenValid = this.authService.forecastApproval();
      if (this.isScreenValid == false) {
        this.translate
          .get("entryScreens.forecastTaskApproval")
          .subscribe((data: string) => {
            this.toastrService.error(data);
          });
      }
    }, 150);
  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.approverRequestId = Number(params.get("requestId"));
    });
    this.guidanceService.getGuidance().subscribe((result) => {
      this.granularity = result.granularityType;
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.currentGuidanceId = result.id;
      this.guidanceService.getPlanningRecord(result.id).subscribe((result) => {
        this.guidancePlanningPeriodList = result;
      });
    });
  }

  addOnClick(event) {
    this.x = event.pageX + 75;
    this.y4c = event.pageY + 150;
    this.y4d = event.pageY + 50;
    this.y4n = event.pageY + 100;
  }

  onGridSet() {
    // setTimeout(() => {
    //   this.autoSizeAll(true);
    // }, 1000);
  }

  autoSizeAll(skipHeader) {
    //this.gridApi.sizeColumnsToFit();
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  setClick() {
    this.carryForwardClicked = false;
    this.increaseMonthlyClicked = false;
    this.decreaseMonthlyClicked = false;
    this.increaseMonthlyPClicked = false;
    this.decreaseMonthlyPClicked = false;
    this.increaseAllMonthlyClicked = false;
    this.decreaseAllMonthlyClicked = false;
    this.increaseAllMonthlyPClicked = false;
    this.decreaseAllMonthlyPClicked = false;
    this.sustainTrendClicked = false;
    this.rulesClicked = false;
    this.exportClicked = false;
    this.importClicked = false;
    this.exelExportClicked = false;
    this.exelImportClicked = false;
    this.copyClicked = false;
    this.pasteClicked = false;
    this.copyHeadersClicked = false;
    this.notesClicked = false;
    this.priceNotesClicked = false;
    this.discountNotesClicked = false;
    this.copyActualsClicked = false;
    this.copyScenarioClicked = false;
    this.copyPlannngClicked = false;
  }

  approve() {
    let approverRequest = {
      approveRequestId: this.approverRequestId,
      rejectMessage: null,
    };
    this.approverRequestService
      .requestApprove(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.router.navigate(["planning-sales/sales-approvals"]);
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  reject() {
    if (this.rejectReason == "") {
      this.translate
        .get("entryScreens.rejectReasonPlease")
        .subscribe((data: any) => {
          this.toastrService.error(data);
        });
      return;
    }
    let approverRequest = {
      approveRequestId: this.approverRequestId,
      rejectMessage: this.rejectReason,
    };
    this.approverRequestService
      .requestReject(approverRequest)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.router.navigate(["planning-sales/sales-approvals"]);
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
    this.rejectReason = "";
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    setTimeout(() => {
      this.getForecast();
    }, 1000);
  }

  getForecast() {
    this.forecastService
      .listPrices(0, this.approverRequestId)
      .subscribe((result) => {
        this.fullData = result.data;
        this.fullData4Import = [...result.data];

        this.rowData = [...result.data].filter((x) => x.isVisible);

        var neg = [];
        var pos = [];

        neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
        pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

        this.years = [...neg, ...pos];
        this.yearsCopyActual = [
          ...neg.map((x) => x * -1),
          ...[this.currentYear],
        ];
        this.year4actual = this.yearsCopyActual[0];

        this.forecastLenght = this.fullData.length;
        this.changeVolume();
        this.setTotalRow();
        this.setExpandable();
      });
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  setTotalRow() {
    let totalRow: any = [...this.fullData[0]];
    totalRow.product = "TOTAL : ";

    let types = ["v", "a"];
    let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    for (let type of types) {
      for (let year of this.years) {
        for (let month of months) {
          let total = 0;
          for (let row of this.fullData) {
            if (
              row[`${type}col_${year}_${month}`] != undefined &&
              row.productFormId == null &&
              row.productChannelId == null
            )
              total = total + Number(row[`${type}col_${year}_${month}`]);
          }
          totalRow[`${type}col_${year}_${month}`] = total.toFixed(2);
        }
        totalRow[`${type}total_${year}`] = Math.round(
          Number(totalRow[`${type}col_${year}_1`]) +
            Number(totalRow[`${type}col_${year}_2`]) +
            Number(totalRow[`${type}col_${year}_3`]) +
            Number(totalRow[`${type}col_${year}_4`]) +
            Number(totalRow[`${type}col_${year}_5`]) +
            Number(totalRow[`${type}col_${year}_6`]) +
            Number(totalRow[`${type}col_${year}_7`]) +
            Number(totalRow[`${type}col_${year}_8`]) +
            Number(totalRow[`${type}col_${year}_9`]) +
            Number(totalRow[`${type}col_${year}_10`]) +
            Number(totalRow[`${type}col_${year}_11`]) +
            Number(totalRow[`${type}col_${year}_12`])
        );
      }
    }
    this.gridApi.setPinnedBottomRowData([totalRow]);
  }

  isEditable(data, month, year): Boolean {
    var startMonth = 0;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    if (!this.isScreenValid) return false;
    if (this.guidanceId != this.currentGuidanceId) return false;
    if (year < 0) return false;
    if (month < startMonth) return false;
    if (data.expandable) return false;
    if (data.isImpactedProduct == false) return false;
    if (data.product == "TOTAL : ") return false;
    return true;
  }

  getProductDef() {
    return {
      headerName: this.productName,
      field: "product",
      /*lockPosition: true,*/ pinned: "left",
      editable: this.cellEditable,
      minWidth: 150,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.expandable && params.data.selected) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (params.data.expandable) {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (!params.data.expandable && params.data.product != "TOTAL : ") {
      if (
        params.data[`productChannelId`] == null &&
        params.data[`productFormId`] == null
      ) {
        // product has no child
        return `<span style="padding-left: 20px;"></span>` + params.value + ``;
      } else if (
        params.data[`productFormId`] != null &&
        params.data[`productChannelId`] == null
      ) {
        // form has no child
        return (
          `<span style="padding-left: 30px;"></span>` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else if (params.data[`productChannelId`] != null) {
        return (
          `<span style="padding-left: 40px;"></span>` +
          `<span style="color: blue;">` +
          params.value +
          `</span>`
        );
      }
    } else if (params.data.product == "TOTAL : ") {
      return (
        `<span style="padding-left: 40px;"></span>` +
        `<span style="color: #ff7500;">` +
        params.value +
        `</span>`
      );
    }
    return `<span` + style + `></span> ` + params.value + ``;
  }

  getYearColumnDef(type, year) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;
    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;
    var name = this.guidanceName;
    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;
    var isYearly: boolean = false;
    let years = this.years;
    let yearIndex;
    for (let index in this.years) {
      if (this.years[index] == year) yearIndex = Number(index);
    }
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Yearly") {
        isYearly = true;
      }
    });
    if (!isYearly) {
      //monthly
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        children: [
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.isImpactedProduct == false) return false;
              //if ( params.data.isSubmit == true) return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            valueSetter: function (params) {
              params.data[`${type}total_${year}`] = Number(params.newValue);
              return true;
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] *
                      rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] +
                      params.data[`${type}total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              }
              if (params.data.expandable == true) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.product == "TOTAL : ") {
                return { color: "#ff7500" };
              }
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
          this.getMonthColumnDef(type, year, 1),
          this.getMonthColumnDef(type, year, 2),
          this.getMonthColumnDef(type, year, 3),
          this.getMonthColumnDef(type, year, 4),
          this.getMonthColumnDef(type, year, 5),
          this.getMonthColumnDef(type, year, 6),
          this.getMonthColumnDef(type, year, 7),
          this.getMonthColumnDef(type, year, 8),
          this.getMonthColumnDef(type, year, 9),
          this.getMonthColumnDef(type, year, 10),
          this.getMonthColumnDef(type, year, 11),
          this.getMonthColumnDef(type, year, 12),
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.isImpactedProduct == false) return false;
              //if ( params.data.isSubmit == true) return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "open",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            valueSetter: function (params) {
              params.data[`${type}total_${year}`] = Number(params.newValue);
              return true;
            },
            cellStyle: function (params) {
              if (params.data.product == "TOTAL : ") {
                return { color: "#ff7500" };
              } else if (params.data.expandable == true) {
                return { backgroundColor: "#f6f6f6" };
              } else if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              }
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    } else {
      //yearly
      return {
        headerName: `${year > 0 ? name : ""} ${
          year < 0 ? "A`" + year * -1 : year
        }`,
        children: [
          {
            headerName: "Total",
            minWidth: 150,
            copyHeadersToClipboard: true,
            suppressClipboardPaste: true,
            editable: function (params) {
              if (!isScreenValid) return false;
              if (guidanceId != currentGuidanceId) return false;
              if (year < 0) return false;
              if (params.data.expandable) return false;
              if (params.data.isImpactedProduct == false) return false;
              //if ( params.data.isSubmit == true) return false;
              if (params.data.product == "TOTAL : ") return false;
              return true;
            },
            columnGroupShow: "closed",
            cellYear: year,
            cellType: type,
            type: "rightAligned",
            valueGetter: function (params) {
              return Number(params.data[`${type}total_${year}`]).toFixed(2);
            },
            cellStyle: function (params) {
              if (
                Number(params.data[`${type}total_${year}`]) <
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] *
                      rate2diff
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
              } else if (
                Number(params.data[`${type}total_${year}`]) >
                  Number(
                    params.data[`${type}total_${years[yearIndex - 1]}`] +
                      params.data[`${type}total_${years[yearIndex - 1]}`] * rate
                  ) &&
                params.data.expandable != true &&
                params.data.product != "TOTAL : "
              ) {
                return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
              }
              if (params.data.expandable == true) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.isImpactedProduct == false) {
                return { backgroundColor: "#f6f6f6" };
              }
              if (params.data.product == "TOTAL : ") {
                return { color: "#ff7500" };
              }
            },
            valueSetter: function (params) {
              params.data[`${type}total_${year}`] = Number(params.newValue);
              return true;
            },
            valueFormatter: function (params) {
              if (params.value == undefined || params.value == "NaN") {
                return "0.00";
              }
              return numberDecimal.transform(
                params.value,
                decimalFormat,
                numberFormat
              );
            },
          },
        ],
      };
    }
  }

  getMonthColumnDef(type, year, month) {
    let numberDecimal = this.numberDecimal;
    let numberFormat = this.numberFormat;
    let decimalFormat = this.decimalFormat;

    this.percent2coloring;
    let rate = Number(this.percent2coloring) / 100;
    let rate2diff = (100 - Number(this.percent2coloring)) / 100;

    let guidanceId = this.guidanceId;
    let isScreenValid = this.isScreenValid;
    let currentGuidanceId = this.currentGuidanceId;

    var startMonth: any = 1;
    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year == year && element.planningType == "Monthly") {
        startMonth = element.startMonth;
      }
    });

    return {
      headerName: this.months[month - 1],
      minWidth: 85,
      copyHeadersToClipboard: true,
      suppressClipboardPaste: true,
      valueGetter: function (params) {
        return Number(params.data[`${type}col_${year}_${month}`]).toFixed(2);
      },
      valueSetter: function (params) {
        params.data[`${type}total_${year}`] =
          (isNumeric(params.data[`${type}total_${year}`])
            ? params.data[`${type}total_${year}`]
            : 0) +
          (params.newValue - (params.oldValue != "NaN" ? params.oldValue : 0));
        params.data[`${type}col_${year}_${month}`] = Number(params.newValue);
        return true;
      },
      columnGroupShow: "open",
      tooltipField: `${type}not_${year}_${month}`,
      cellMonth: month,
      cellYear: year,
      cellType: type,
      type: "rightAligned",
      editable: function (params) {
        if (!isScreenValid) return false;
        if (guidanceId != currentGuidanceId) return false;
        if (year < 0) return false;
        if (month < startMonth) return false;
        if (params.data.expandable) return false;
        if (params.data.isImpactedProduct == false) return false;
        if (params.data.product == "TOTAL : ") return false;
        return true;
      },
      cellStyle: function (params) {
        if (
          Number(params.data[`${type}col_${year}_${month}`]) <
            Number(
              params.data[`${type}col_${year}_${month - 1}`] * rate2diff
            ) &&
          params.data.expandable != true &&
          params.data.product != "TOTAL : " &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#fff0ee",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#fff0ee" }; //düşüş durumunda
        } else if (
          Number(params.data[`${type}col_${year}_${month}`]) >
            Number(
              params.data[`${type}col_${year}_${month - 1}`] +
                params.data[`${type}col_${year}_${month - 1}`] * rate
            ) &&
          params.data.expandable != true &&
          params.data.product != "TOTAL : " &&
          month - 1 >= startMonth
        ) {
          if (params.data[`${type}not_${year}_${month}`] != null) {
            return {
              color: "black",
              backgroundColor: "#f3fff3",
              border: "solid",
              borderTopWidth: "0.0px",
              borderRightWidth: "0.0px",
              borderLeftWidth: "0.0px",
              borderBottomWidth: "2px",
              borderColor: "#BB8FCE",
            }; // yükselme durumunda
          }
          return { color: "black", backgroundColor: "#f3fff3" }; // yükselme durumunda
        } else if (
          params.data[`${type}not_${year}_${month}`] != null &&
          params.data.expandable != true
        ) {
          return {
            border: "solid",
            borderTopWidth: "0.0px",
            borderRightWidth: "0.0px",
            borderLeftWidth: "0.0px",
            borderBottomWidth: "2px",
            borderColor: "#BB8FCE",
          }; // yükselme durumunda
        } else if (
          params.data[`productChannelId`] == null &&
          params.data[`productFormId`] != null &&
          params.data.expandable == true
        ) {
          return { backgroundColor: "#f6f6f6" };
        } else if (params.data.isImpactedProduct == false) {
          return { backgroundColor: "#f6f6f6" };
        } else if (
          params.data[`productChannelId`] == null &&
          params.data[`productFormId`] == null &&
          params.data.expandable == true
        ) {
          return { backgroundColor: "#f6f6f6" };
        } else if (params.data.product == "TOTAL : ") {
          return { color: "#ff7500" };
        }
        return { color: "initial", backgroundColor: "initial" };
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "0.00";
        }
        return numberDecimal.transform(
          params.value,
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  changeColor() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    let type;

    if (this.volume == true) type = "v";
    else if (this.amount == true) type = "a";

    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(type, year));
    });

    this.gridApi.setColumnDefs(this.columnDefs);
    this.translate
      .get("entryScreens.coloringRate")
      .subscribe((data: string) => {
        this.toastrService.warning(data);
      });
  }

  changeVolume() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("v", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.volume = true;
    this.amount = false;
  }

  changeAmount() {
    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef("a", year));
    });
    this.gridApi.setColumnDefs(this.columnDefs);
    this.amount = true;
    this.volume = false;
  }

  save() {
    let data = [];
    let startMonth = 0;
    let startMonths = [];

    let currentYear = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear();

    this.guidancePlanningPeriodList.forEach((element) => {
      if (element.year <= currentYear && element.planningType == "Monthly") {
        startMonths.push({
          startMonth: element.startMonth,
          year: element.year,
        });
      }
    });

    this.fullData.forEach((rowNode) => {
      if (rowNode.isImpactedProduct == true) {
        startMonths.forEach((ele) => {
          for (let month = 1; month < ele.startMonth; month++) {
            if (rowNode[`acol_${ele.year}_${month}`])
              rowNode[`acol_${ele.year}_${month}`] = 0;
            if (rowNode[`vcol_${ele.year}_${month}`])
              rowNode[`vcol_${ele.year}_${month}`] = 0;
          }
        });
        data.push(rowNode);
      }
    });

    let forecast = new Forecast();
    forecast.rows = data;

    this.forecastService.update(forecast).subscribe((backendResult) => {
      if (backendResult.isSuccess) {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
      } else {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name = "Forecast-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    let type = this.volume ? "v" : this.amount ? "a" : "";

    let emptyRow: any[];
    let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
    let guide: any[] = [
      "",
      "Excel User Guide : On This Excel Sheet Change Only Volumes/Amounts. Do not Remove Any Column or Row and Do not Change Their Names.",
    ];

    let header = ["SalesForecastId", "Name"];

    if (type == "v") {
      this.sheetName = "Sheet Volume";
    } else if (type == "a") {
      this.sheetName = "Sheet Amount";
    }

    for (let year of this.years) {
      if (year > 0) {
        header.push(`${year} Jan`);
        header.push(`${year} Feb`);
        header.push(`${year} Mar`);
        header.push(`${year} Apr`);
        header.push(`${year} May`);
        header.push(`${year} Jun`);
        header.push(`${year} Jul`);
        header.push(`${year} Aug`);
        header.push(`${year} Sep`);
        header.push(`${year} Oct`);
        header.push(`${year} Nov`);
        header.push(`${year} Dec`);
      }
    }

    this.data2Exel = [];
    this.data2Exel.push(header);

    this.fullData.forEach((element) => {
      this.salesForecastId = element.id;

      if (element.productFormId != null && element.productChannelId == null) {
        this.exelName = " " + " " + " " + element.product;
      } else if (element.productChannelId != null) {
        this.exelName = " " + " " + " " + " " + " " + " " + element.product;
      } else {
        this.exelName = element.product;
      }

      let row: any[] = [this.salesForecastId, this.exelName];
      for (let year of this.years) {
        if (year > 0) {
          row.push(element[`${type}col_${year}_1`]);
          row.push(element[`${type}col_${year}_2`]);
          row.push(element[`${type}col_${year}_3`]);
          row.push(element[`${type}col_${year}_4`]);
          row.push(element[`${type}col_${year}_5`]);
          row.push(element[`${type}col_${year}_6`]);
          row.push(element[`${type}col_${year}_7`]);
          row.push(element[`${type}col_${year}_8`]);
          row.push(element[`${type}col_${year}_9`]);
          row.push(element[`${type}col_${year}_10`]);
          row.push(element[`${type}col_${year}_11`]);
          row.push(element[`${type}col_${year}_12`]);
        }
      }
      this.data2Exel.push(row);
    });
    this.data2Exel.push(emptyRow);
    this.data2Exel.push(scenario);
    this.data2Exel.push(guide);

    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["B1"].s = {};
    ws["B1"].s = { fill: { bgColor: { rgb: "ff6600" } } };

    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 2] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 3] = { hpx: 35 };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
    XLSX.writeFile(wb, this.file_name);
  }

  onFileAdd(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];

      let type = "";
      if (first_sheet_name == "Sheet Volume") type = "v";
      else if (first_sheet_name == "Sheet Amount") type = "a";

      if ((arraylist.length - 2) % this.forecastLenght === 0) {
        this.fullData4Import = arraylist;
        for (let row in this.fullData) {
          for (let year of this.years) {
            if (
              year > 0 &&
              this.fullData[row].isImpactedProduct == true &&
              !this.fullData[row].expandable
            ) {
              let ArrMo = [
                "",
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];

              this.guidancePlanningPeriodList.forEach((element) => {
                if (element.year == year && element.planningType == "Monthly") {
                  let startMonth = element.startMonth;

                  for (var month = startMonth; month < 13; month++) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[month]}`];
                  }

                  let total = 0;
                  let totaln = 0;
                  for (var mnth = 1; mnth < 13; mnth++) {
                    total += Number(
                      this.fullData[row][`${type}col_${year}_${mnth}`]
                    );
                    totaln += Number(
                      this.fullData[row][`ncol_${year}_${mnth}`]
                    );
                  }

                  this.fullData[row][`${type}total_${year}`] = total;
                  this.fullData[row][`ntotal_${year}`] = totaln;
                } else if (
                  element.year == year &&
                  element.planningType == "Yearly"
                ) {
                  for (var month = 1; month < 13; month++) {
                    this.fullData[row][`${type}col_${year}_${month}`] =
                      this.fullData4Import[row][`${year} ${ArrMo[month]}`];
                  }

                  this.fullData[row][`${type}total_${year}`] =
                    this.fullData4Import[row][`${year} Jan`] +
                    this.fullData4Import[row][`${year} Feb`] +
                    this.fullData4Import[row][`${year} Mar`] +
                    this.fullData4Import[row][`${year} Apr`] +
                    this.fullData4Import[row][`${year} May`] +
                    this.fullData4Import[row][`${year} Jun`] +
                    this.fullData4Import[row][`${year} Jul`] +
                    this.fullData4Import[row][`${year} Aug`] +
                    this.fullData4Import[row][`${year} Sep`] +
                    this.fullData4Import[row][`${year} Oct`] +
                    this.fullData4Import[row][`${year} Nov`] +
                    this.fullData4Import[row][`${year} Dec`];
                }
              });

              if (type == "v") {
                for (let m = 0; m < 13; m++) {
                  if (this.fullData[row][`npricecol_${year}_${m}`] != 0)
                    this.fullData[row][`acol_${year}_${m}`] =
                      this.fullData[row][`vcol_${year}_${m}`] *
                      this.fullData[row][`npricecol_${year}_${m}`];
                }
                this.fullData[row][`atotal_${year}`] =
                  this.fullData[row][`acol_${year}_1`] +
                  this.fullData[row][`acol_${year}_2`] +
                  this.fullData[row][`acol_${year}_3`] +
                  this.fullData[row][`acol_${year}_4`] +
                  this.fullData[row][`acol_${year}_5`] +
                  this.fullData[row][`acol_${year}_6`] +
                  this.fullData[row][`acol_${year}_7`] +
                  this.fullData[row][`acol_${year}_8`] +
                  this.fullData[row][`acol_${year}_9`] +
                  this.fullData[row][`acol_${year}_10`] +
                  this.fullData[row][`acol_${year}_11`] +
                  this.fullData[row][`acol_${year}_12`];
              } else if (type == "a") {
                for (let m = 0; m < 13; m++) {
                  if (this.fullData[row][`npricecol_${year}_${m}`] != 0)
                    this.fullData[row][`vcol_${year}_${m}`] =
                      this.fullData[row][`acol_${year}_${m}`] /
                      this.fullData[row][`npricecol_${year}_${m}`];
                }
                this.fullData[row][`vtotal_${year}`] =
                  this.fullData[row][`vcol_${year}_1`] +
                  this.fullData[row][`vcol_${year}_2`] +
                  this.fullData[row][`vcol_${year}_3`] +
                  this.fullData[row][`vcol_${year}_4`] +
                  this.fullData[row][`vcol_${year}_5`] +
                  this.fullData[row][`vcol_${year}_6`] +
                  this.fullData[row][`vcol_${year}_7`] +
                  this.fullData[row][`vcol_${year}_8`] +
                  this.fullData[row][`vcol_${year}_9`] +
                  this.fullData[row][`vcol_${year}_10`] +
                  this.fullData[row][`vcol_${year}_11`] +
                  this.fullData[row][`vcol_${year}_12`];
              }
            }
          }
        }

        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        if (this.granularity == 1) {
          for (let row of Object.keys(this.fullData)) {
            if (
              this.fullData[row][`productId`] != null &&
              this.fullData[row][`productChannelId`] == null
            ) {
              let productId = this.fullData[row][`productId`];

              for (let year of this.years) {
                this.fullData[row][`acol_${year}_1`] = 0;
                this.fullData[row][`acol_${year}_2`] = 0;
                this.fullData[row][`acol_${year}_3`] = 0;
                this.fullData[row][`acol_${year}_4`] = 0;
                this.fullData[row][`acol_${year}_5`] = 0;
                this.fullData[row][`acol_${year}_6`] = 0;
                this.fullData[row][`acol_${year}_7`] = 0;
                this.fullData[row][`acol_${year}_8`] = 0;
                this.fullData[row][`acol_${year}_9`] = 0;
                this.fullData[row][`acol_${year}_10`] = 0;
                this.fullData[row][`acol_${year}_11`] = 0;
                this.fullData[row][`acol_${year}_12`] = 0;

                this.fullData[row][`vcol_${year}_1`] = 0;
                this.fullData[row][`vcol_${year}_2`] = 0;
                this.fullData[row][`vcol_${year}_3`] = 0;
                this.fullData[row][`vcol_${year}_4`] = 0;
                this.fullData[row][`vcol_${year}_5`] = 0;
                this.fullData[row][`vcol_${year}_6`] = 0;
                this.fullData[row][`vcol_${year}_7`] = 0;
                this.fullData[row][`vcol_${year}_8`] = 0;
                this.fullData[row][`vcol_${year}_9`] = 0;
                this.fullData[row][`vcol_${year}_10`] = 0;
                this.fullData[row][`vcol_${year}_11`] = 0;
                this.fullData[row][`vcol_${year}_12`] = 0;
              }

              for (let rowC of Object.keys(this.fullData)) {
                if (
                  this.fullData[rowC][`productId`] == productId &&
                  this.fullData[rowC][`productChannelId`] == null
                ) {
                  for (let year of this.years) {
                    for (let month of months) {
                      this.fullData[row][`${type}col_${year}_${month}`] +=
                        isNumeric(
                          this.fullData[rowC][`${type}col_${year}_${month}`]
                        )
                          ? this.fullData[rowC][`${type}col_${year}_${month}`]
                          : 0;
                      if (type == "v") {
                        this.fullData[row][`acol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`acol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`acol_${year}_${month}`]
                            : 0;
                      } else if (type == "a") {
                        this.fullData[row][`vcol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`vcol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`vcol_${year}_${month}`]
                            : 0;
                      }
                    }

                    this.fullData[row][`atotal_${year}`] =
                      this.fullData[row][`acol_${year}_1`] +
                      this.fullData[row][`acol_${year}_2`] +
                      this.fullData[row][`acol_${year}_3`] +
                      this.fullData[row][`acol_${year}_4`] +
                      this.fullData[row][`acol_${year}_5`] +
                      this.fullData[row][`acol_${year}_6`] +
                      this.fullData[row][`acol_${year}_7`] +
                      this.fullData[row][`acol_${year}_8`] +
                      this.fullData[row][`acol_${year}_9`] +
                      this.fullData[row][`acol_${year}_10`] +
                      this.fullData[row][`acol_${year}_11`] +
                      this.fullData[row][`acol_${year}_12`];

                    this.fullData[row][`vtotal_${year}`] =
                      this.fullData[row][`vcol_${year}_1`] +
                      this.fullData[row][`vcol_${year}_2`] +
                      this.fullData[row][`vcol_${year}_3`] +
                      this.fullData[row][`vcol_${year}_4`] +
                      this.fullData[row][`vcol_${year}_5`] +
                      this.fullData[row][`vcol_${year}_6`] +
                      this.fullData[row][`vcol_${year}_7`] +
                      this.fullData[row][`vcol_${year}_8`] +
                      this.fullData[row][`vcol_${year}_9`] +
                      this.fullData[row][`vcol_${year}_10`] +
                      this.fullData[row][`vcol_${year}_11`] +
                      this.fullData[row][`vcol_${year}_12`];
                  }
                }
              }
            }
          }
        } else if (this.granularity == 2) {
          for (let row of Object.keys(this.fullData)) {
            if (
              this.fullData[row][`productId`] != null &&
              this.fullData[row][`productFormId`] == null
            ) {
              let productId = this.fullData[row][`productId`];

              for (let year of this.years) {
                this.fullData[row][`acol_${year}_1`] = 0;
                this.fullData[row][`acol_${year}_2`] = 0;
                this.fullData[row][`acol_${year}_3`] = 0;
                this.fullData[row][`acol_${year}_4`] = 0;
                this.fullData[row][`acol_${year}_5`] = 0;
                this.fullData[row][`acol_${year}_6`] = 0;
                this.fullData[row][`acol_${year}_7`] = 0;
                this.fullData[row][`acol_${year}_8`] = 0;
                this.fullData[row][`acol_${year}_9`] = 0;
                this.fullData[row][`acol_${year}_10`] = 0;
                this.fullData[row][`acol_${year}_11`] = 0;
                this.fullData[row][`acol_${year}_12`] = 0;

                this.fullData[row][`vcol_${year}_1`] = 0;
                this.fullData[row][`vcol_${year}_2`] = 0;
                this.fullData[row][`vcol_${year}_3`] = 0;
                this.fullData[row][`vcol_${year}_4`] = 0;
                this.fullData[row][`vcol_${year}_5`] = 0;
                this.fullData[row][`vcol_${year}_6`] = 0;
                this.fullData[row][`vcol_${year}_7`] = 0;
                this.fullData[row][`vcol_${year}_8`] = 0;
                this.fullData[row][`vcol_${year}_9`] = 0;
                this.fullData[row][`vcol_${year}_10`] = 0;
                this.fullData[row][`vcol_${year}_11`] = 0;
                this.fullData[row][`vcol_${year}_12`] = 0;
              }

              for (let rowC of Object.keys(this.fullData)) {
                if (
                  this.fullData[rowC][`productId`] == productId &&
                  this.fullData[rowC][`productFormId`] != null
                ) {
                  for (let year of this.years) {
                    for (let month of months) {
                      this.fullData[row][`${type}col_${year}_${month}`] +=
                        isNumeric(
                          this.fullData[rowC][`${type}col_${year}_${month}`]
                        )
                          ? this.fullData[rowC][`${type}col_${year}_${month}`] *
                            this.fullData[rowC][`conversion_rate`]
                          : 0;
                      if (type == "v") {
                        this.fullData[row][`acol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`acol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`acol_${year}_${month}`]
                            : 0;
                      } else if (type == "a") {
                        this.fullData[row][`vcol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`vcol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`vcol_${year}_${month}`]
                            : 0;
                      }
                    }

                    this.fullData[row][`atotal_${year}`] =
                      this.fullData[row][`acol_${year}_1`] +
                      this.fullData[row][`acol_${year}_2`] +
                      this.fullData[row][`acol_${year}_3`] +
                      this.fullData[row][`acol_${year}_4`] +
                      this.fullData[row][`acol_${year}_5`] +
                      this.fullData[row][`acol_${year}_6`] +
                      this.fullData[row][`acol_${year}_7`] +
                      this.fullData[row][`acol_${year}_8`] +
                      this.fullData[row][`acol_${year}_9`] +
                      this.fullData[row][`acol_${year}_10`] +
                      this.fullData[row][`acol_${year}_11`] +
                      this.fullData[row][`acol_${year}_12`];

                    this.fullData[row][`vtotal_${year}`] =
                      this.fullData[row][`vcol_${year}_1`] +
                      this.fullData[row][`vcol_${year}_2`] +
                      this.fullData[row][`vcol_${year}_3`] +
                      this.fullData[row][`vcol_${year}_4`] +
                      this.fullData[row][`vcol_${year}_5`] +
                      this.fullData[row][`vcol_${year}_6`] +
                      this.fullData[row][`vcol_${year}_7`] +
                      this.fullData[row][`vcol_${year}_8`] +
                      this.fullData[row][`vcol_${year}_9`] +
                      this.fullData[row][`vcol_${year}_10`] +
                      this.fullData[row][`vcol_${year}_11`] +
                      this.fullData[row][`vcol_${year}_12`];
                  }
                }
              }
            }
          }
        } else if (this.granularity == 3) {
          /* Channel'dan Form'a Yazma */
          for (let rowF of Object.keys(this.fullData)) {
            if (
              this.fullData[rowF][`productId`] != null &&
              this.fullData[rowF][`productFormId`] != null &&
              this.fullData[rowF][`productChannelId`] == null
            ) {
              let formFormId = this.fullData[rowF][`productFormId`];
              let formProductId = this.fullData[rowF][`productId`];

              for (let year of this.years) {
                this.fullData[rowF][`acol_${year}_1`] = 0;
                this.fullData[rowF][`acol_${year}_2`] = 0;
                this.fullData[rowF][`acol_${year}_3`] = 0;
                this.fullData[rowF][`acol_${year}_4`] = 0;
                this.fullData[rowF][`acol_${year}_5`] = 0;
                this.fullData[rowF][`acol_${year}_6`] = 0;
                this.fullData[rowF][`acol_${year}_7`] = 0;
                this.fullData[rowF][`acol_${year}_8`] = 0;
                this.fullData[rowF][`acol_${year}_9`] = 0;
                this.fullData[rowF][`acol_${year}_10`] = 0;
                this.fullData[rowF][`acol_${year}_11`] = 0;
                this.fullData[rowF][`acol_${year}_12`] = 0;

                this.fullData[rowF][`vcol_${year}_1`] = 0;
                this.fullData[rowF][`vcol_${year}_2`] = 0;
                this.fullData[rowF][`vcol_${year}_3`] = 0;
                this.fullData[rowF][`vcol_${year}_4`] = 0;
                this.fullData[rowF][`vcol_${year}_5`] = 0;
                this.fullData[rowF][`vcol_${year}_6`] = 0;
                this.fullData[rowF][`vcol_${year}_7`] = 0;
                this.fullData[rowF][`vcol_${year}_8`] = 0;
                this.fullData[rowF][`vcol_${year}_9`] = 0;
                this.fullData[rowF][`vcol_${year}_10`] = 0;
                this.fullData[rowF][`vcol_${year}_11`] = 0;
                this.fullData[rowF][`vcol_${year}_12`] = 0;
              }

              for (let rowC of Object.keys(this.fullData)) {
                if (
                  this.fullData[rowC][`productId`] == formProductId &&
                  this.fullData[rowC][`productFormId`] == formFormId &&
                  this.fullData[rowC][`productChannelId`] != null
                ) {
                  for (let year of this.years) {
                    for (let month of months) {
                      this.fullData[rowF][`${type}col_${year}_${month}`] +=
                        isNumeric(
                          this.fullData[rowC][`${type}col_${year}_${month}`]
                        )
                          ? this.fullData[rowC][`${type}col_${year}_${month}`]
                          : 0;
                      if (type == "v") {
                        this.fullData[rowF][`acol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`acol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`acol_${year}_${month}`]
                            : 0;
                      } else if (type == "a") {
                        this.fullData[rowF][`vcol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowC][`vcol_${year}_${month}`]
                          )
                            ? this.fullData[rowC][`vcol_${year}_${month}`]
                            : 0;
                      }
                    }

                    this.fullData[rowF][`atotal_${year}`] =
                      this.fullData[rowF][`acol_${year}_1`] +
                      this.fullData[rowF][`acol_${year}_2`] +
                      this.fullData[rowF][`acol_${year}_3`] +
                      this.fullData[rowF][`acol_${year}_4`] +
                      this.fullData[rowF][`acol_${year}_5`] +
                      this.fullData[rowF][`acol_${year}_6`] +
                      this.fullData[rowF][`acol_${year}_7`] +
                      this.fullData[rowF][`acol_${year}_8`] +
                      this.fullData[rowF][`acol_${year}_9`] +
                      this.fullData[rowF][`acol_${year}_10`] +
                      this.fullData[rowF][`acol_${year}_11`] +
                      this.fullData[rowF][`acol_${year}_12`];

                    this.fullData[rowF][`vtotal_${year}`] =
                      this.fullData[rowF][`vcol_${year}_1`] +
                      this.fullData[rowF][`vcol_${year}_2`] +
                      this.fullData[rowF][`vcol_${year}_3`] +
                      this.fullData[rowF][`vcol_${year}_4`] +
                      this.fullData[rowF][`vcol_${year}_5`] +
                      this.fullData[rowF][`vcol_${year}_6`] +
                      this.fullData[rowF][`vcol_${year}_7`] +
                      this.fullData[rowF][`vcol_${year}_8`] +
                      this.fullData[rowF][`vcol_${year}_9`] +
                      this.fullData[rowF][`vcol_${year}_10`] +
                      this.fullData[rowF][`vcol_${year}_11`] +
                      this.fullData[rowF][`vcol_${year}_12`];
                  }
                }
              }
            }
          }
          /* Form'dan Product'a Yazma */
          for (let rowP of Object.keys(this.fullData)) {
            if (
              this.fullData[rowP][`productId`] != null &&
              this.fullData[rowP][`productFormId`] == null &&
              this.fullData[rowP][`productChannelId`] == null
            ) {
              let productId = this.fullData[rowP][`productId`];

              for (let year of this.years) {
                this.fullData[rowP][`acol_${year}_1`] = 0;
                this.fullData[rowP][`acol_${year}_2`] = 0;
                this.fullData[rowP][`acol_${year}_3`] = 0;
                this.fullData[rowP][`acol_${year}_4`] = 0;
                this.fullData[rowP][`acol_${year}_5`] = 0;
                this.fullData[rowP][`acol_${year}_6`] = 0;
                this.fullData[rowP][`acol_${year}_7`] = 0;
                this.fullData[rowP][`acol_${year}_8`] = 0;
                this.fullData[rowP][`acol_${year}_9`] = 0;
                this.fullData[rowP][`acol_${year}_10`] = 0;
                this.fullData[rowP][`acol_${year}_11`] = 0;
                this.fullData[rowP][`acol_${year}_12`] = 0;

                this.fullData[rowP][`vcol_${year}_1`] = 0;
                this.fullData[rowP][`vcol_${year}_2`] = 0;
                this.fullData[rowP][`vcol_${year}_3`] = 0;
                this.fullData[rowP][`vcol_${year}_4`] = 0;
                this.fullData[rowP][`vcol_${year}_5`] = 0;
                this.fullData[rowP][`vcol_${year}_6`] = 0;
                this.fullData[rowP][`vcol_${year}_7`] = 0;
                this.fullData[rowP][`vcol_${year}_8`] = 0;
                this.fullData[rowP][`vcol_${year}_9`] = 0;
                this.fullData[rowP][`vcol_${year}_10`] = 0;
                this.fullData[rowP][`vcol_${year}_11`] = 0;
                this.fullData[rowP][`vcol_${year}_12`] = 0;
              }

              for (let rowF of Object.keys(this.fullData)) {
                if (
                  this.fullData[rowF][`productId`] == productId &&
                  this.fullData[rowF][`productFormId`] != null &&
                  this.fullData[rowF][`productChannelId`] == null
                ) {
                  for (let year of this.years) {
                    for (let month of months) {
                      if (type == "v") {
                        this.fullData[rowP][`${type}col_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowF][`${type}col_${year}_${month}`]
                          )
                            ? this.fullData[rowF][
                                `${type}col_${year}_${month}`
                              ] * this.fullData[rowF][`conversion_rate`]
                            : 0;

                        this.fullData[rowP][`acol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowF][`acol_${year}_${month}`]
                          )
                            ? this.fullData[rowF][`acol_${year}_${month}`]
                            : 0;
                      } else if (type == "a") {
                        this.fullData[rowP][`${type}col_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowF][`${type}col_${year}_${month}`]
                          )
                            ? this.fullData[rowF][`${type}col_${year}_${month}`]
                            : 0;

                        this.fullData[rowP][`vcol_${year}_${month}`] +=
                          isNumeric(
                            this.fullData[rowF][`vcol_${year}_${month}`]
                          )
                            ? this.fullData[rowF][`vcol_${year}_${month}`]
                            : 0;
                      }
                    }

                    this.fullData[rowP][`atotal_${year}`] =
                      this.fullData[rowP][`acol_${year}_1`] +
                      this.fullData[rowP][`acol_${year}_2`] +
                      this.fullData[rowP][`acol_${year}_3`] +
                      this.fullData[rowP][`acol_${year}_4`] +
                      this.fullData[rowP][`acol_${year}_5`] +
                      this.fullData[rowP][`acol_${year}_6`] +
                      this.fullData[rowP][`acol_${year}_7`] +
                      this.fullData[rowP][`acol_${year}_8`] +
                      this.fullData[rowP][`acol_${year}_9`] +
                      this.fullData[rowP][`acol_${year}_10`] +
                      this.fullData[rowP][`acol_${year}_11`] +
                      this.fullData[rowP][`acol_${year}_12`];

                    this.fullData[rowP][`vtotal_${year}`] =
                      this.fullData[rowP][`vcol_${year}_1`] +
                      this.fullData[rowP][`vcol_${year}_2`] +
                      this.fullData[rowP][`vcol_${year}_3`] +
                      this.fullData[rowP][`vcol_${year}_4`] +
                      this.fullData[rowP][`vcol_${year}_5`] +
                      this.fullData[rowP][`vcol_${year}_6`] +
                      this.fullData[rowP][`vcol_${year}_7`] +
                      this.fullData[rowP][`vcol_${year}_8`] +
                      this.fullData[rowP][`vcol_${year}_9`] +
                      this.fullData[rowP][`vcol_${year}_10`] +
                      this.fullData[rowP][`vcol_${year}_11`] +
                      this.fullData[rowP][`vcol_${year}_12`];
                  }
                }
              }
            }
          }
        }

        this.rowData = this.fullData.filter((x) => x.isVisible);
        this.fullData4Import = [...this.fullData];

        this.setExpandable();

        if (type == "v") this.changeVolume();
        else this.changeAmount();

        this.setTotalRow();
      } else {
        this.translate.get("entryScreens.corrupted").subscribe((data: any) => {
          this.toastrService.error(data);
        });
      }
    };
  }

  private bringFocusBack() {
    let cell = this.gridApi.getFocusedCell();

    if (cell) {
      this.gridApi.setFocusedCell(cell.rowIndex, cell.column);
    }
  }

  onColumnSpread(event: any) {
    let newValue: number = event.value;
    let type = event.colDef.cellType;
    let year = event.colDef.cellYear;
    let month = event.colDef.cellMonth ? event.colDef.cellMonth : 0;
    let data = event.data;

    /* ay'dan veri girişi */
    if (month != 0) {
      data[`${type}col_${year}_${month}`] = newValue;
      this.columnSpread(
        data.unique_id,
        newValue,
        data.productId,
        type,
        year,
        month
      );

      /* net price dan volume/amount hesaplama */
      if (data[`npricecol_${year}_${month}`] != 0) {
        if (type == "v") {
          let newValue2 =
            Number(data[`vcol_${year}_${month}`]) *
            Number(data[`npricecol_${year}_${month}`]);
          data[`acol_${year}_${month}`] = newValue2;
          this.columnSpread(
            data.unique_id,
            newValue2,
            data.productId,
            "a",
            year,
            month
          );
        } else if (type == "a") {
          let newValue2 =
            Number(data[`acol_${year}_${month}`]) /
            Number(data[`npricecol_${year}_${month}`]);
          data[`vcol_${year}_${month}`] = newValue2;
          this.columnSpread(
            data.unique_id,
            newValue2,
            data.productId,
            "v",
            year,
            month
          );
        }
      }

      /* grid total row refresh */
      this.refreshRowTotal(data, year);

      this.gridApi.applyTransaction({ update: [data] });
      this.setTotalRow();
      this.bringFocusBack();
    } else if (month == 0) {
      /* yıl'dan veri girişi */
      //total den giriş
      this.columnSpreadTotal(
        data.unique_id,
        newValue,
        data.productId,
        type,
        year
      );

      var startM: any = 1;
      this.guidancePlanningPeriodList.forEach((element) => {
        if (element.year == year && element.planningType == "Monthly") {
          startM = element.startMonth;
          var actualsTotal = 0;
          for (let actM = 1; actM < startM; actM++) {
            actualsTotal += data[`${type}col_${year}_${actM}`];
          }

          var newValue =
            (data[`${type}total_${year}`] - actualsTotal) / (12 - (startM - 1));

          for (let mnth = startM; mnth < 13; mnth++) {
            data[`${type}col_${year}_${mnth}`] = newValue;
            this.columnSpread(
              data.unique_id,
              newValue,
              data.productId,
              type,
              year,
              mnth
            );

            /* net price dan volume/amount hesaplama */
            if (data[`npricecol_${year}_${mnth}`] != 0) {
              if (type == "v") {
                let newValue2 =
                  Number(data[`vcol_${year}_${mnth}`]) *
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`acol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "a",
                  year,
                  mnth
                );
              } else if (type == "a") {
                let newValue2 =
                  Number(data[`acol_${year}_${mnth}`]) /
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`vcol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "v",
                  year,
                  mnth
                );
              }
            }
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        } else if (element.year == year && element.planningType == "Yearly") {
          let newValue = data[`${type}total_${year}`] / 12;
          for (let mnth = 1; mnth < 13; mnth++) {
            data[`${type}col_${year}_${mnth}`] = newValue;
            this.columnSpread(
              data.unique_id,
              newValue,
              data.productId,
              type,
              year,
              mnth
            );

            /* net price dan volume/amount hesaplama */
            if (data[`npricecol_${year}_${mnth}`] != 0) {
              if (type == "v") {
                let newValue2 =
                  Number(data[`vcol_${year}_${mnth}`]) *
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`acol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "a",
                  year,
                  mnth
                );
              } else if (type == "a") {
                let newValue2 =
                  Number(data[`acol_${year}_${mnth}`]) /
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`vcol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "v",
                  year,
                  mnth
                );
              }
            }
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        }
      });
    }

    this.setTotalRow();
    this.gridApi.refreshCells({
      force: true,
    });
  }

  columnSpread(uniqueId, newValue, productId, type, year, month) {
    this.fullData.forEach((row) => {
      if (row.unique_id == uniqueId)
        row[`${type}col_${year}_${month}`] = newValue;
    });

    /* step 1 channel satırlarından form'a yazma */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId != null &&
        row.productChannelId == null
      ) {
        var formChannelLines = this.fullData.filter(
          (x) =>
            x.productId == row.productId &&
            x.productFormId == row.productFormId &&
            x.productChannelId != null
        );
        if (formChannelLines[0]) row[`${type}col_${year}_${month}`] = 0;

        formChannelLines.forEach((channelRow) => {
          row[`${type}col_${year}_${month}`] += Number(
            channelRow[`${type}col_${year}_${month}`]
          );
        });
      }
    });

    /* step 2 form satırlarından product'a yazma */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId == null &&
        row.productChannelId == null
      ) {
        var conversionRate = 1;
        var productFormLines = this.fullData.filter(
          (x) =>
            x.productId == row.productId &&
            x.productFormId != null &&
            x.productChannelId == null
        );
        if (productFormLines[0]) row[`${type}col_${year}_${month}`] = 0;

        productFormLines.forEach((formRow) => {
          if (formRow.conversion_rate != null && type == "v")
            conversionRate = formRow.conversion_rate;
          row[`${type}col_${year}_${month}`] +=
            Number(formRow[`${type}col_${year}_${month}`]) * conversionRate;
        });
      }
    });

    /* step 3 channel satırlarından product'a yazma granularity product - channel için */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId == null &&
        row.productChannelId == null
      ) {
        var productChannelLines = this.fullData.filter(
          (x) =>
            x.productId == productId &&
            x.productFormId == null &&
            x.productChannelId != null
        );
        if (productChannelLines[0]) row[`${type}col_${year}_${month}`] = 0;

        productChannelLines.forEach((channelRow) => {
          row[`${type}col_${year}_${month}`] += Number(
            channelRow[`${type}col_${year}_${month}`]
          );
        });
      }
    });

    this.columnSpreadTotal(uniqueId, null, productId, type, year);

    var rows = [...this.fullData];
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
    this.bringFocusBack();
  }

  columnSpreadTotal(uniqueId, newValue, productId, type, year) {
    if (newValue != null) {
      this.fullData.forEach((row) => {
        if (row.unique_id == uniqueId) row[`${type}total_${year}`] = newValue;
      });
    }

    /* step 1 channel satırlarından form'a yazma */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId != null &&
        row.productChannelId == null
      ) {
        var formChannelLines = this.fullData.filter(
          (x) =>
            x.productId == row.productId &&
            x.productFormId == row.productFormId &&
            x.productChannelId != null
        );
        if (formChannelLines[0]) row[`${type}total_${year}`] = 0;

        formChannelLines.forEach((channelRow) => {
          row[`${type}total_${year}`] += channelRow[`${type}total_${year}`];
        });
      }
    });

    /* step 2 form satırlarından product'a yazma */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId == null &&
        row.productChannelId == null
      ) {
        var conversionRate = 1;
        var productFormLines = this.fullData.filter(
          (x) =>
            x.productId == row.productId &&
            x.productFormId != null &&
            x.productChannelId == null
        );
        if (productFormLines[0]) row[`${type}total_${year}`] = 0;

        productFormLines.forEach((formRow) => {
          if (formRow.conversion_rate != null && type == "v")
            conversionRate = formRow.conversion_rate;
          row[`${type}total_${year}`] +=
            formRow[`${type}total_${year}`] * conversionRate;
        });
      }
    });

    /* step 3 channel satırlarından product'a yazma granularity product - channel için */
    this.fullData.forEach((row) => {
      if (
        row.productId == productId &&
        row.productFormId == null &&
        row.productChannelId == null
      ) {
        var productChannelLines = this.fullData.filter(
          (x) =>
            x.productId == productId &&
            x.productFormId == null &&
            x.productChannelId != null
        );
        if (productChannelLines[0]) row[`${type}total_${year}`] = 0;

        productChannelLines.forEach((channelRow) => {
          row[`${type}total_${year}`] += channelRow[`${type}total_${year}`];
        });
      }
    });

    var rows = [...this.fullData];
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
    this.bringFocusBack();
  }

  refreshRowTotal(data, year) {
    /* Aggrid refresh için */
    ["v", "a"].forEach((typ) => {
      var total = 0;
      for (var mnth = 1; mnth < 13; mnth++) {
        total += Number(data[`${typ}col_${year}_${mnth}`]);
      }
      data[`${typ}total_${year}`] = total;
    });
  }

  onCopy() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.value2copy = this.currentContextEvent.value;
  }

  onPaste() {
    if (this.value2copy == null || this.value2copy == "NaN") return;
    if (!this.isScreenValid) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    if (data.expandable == true) return;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth
      ? this.currentContextEvent.colDef.cellMonth
      : 0;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    var newValue = Number(this.value2copy);

    if (cellMonth != 0) {
      data[`${type}col_${year}_${cellMonth}`] = newValue;
      this.columnSpread(
        data.unique_id,
        newValue,
        data.productId,
        type,
        year,
        cellMonth
      );

      /* net price dan amount/volume hesaplama */
      if (data[`npricecol_${year}_${cellMonth}`] != 0) {
        if (type == "v") {
          newValue =
            Number(data[`vcol_${year}_${cellMonth}`]) *
            data[`npricecol_${year}_${cellMonth}`];
          data[`acol_${year}_${cellMonth}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            "a",
            year,
            cellMonth
          );
        } else if (type == "a") {
          newValue =
            Number(data[`acol_${year}_${cellMonth}`]) /
            data[`npricecol_${year}_${cellMonth}`];
          data[`vcol_${year}_${cellMonth}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            "v",
            year,
            cellMonth
          );
        }
      }
    } else {
      data[`${type}total_${year}`] = newValue;
      this.columnSpreadTotal(
        data.unique_id,
        newValue,
        data.productId,
        type,
        year
      );

      var startM: any = 1;
      this.guidancePlanningPeriodList.forEach((element) => {
        if (element.year == year && element.planningType == "Monthly") {
          startM = element.startMonth;
          var actualsTotal = 0;
          for (let actM = 1; actM < startM; actM++) {
            actualsTotal += data[`${type}col_${year}_${actM}`];
          }

          var newValue =
            (data[`${type}total_${year}`] - actualsTotal) / (12 - (startM - 1));

          for (let mnth = startM; mnth < 13; mnth++) {
            data[`${type}col_${year}_${mnth}`] = newValue;
            this.columnSpread(
              data.unique_id,
              newValue,
              data.productId,
              type,
              year,
              mnth
            );

            /* net price dan volume/amount hesaplama */
            if (data[`npricecol_${year}_${mnth}`] != 0) {
              if (type == "v") {
                let newValue2 =
                  Number(data[`vcol_${year}_${mnth}`]) *
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`acol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "a",
                  year,
                  mnth
                );
              } else if (type == "a") {
                let newValue2 =
                  Number(data[`acol_${year}_${mnth}`]) /
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`vcol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "v",
                  year,
                  mnth
                );
              }
            }
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        } else if (element.year == year && element.planningType == "Yearly") {
          let newValue = data[`${type}total_${year}`] / 12;
          for (let mnth = 1; mnth < 13; mnth++) {
            data[`${type}col_${year}_${mnth}`] = newValue;
            this.columnSpread(
              data.unique_id,
              newValue,
              data.productId,
              type,
              year,
              mnth
            );

            /* net price dan volume/amount hesaplama */
            if (data[`npricecol_${year}_${mnth}`] != 0) {
              if (type == "v") {
                let newValue2 =
                  Number(data[`vcol_${year}_${mnth}`]) *
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`acol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "a",
                  year,
                  mnth
                );
              } else if (type == "a") {
                let newValue2 =
                  Number(data[`acol_${year}_${mnth}`]) /
                  Number(data[`npricecol_${year}_${mnth}`]);
                data[`vcol_${year}_${mnth}`] = newValue2;
                this.columnSpread(
                  data.unique_id,
                  newValue2,
                  data.productId,
                  "v",
                  year,
                  mnth
                );
              }
            }
          }
          /* grid total row refresh */
          this.refreshRowTotal(data, year);

          this.gridApi.applyTransaction({ update: [data] });
          this.setTotalRow();
          this.bringFocusBack();
        }
      });
    }
    /* grid total row refresh */
    this.refreshRowTotal(data, year);

    this.setTotalRow();
    this.gridApi.applyTransaction({ update: [data] });
    this.gridApi.redrawRows();
    this.bringFocusBack();
  }

  sustainTrend() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data4sustain = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4sustain, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.years.includes(year - 1)) {
      let toplam: number = 0;
      for (var month = 1; month < 13; month++) {
        toplam =
          toplam + Number(data4sustain[`${type}col_${year - 1}_${month}`]); //bir önceki yılın trendinin hesaplar.
      }

      let ort: number = Math.floor(toplam / 12); //no float.
      for (var month = 1; month < 13; month++) {
        let oldVal = data4sustain[`${type}col_${year}_${month}`];
        let parentId = data4sustain.parentId;

        data4sustain[`${type}col_${year}_${month}`] = ort;
        this.columnSpread(oldVal, ort, parentId, type, year, month);
        this.columnSpreadTotal(oldVal, ort, parentId, type, year);

        /* net price dan amount/volume hesaplama */
        if (data4sustain[`npricecol_${year}_${month}`] != 0) {
          if (type == "v") {
            let o = data4sustain[`acol_${year}_${month}`];
            data4sustain[`acol_${year}_${month}`] =
              data4sustain[`vcol_${year}_${month}`] *
              data4sustain[`npricecol_${year}_${month}`];
            let n = data4sustain[`acol_${year}_${month}`];
            this.columnSpread(o, n, parentId, type, year, month);
          } else if (type == "a") {
            let o = data4sustain[`vcol_${year}_${month}`];
            data4sustain[`vcol_${year}_${month}`] =
              data4sustain[`acol_${year}_${month}`] /
              data4sustain[`npricecol_${year}_${month}`];
            let n = data4sustain[`vcol_${year}_${month}`];
            this.columnSpread(o, n, parentId, type, year, month);
          }
        }
      }

      //toplan column set
      data4sustain[`atotal_${year}`] = 0;
      for (var month = 1; month < 13; month++) {
        data4sustain[`atotal_${year}`] += Number(
          data4sustain[`acol_${year}_${month}`]
        );
      }

      data4sustain[`vtotal_${year}`] = 0;
      for (var month = 1; month < 13; month++) {
        data4sustain[`vtotal_${year}`] += Number(
          data4sustain[`vcol_${year}_${month}`]
        );
      }

      this.setTotalRow();
      this.gridApi.applyTransaction({ update: [data4sustain] });
      this.bringFocusBack();
    } else {
      this.translate.get("entryScreens.noPast").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
    }
  }

  onCellClicked(event) {
    if (event.column.colDef.field != "product") {
      return;
    }

    var parentId = event.data.id;
    var rows = [...this.fullData];

    let currentRow = rows.filter((row) => row.id == parentId)[0];
    let isOpen = currentRow.selected;

    let currentRows = rows.filter((row) => row.parentId == parentId);
    currentRows.forEach(function (row) {
      row.isVisible = !isOpen;
      if (isOpen) {
        row.selected = false;
        let subRows = rows.filter((r) => r.parentId == row.id);
        subRows.forEach(function (row) {
          row.isVisible = false;
        });
      }
    });
    currentRow.selected = !isOpen;
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  openRows() {
    // rowların hepsini açma
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  onEditNotes() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }
    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let note = this.noteValue;
    let data4note = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data4note, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth)
      data4note[`${type}not_${year}_1`] =
        note.trim() == "" ? null : note.trim();
    else
      data4note[`${type}not_${year}_${cellMonth}`] =
        note.trim() == "" ? null : note.trim();

    this.gridApi.applyTransaction({ update: [data4note] });
    this.gridApi.redrawRows();
    this.bringFocusBack();

    let data = [];
    this.gridApi.forEachNode(function (rowNode, index) {
      data.push(rowNode.data);
    });

    let forecast = new Forecast();
    forecast.rows = data;

    this.forecastService.update(forecast).subscribe((backendResult) => {
      if (backendResult.isSuccess) {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.success(data);
          });
      } else {
        this.translate
          .get(`messages.${backendResult.messageId}`)
          .subscribe((data: any) => {
            this.toastrService.error(data);
          });
      }
    });
  }

  onEditNotesShow() {
    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    if (cellMonth) {
      this.cellNote = data[`${type}not_${year}_${cellMonth}`];
    }
    if (!cellMonth) {
      this.cellNote = data[`${type}not_${year}_1`];
    }

    if (this.cellNote != null) {
      return this.cellNote;
    } else {
      return "no note..";
    }
  }

  onCarryForward() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (var month = cellMonth + 1; month < 13; month++) {
      var newValue = Number(data[`${type}col_${year}_${cellMonth}`]);
      data[`${type}col_${year}_${month}`] = newValue;
      this.columnSpread(
        data.unique_id,
        newValue,
        data.productId,
        type,
        year,
        month
      );

      /* net price dan amount/volume hesaplama */
      if (data[`npricecol_${year}_${month}`] != 0) {
        if (type == "v") {
          newValue =
            Number(data[`vcol_${year}_${month}`]) *
            data[`npricecol_${year}_${month}`];
          data[`acol_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            "a",
            year,
            month
          );
        } else if (type == "a") {
          newValue =
            Number(data[`acol_${year}_${month}`]) /
            data[`npricecol_${year}_${month}`];
          data[`vcol_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            "v",
            year,
            month
          );
        }
      }
    }

    /* grid total row refresh */
    this.refreshRowTotal(data, year);

    this.gridApi.applyTransaction({ update: [data] });
    this.setTotalRow();
    this.bringFocusBack();
  }

  onIncreaseAllMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.increaseAllValue > -1) {
        let value = this.increaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          var newValue =
            Number(data[`${type}col_${year}_${month}`]) + Number(value);
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.increaseAllValueP > -1) {
        let value = this.increaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month}`]) +
              (Number(data[`${type}col_${year}_${month}`]) * Number(value)) /
                100
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onIncreaseMonthly(typeF) {
    // 0 -> value, 1-> %
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    let type = this.currentContextEvent.colDef.cellType;
    let year = this.currentContextEvent.colDef.cellYear;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (typeF == 0) {
      if (this.increaseValue > -1) {
        let value = this.increaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) + Number(value)
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    } else {
      if (this.increaseValueP > -1) {
        let value = this.increaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) +
              (Number(data[`${type}col_${year}_${month - 1}`]) *
                Number(value)) /
                100
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
        return;
      }
    }
  }

  onDecreaseMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.decreaseValue > -1) {
        let value = this.decreaseValue;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) - Number(value)
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    } else {
      if (this.decreaseValueP > -1) {
        let value = this.decreaseValueP;

        for (var month = cellMonth + 1; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month - 1}`]) -
              (Number(data[`${type}col_${year}_${month - 1}`]) *
                Number(value)) /
                100
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    }
  }

  onDecreaseAllMonthly(typeF) {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    var data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;

    var type = this.currentContextEvent.colDef.cellType;
    var year = this.currentContextEvent.colDef.cellYear;
    var cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (typeF == 0) {
      if (this.decreaseAllValue > -1) {
        let value = this.decreaseAllValue;

        for (var month = cellMonth; month < 13; month++) {
          var newValue = Number(
            Number(data[`${type}col_${year}_${month}`]) - Number(value)
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    } else {
      if (this.decreaseAllValueP > -1) {
        let value = this.decreaseAllValueP;

        for (var month = cellMonth; month < 13; month++) {
          let newValue = Number(
            Number(data[`${type}col_${year}_${month}`]) -
              (Number(data[`${type}col_${year}_${month}`]) * Number(value)) /
                100
          );
          data[`${type}col_${year}_${month}`] = newValue;
          this.columnSpread(
            data.unique_id,
            newValue,
            data.productId,
            type,
            year,
            month
          );

          /* net price dan amount/volume hesaplama */
          if (data[`npricecol_${year}_${month}`] != 0) {
            if (type == "v") {
              newValue =
                Number(data[`vcol_${year}_${month}`]) *
                data[`npricecol_${year}_${month}`];
              data[`acol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "a",
                year,
                month
              );
            } else if (type == "a") {
              newValue =
                Number(data[`acol_${year}_${month}`]) /
                data[`npricecol_${year}_${month}`];
              data[`vcol_${year}_${month}`] = newValue;
              this.columnSpread(
                data.unique_id,
                newValue,
                data.productId,
                "v",
                year,
                month
              );
            }
          }
        }
        /* grid total row refresh */
        this.refreshRowTotal(data, year);

        this.gridApi.applyTransaction({ update: [data] });
        this.setTotalRow();
        this.bringFocusBack();
      } else {
        this.translate.get("entryScreens.lower0").subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      }
    }
  }

  copyActuals() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    let data = this.gridApi.getDisplayedRowAtIndex(
      this.currentContextEvent.rowIndex
    ).data;
    let year = this.currentContextEvent.colDef.cellYear;
    let type = this.currentContextEvent.colDef.cellType;
    let cellMonth = this.currentContextEvent.colDef.cellMonth;

    var isEditable = this.isEditable(data, cellMonth, year);

    if (!isEditable) {
      this.translate.get("entryScreens.readOnly").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    if (!cellMonth) {
      this.translate
        .get("entryScreens.notAvailable")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    for (let month = 1; month < 13; month++) {
      if (data[`${type}col_-${this.year4actual}_${month}`]) {
        let newVal = Number(data[`${type}col_-${this.year4actual}_${month}`]);
        let oldVal = Number(data[`${type}col_${year}_${month}`]);
        let parentId = data.parentId;

        data[`${type}col_${year}_${month}`] =
          data[`${type}col_-${this.year4actual}_${month}`];
        this.columnSpread(oldVal, newVal, parentId, type, year, month);
        this.columnSpreadTotal(oldVal, newVal, parentId, type, year);

        /* net price dan amount/volume hesaplama */
        if (data[`npricecol_${year}_${month}`] != 0) {
          if (type == "v") {
            let o = data[`acol_${year}_${month}`];
            data[`acol_${year}_${month}`] =
              data[`vcol_${year}_${month}`] *
              data[`npricecol_${year}_${month}`];
            let n = data[`acol_${year}_${month}`];
            this.columnSpread(o, n, parentId, "a", year, month);
          } else if (type == "a") {
            let o = data[`vcol_${year}_${month}`];
            data[`vcol_${year}_${month}`] =
              data[`acol_${year}_${month}`] /
              data[`npricecol_${year}_${month}`];
            let n = data[`vcol_${year}_${month}`];
            this.columnSpread(o, n, parentId, "v", year, month);
          }
        }
      }
    }

    //toplan column set
    data[`atotal_${year}`] = 0;
    for (var month = 1; month < 13; month++) {
      data[`atotal_${year}`] += Number(data[`acol_${year}_${month}`]);
    }
    data[`vtotal_${year}`] = 0;
    for (var month = 1; month < 13; month++) {
      data[`vtotal_${year}`] += Number(data[`vcol_${year}_${month}`]);
    }

    this.setTotalRow();
    this.gridApi.applyTransaction({ update: [data] });
    this.bringFocusBack();

    return true;
  }

  copyScenario() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.forecastService.listPrices(this.copyScenarioId).subscribe((result) => {
      if (!result) {
        this.translate
          .get("entryScreens.no4scenario")
          .subscribe((data: string) => {
            this.toastrService.warning(data);
          });
        return;
      }

      this.fullDataS = result.data;

      for (let rowS in this.fullDataS) {
        for (let row in this.fullData) {
          if (
            this.fullDataS[rowS].productId == this.fullData[row].productId &&
            this.fullDataS[rowS].productFormId ==
              this.fullData[row].productFormId &&
            this.fullDataS[rowS].productChannelId ==
              this.fullData[row].productChannelId
          ) {
            let types = ["v", "a"];
            let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

            for (let type of types) {
              for (let year of this.years) {
                for (let month of months) {
                  this.fullData[row][`${type}col_${year}_${month}`] =
                    this.fullDataS[rowS][`${type}col_${year}_${month}`];
                }
                this.fullData[row][`${type}total_${year}`] =
                  this.fullDataS[rowS][`${type}total_${year}`];
              }
            }
          }
        }
      }

      this.rowData = this.fullData.filter((x) => x.isVisible);
      this.fullData4Import = [...this.fullData];

      this.setExpandable();
      this.setTotalRow();
      this.changeVolume();
    });

    return true;
  }

  copyPlanning() {
    if (this.isScreenValid == false) {
      this.translate
        .get("entryScreens.forecastApprovalOut")
        .subscribe((data: string) => {
          this.toastrService.warning(data);
        });
      return;
    }

    if (this.guidanceId != this.currentGuidanceId) {
      this.translate.get("entryScreens.notUsable").subscribe((data: string) => {
        this.toastrService.warning(data);
      });
      return;
    }

    this.scenarioService
      .listScenariosByGuidance(this.copyPlanningGuidanceId, 1)
      .subscribe((result) => {
        this.scenarioCopyList = result;
        this.scenarioGuiList = result;
      });
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isCharDecimal(charStr) {
      return ".".indexOf(charStr) === 0;
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }

  @ViewChild("userMenu") userMenu: TemplateRef<any>;
  sub: Subscription;
  overlayRef: OverlayRef | null;

  open({ x, y }: MouseEvent, user) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.userMenu, this.viewContainerRef, {
        $implicit: user,
      })
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
    this.rulesPopup = false;
    this.notesPopup = false;
    this.colorPopup = false;
  }
}
