import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { ActualsExportComponent } from "./export/actuals-export.component";
import { ActualsImportComponent } from "./import/actuals-import.component";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { Actuals } from "@planard/@core/entities/actuals";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./actuals.component.html",
  styleUrls: ["./actuals.component.scss"],
})
export class ActualsComponent implements OnInit {
  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  rows = [];
  isRoleValid: boolean;

  dataSource: MatTableDataSource<Actuals>;
  displayedColumns: string[] = [
    "fileName",
    "type",
    "period",
    "createdDate",
    "actions",
  ];
  @ViewChild(ActualsComponent, { static: true }) table: any[];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public forecastService: ForecastService,
    public guidanceService: GuidanceService,
    public actualsService: ActualsService,
    public scenarioService: ScenarioService,
    public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.guidanceService.listAll().subscribe((result) => {
      this.guidanceList = result;
    });
    this.scenarioService.listAll().subscribe((result) => {
      this.scenarioList = result;
    });
    this.getData();
    setTimeout(() => {
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "fileName", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }
    }, 1500);
  }

  getData() {
    this.actualsService.listAllFiles().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.rows = data;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  dowlandTemplate() {
    const dialogRef = this.dialog.open(ActualsExportComponent, {
      width: "700px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  onUpload() {
    const dialogRef = this.dialog.open(ActualsImportComponent, {
      width: "700px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      setTimeout(() => {
        this.ngOnInit();
      }, 1500);
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.actual").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.actualsService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        }
      });
    });
  }
}
