import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { CostType } from "@planard/@core/entities/costType";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { CostTypeAddComponent } from "./add/cost-type-add.component";
import { EnumCostType } from "@planard/@core/enums/EnumCostType";
import { Category } from "@planard/@core/entities/category";
import { SubCategory } from "@planard/@core/entities/subcategory";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./cost-type-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class CostTypeNewComponent implements OnInit {
  displayedColumns: string[] = [
    "costType",
    "categoryId",
    "subCategoryId",
    "name",
    "lifeSpan",
    "actions",
  ];
  dataSource: MatTableDataSource<CostType>;
  controls: any[];

  costTypeList: EnumCostType[] = EnumCostType.values;
  categoryList: Category[] = [];
  subCategoryList: SubCategory[] = [];
  subCatList: SubCategory[] = [];
  isRoleValid: boolean;
  package: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public costTypeService: CostService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      this.package = this.authService.getCompanyPackage();
      this.displayedColumns =
        this.package == "premium"
          ? [
              "costType",
              "categoryId",
              "subCategoryId",
              "name",
              "lifeSpan",
              "actions",
            ]
          : ["categoryId", "subCategoryId", "name", "lifeSpan", "actions"];
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.costTypeService.listCategories().subscribe((result) => {
      this.categoryList = result;
    });
    this.costTypeService.listSubcategories().subscribe((result) => {
      this.subCategoryList = result;
      this.subCatList = [...result];
    });
    this.costTypeService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                Validators.maxLength(20),
                removeSpaces,
              ]),
              costType: new FormControl(EnumCostType[`${entity.costType}`], [
                Validators.required,
              ]),
              lifeSpan: new FormControl(entity.lifeSpan, [
                Validators.required,
                Validators.min(0),
              ]),
              categoryId: new FormControl(entity.categoryId, [
                Validators.required,
              ]),
              subCategoryId: new FormControl(entity.subCategoryId, [
                Validators.required,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var costtype = this.getElement(index);
      costtype[field] = control.value;

      if (costtype.costType != EnumCostType.ASSETS) {
        costtype.lifeSpan = 0;
      }

      if (costtype.costType != EnumCostType.ASSETS) {
        this.costTypeService.update(costtype).subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.ngOnInit();
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
            this.ngOnInit();
          }
        });
      } else if (
        costtype.costType == EnumCostType.ASSETS &&
        costtype.categoryId == 6
      ) {
        this.costTypeService.update(costtype).subscribe((result) => {
          if (result.isSuccess) {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.success(data);
              });
            this.ngOnInit();
          } else {
            this.translate
              .get(`messages.${result.messageId}`)
              .subscribe((data: any) => {
                this.toastrService.error(data);
              });
            this.ngOnInit();
          }
        });
      } else if (
        costtype.costType == EnumCostType.ASSETS &&
        costtype.categoryId != 6
      ) {
        this.toastrService.error(
          "Asset types category must be the Assets (Depreciation)."
        );
      }
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(CostTypeAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("common.formNotValid").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.costTypeService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  getCategory(row: CostType) {
    if (
      row.id != null &&
      row.categoryId != null &&
      this.categoryList.length != 0
    ) {
      for (let ct of Object.keys(this.categoryList)) {
        if (this.categoryList[ct].id === row.categoryId) {
          return this.categoryList[ct].name;
        }
      }
    }
  }

  getSubCategory(row: CostType) {
    if (
      row.id != null &&
      row.subCategoryId != null &&
      this.subCategoryList.length != 0
    ) {
      for (let ct of Object.keys(this.subCategoryList)) {
        if (row.subCategoryId == this.subCategoryList[ct].id) {
          return this.subCategoryList[ct].name;
        }
      }
    }
  }

  subCategorySet(categoryId: number) {
    if (categoryId > 0) {
      this.subCatList.length = 0;
      for (let pf of Object.keys(this.subCategoryList)) {
        if (categoryId === this.subCategoryList[pf].categoryId) {
          this.subCatList.push(this.subCategoryList[pf]);
        }
      }
    }
  }
}
