import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { ProductFamily } from "@planard/@core/entities/productFamily";
import { ProductForm } from "@planard/@core/entities/productForm";
import { Channel } from "@planard/@core/entities/channel";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ProductFamilyService } from "@planard/@core/backend/service/product-family.service";
import { ChannelService } from "@planard/@core/backend/service/channel.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";

@Component({
  selector: "sales-trend",
  templateUrl: "./sales-trend.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: initial;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class SalesTrendComponent implements OnInit {
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];
  timescaleList = [
    { value: 0, label: "Monthly" },
    { value: 1, label: "Yearly" },
  ];

  //Lists
  currencyList: EnumCurrency[] = EnumCurrency.values;
  planningPeriodList: GuidancePlanningPeriod[];
  scenarioList: Scenario[];
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  indicatorList = [
    { value: 0, label: "Units" },
    { value: 1, label: "Amount" },
  ];
  granularityList = [
    { value: 0, label: "Business Unit" },
    { value: 1, label: "Product Family" },
    { value: 2, label: "Product" },
    { value: 3, label: "Channel" },
  ];

  businessUnitList: BusinessUnit[];
  productFamilyBUList: ProductFamily[] = [];
  productFamilyList: ProductFamily[];
  productPFList: Products[] = [];
  productList: Products[];
  formPList: ProductForm[] = [];
  channelList: Channel[];
  businessUnitCList: BusinessUnit[] = [];

  normalbusinessUnitList: BusinessUnit[];
  normalproductList: Products[];
  normalproductFamilyList: ProductFamily[];
  companyCurrency: string;
  companyNumberFormat: string;

  parentId: number[] = [];
  subId: number = 0;

  //Values
  fullData: any;
  years: any;
  scenarioId = 37;
  selectedYear: number;
  timescale: number = 0;
  currency: any = 0;
  indicator: number = 0;
  rate: number = 1;
  img = "";
  productName: string;
  currencyName: string;
  guidanceId: number;
  period: any;
  reportScreenshot: ReportScreenshot = {};
  granularity: any = 0;
  isRun: boolean;

  businessUnitId: number[] = [];
  productFamilyBUId: number;
  productFamilyId: number[] = [];
  productPFId: number;
  productId: number[] = [];
  formPId: number;
  channelId: number[] = [];
  businessUnitCId: number;
  guidanceIsStart: boolean = null;
  isRoleValid: boolean;
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  periodGroup: any;

  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [];

  scenarioControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  indicatorControl = new FormControl("", [Validators.required]);
  scaleControl = new FormControl("", [Validators.required]);
  periodControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  //GRA CONTROLS
  buControl = new FormControl("", [Validators.required]);
  pfBuControl = new FormControl("", [Validators.required]);
  pfControl = new FormControl("", [Validators.required]);
  productPfControl = new FormControl("", [Validators.required]);
  productControl = new FormControl("", [Validators.required]);
  formControl = new FormControl("", [Validators.required]);
  channelControl = new FormControl("", [Validators.required]);
  buChControl = new FormControl("", [Validators.required]);

  actualsName;
  currentPlanName;

  numberFormat: string;
  decimalFormat: string;

  isCurrenctYearExist = false;

  current_year = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  @ViewChild("screen", { static: true }) screen: any;
  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public forecastService: ForecastService,
    public guidanceService: GuidanceService,
    public actualsService: ActualsService,
    public businessUnitService: BusinessUnitService,
    public productFamilyService: ProductFamilyService,
    public channelService: ChannelService,
    private numberDecimal: NumberDecimalPipe,
    private router: Router,
    public authService: AuthService,
    public translate: TranslateService,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    public productsService: ProductsService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      var companyPackage = this.authService.getCompanyPackage();

      if (companyPackage == "basic")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isBusinessUnitPlanner();

      if (companyPackage == "premium")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isBusinessUnitApprover() ||
          this.authService.isBusinessUnitPlanner();

      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.companyNumberFormat =
        this.authService.getNumberFormat() == "noScale"
          ? ""
          : this.authService.getNumberFormat() == "thousands"
          ? "k"
          : this.authService.getNumberFormat() == "millions"
          ? "m"
          : "n/a";
      this.companyCurrency = this.authService.getCompanyCurrency();
    }, 150);
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result: any) => {
      var guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceIsStart = result.start ? true : false;
      if (this.guidanceIsStart) {
        this.guidanceService
          .getPlanningRecord(result.id)
          .subscribe((result2: any) => {
            this.planningPeriodList = result2;
            result2.forEach((element) => {
              if (element.year == this.current_year)
                this.isCurrenctYearExist = true;
              let ele = {
                value: element.id,
                label: `${guidanceName}'${element.year}`,
                isActuals: false,
              };
              this.currentPlanPeriodList.push(ele);
            });
            this.setPeriod();
          });
        this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
          this.guidanceFxRateList = data;
        });
      }
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.scenarioService.listScenariosWithSales().subscribe((result: any) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
          this.setScenarioLists();
        }
      });
    });
    this.channelService.listAll().subscribe((result: any) => {
      this.channelList = result;
    });
    this.businessUnitService.listAll().subscribe((result: any) => {
      this.normalbusinessUnitList = result;
    });
    this.productFamilyService.listAll().subscribe((result: any) => {
      this.normalproductFamilyList = result;
    });
    this.productsService.listAll().subscribe((result: any) => {
      this.normalproductList = result;
    });
  }

  setPeriod() {
    let type = this.indicator == 0 ? "Volume" : "Amount";
    this.actualsList = [];
    this.period = null;
    //actuals yılları
    this.actualsService.listFilePeriod("Volume").subscribe((data) => {
      data.forEach((element) => {
        //if ( element.period == this.current_year && this.isCurrenctYearExist ) return;
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });

    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    }, 1000);
  }

  setScenarioLists() {
    this.forecastService
      .listForecastChannels(this.scenarioId)
      .subscribe((result: any) => {
        this.channelList = result;
        if (result.length == 0) {
          this.translate.get("reports.warning4").subscribe((data: any) => {
            this.toastr.warning(data);
          });
        }
      });
    this.forecastService
      .listForecastBusinessUnits(this.scenarioId)
      .subscribe((result: any) => {
        this.businessUnitList = result;
        if (result.length == 0) {
          this.translate.get("reports.warning5").subscribe((data: any) => {
            this.toastr.warning(data);
          });
        }
      });
    this.forecastService
      .listForecastProductFamilies(this.scenarioId)
      .subscribe((result: any) => {
        this.productFamilyList = result;
        if (result.length == 0) {
          this.translate.get("reports.warning6").subscribe((data: any) => {
            this.toastr.warning(data);
          });
        }
      });
    this.forecastService
      .listForecastProducts(this.scenarioId)
      .subscribe((result: any) => {
        this.productList = result;
        if (result.length == 0) {
          this.translate.get("reports.warning7").subscribe((data: any) => {
            this.toastr.warning(data);
          });
        }
      });
  }

  // SECOND CAT
  setProductFamilyBU() {
    // business unitin product familyleri
    if (!this.businessUnitId[1] && this.businessUnitId[0]) {
      this.productFamilyBUList.length = 0;
      this.forecastService
        .listProductFamiliesByBusinessUnit(
          this.scenarioId,
          this.businessUnitId[0]
        )
        .subscribe((result: any) => {
          this.productFamilyBUList = result;
          if (result.length == 0) {
            this.translate.get("reports.warning8").subscribe((data: any) => {
              this.toastr.error(data);
            });
          }
        });
    } else {
      this.productFamilyBUList.length = 0;
    }
  }

  setProductPF() {
    // product familynin productları
    if (!this.productFamilyId[1] && this.productFamilyId[0]) {
      this.productPFList.length = 0;
      this.forecastService
        .listProductsByProductFamily(this.scenarioId, this.productFamilyId[0])
        .subscribe((result: any) => {
          this.productPFList = result;
          if (result.length == 0) {
            this.translate.get("reports.warning9").subscribe((data: any) => {
              this.toastr.error(data);
            });
          }
        });
    } else {
      this.productPFList.length = 0;
    }
  }

  setFormP() {
    // productın formları
    if (!this.productFamilyId[1] && this.productId[0]) {
      this.formPList.length = 0;
      this.forecastService
        .listFormsByProduct(this.scenarioId, this.productId[0])
        .subscribe((result: any) => {
          this.formPList = result;
          if (result.length == 0) {
            this.translate.get("reports.warning10").subscribe((data: any) => {
              this.toastr.error(data);
            });
          }
        });
    } else {
      this.formPList.length = 0;
    }
  }

  setBuC() {
    // channelın bu ları
    if (!this.channelId[1] && this.channelId[0]) {
      this.businessUnitCList.length = 0;
      this.forecastService
        .listBusinessUnitsByChannel(this.scenarioId, this.channelId[0])
        .subscribe((result: any) => {
          this.businessUnitCList = result;
          if (result.length == 0) {
            this.translate.get("reports.warning11").subscribe((data: any) => {
              this.toastr.error(data);
            });
          }
        });
    } else {
      this.businessUnitCList.length = 0;
    }
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.indicatorControl.valid == false ||
      this.granularityControl.valid == false ||
      this.scaleControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.indicatorControl.valid == true &&
      this.currencyControl.valid == true &&
      this.scaleControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      if (this.timescale == 0) {
        if (this.periodControl.valid == true) {
          if (this.granularity == 0) {
            // business unit
            if (this.buControl.valid == true) {
              if (!this.businessUnitId[1]) {
                if (this.pfBuControl.valid == true) {
                  this.run();
                } else {
                  this.translate
                    .get("reports.allrequired")
                    .subscribe((data: any) => {
                      this.toastr.error(data);
                    });
                  return;
                }
              } else {
                this.run();
              }
            } else {
              this.translate
                .get("reports.allrequired")
                .subscribe((data: any) => {
                  this.toastr.error(data);
                });
              return;
            }
          } else if (this.granularity == 1) {
            // product family
            if (this.pfControl.valid == true) {
              if (!this.productFamilyId[1]) {
                if (this.productPfControl.valid == true) {
                  this.run();
                } else {
                  this.translate
                    .get("reports.allrequired")
                    .subscribe((data: any) => {
                      this.toastr.error(data);
                    });
                  return;
                }
              } else {
                this.run();
              }
            } else {
              this.translate
                .get("reports.allrequired")
                .subscribe((data: any) => {
                  this.toastr.error(data);
                });
              return;
            }
          } else if (this.granularity == 2) {
            // product
            if (this.productControl.valid == true) {
              if (!this.productId[1]) {
                if (this.formControl.valid == true) {
                  this.run();
                } else {
                  this.translate
                    .get("reports.allrequired")
                    .subscribe((data: any) => {
                      this.toastr.error(data);
                    });
                  return;
                }
              } else {
                this.run();
              }
            } else {
              this.translate
                .get("reports.allrequired")
                .subscribe((data: any) => {
                  this.toastr.error(data);
                });
              return;
            }
          } else if (this.granularity == 3) {
            // channel
            if (this.channelControl.valid == true) {
              if (!this.channelId[1]) {
                if (this.buChControl.valid == true) {
                  this.run();
                } else {
                  this.translate
                    .get("reports.allrequired")
                    .subscribe((data: any) => {
                      this.toastr.error(data);
                    });
                  return;
                }
              } else {
                this.run();
              }
            } else {
              this.translate
                .get("reports.allrequired")
                .subscribe((data: any) => {
                  this.toastr.error(data);
                });
              return;
            }
          }
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      } else {
        if (this.granularity == 0) {
          // business unit
          if (this.buControl.valid == true) {
            if (!this.businessUnitId[1]) {
              if (this.pfBuControl.valid == true) {
                this.run();
              } else {
                this.translate
                  .get("reports.allrequired")
                  .subscribe((data: any) => {
                    this.toastr.error(data);
                  });
                return;
              }
            } else {
              this.run();
            }
          } else {
            this.translate.get("reports.allrequired").subscribe((data: any) => {
              this.toastr.error(data);
            });
            return;
          }
        } else if (this.granularity == 1) {
          // product family
          if (this.pfControl.valid == true) {
            if (!this.productFamilyId[1]) {
              if (this.productPfControl.valid == true) {
                this.run();
              } else {
                this.translate
                  .get("reports.allrequired")
                  .subscribe((data: any) => {
                    this.toastr.error(data);
                  });
                return;
              }
            } else {
              this.run();
            }
          } else {
            this.translate.get("reports.allrequired").subscribe((data: any) => {
              this.toastr.error(data);
            });
            return;
          }
        } else if (this.granularity == 2) {
          // product
          if (this.productControl.valid == true) {
            if (!this.productId[1]) {
              if (this.formControl.valid == true) {
                this.run();
              } else {
                this.translate
                  .get("reports.allrequired")
                  .subscribe((data: any) => {
                    this.toastr.error(data);
                  });
                return;
              }
            } else {
              this.run();
            }
          } else {
            this.translate.get("reports.allrequired").subscribe((data: any) => {
              this.toastr.error(data);
            });
            return;
          }
        } else if (this.granularity == 3) {
          // channel
          if (this.channelControl.valid == true) {
            if (!this.channelId[1]) {
              if (this.buChControl.valid == true) {
                this.run();
              } else {
                this.translate
                  .get("reports.allrequired")
                  .subscribe((data: any) => {
                    this.toastr.error(data);
                  });
                return;
              }
            } else {
              this.run();
            }
          } else {
            this.translate.get("reports.allrequired").subscribe((data: any) => {
              this.toastr.error(data);
            });
            return;
          }
        }
      }
    }
  }

  run() {
    this.isRun = false;

    this.barChartData = [];
    this.barChartOptions = {};
    this.reportData.chartLabels = [];

    var actuals: any;
    var periodIds: any;

    if (this.period != null) {
      if (this.period.isActuals == true) {
        actuals = this.period.value;
        periodIds = 0;
      } else {
        actuals = 0;
        periodIds = this.period.value;
      }
    }

    if (this.timescale == 1) {
      actuals = 0;
      periodIds = 0;
    }

    if (this.granularity == 0) {
      this.parentId = this.businessUnitId;
      if (this.businessUnitId[1]) {
        this.subId = 0;
      } else {
        this.subId = this.productFamilyBUId;
      }
    } else if (this.granularity == 1) {
      this.parentId = this.productFamilyId;
      if (this.productFamilyId[1]) {
        this.subId = 0;
      } else {
        this.subId = this.productPFId;
      }
    } else if (this.granularity == 2) {
      this.parentId = this.productId;
      if (this.productId[1]) {
        this.subId = 0;
      } else {
        this.subId = this.formPId;
      }
    } else if (this.granularity == 3) {
      this.parentId = this.channelId;
      if (this.channelId[1]) {
        this.subId = 0;
      } else {
        this.subId = this.businessUnitCId;
      }
    }

    if (this.indicator == 0) this.currency = 0;

    let colors = [
      "lightgreen",
      "blue",
      "purple",
      "orange",
      "yellow",
      "darkblue",
      "pink",
      "darkgreen",
      "brown",
      "DarkTurquoise",
      "rose",
    ];

    this.forecastService
      .salesTrend(
        this.guidanceIsStart ? this.scenarioId : 0,
        this.granularity,
        this.parentId,
        this.subId,
        this.indicator,
        this.timescale,
        actuals,
        periodIds,
        this.currency
      )
      .subscribe((result) => {
        if (result.data.length == 0) {
          this.translate.get("reports.warning3").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }

        let min = result.data[0].line[0];
        let max = result.data[0].line[0];

        for (let index in result.data) {
          for (let ind in result.data[index].line) {
            if (result.data[index].line[ind] < min) {
              min = result.data[index].line[ind];
            }
            if (result.data[index].line[ind] > max) {
              max = result.data[index].line[ind];
            }
          }
        }

        for (let index in result.data) {
          for (let ind in result.data[index].line) {
            result.data[index].line[ind] =
              result.data[index].line[ind].toFixed(2);
          }
        }

        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;
        let indicator = this.indicator;

        this.reportData.chartLabels = result.chartLabels;
        for (let index in result.data) {
          this.barChartData.push({
            type: "line",
            fill: false,
            borderWidth: 2,
            //tension: 0.2,
            data: result.data[index].line,
            label: `${result.data[index].label}`,
            backgroundColor: `${colors[index]}`,
            borderColor: `${colors[index]}`,
          });
        }

        //setTimeout(() => {
        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            mode: "index",
            intersect: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: min,
                  suggestedMax: max + 10,
                },
              },
            ],
          },
          title: {
            display: true,
            text: "Sales Trend",
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              display: "auto",
              color: "red",
              align: "top",
              formatter: function (value, context) {
                //if ( indicator == 1 )
                return numberDecimal.transform(
                  Number(value),
                  decimalFormat,
                  numberFormat
                );
                //return value ;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
          },
        };
        //}, 1000);

        setTimeout(() => {
          this.isRun = true;
        }, 1500);
      });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  take() {
    if (this.isRun == true) {
      this.captureService
        .getImage(this.screen.nativeElement, true, 400)
        .pipe(
          tap((img) => {
            this.img = img;
          })
        )
        .subscribe();
    } else {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
    }
  }

  reset() {
    this.img = "";
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  setProductName(id: number) {
    for (let sc of Object.keys(this.productList)) {
      if (id == this.productList[sc].id) {
        return this.productList[sc].name;
      }
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(this.scenarioId)} / ${
      this.granularityList[this.granularity].label
    } / ${this.period.label} / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Sales Trend";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }
}
