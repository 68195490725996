import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  isPasswordSame = true;

  name: string;
  mail: string;
  approver: string;
  department: string;
  subdepartment: string;
  role: string;
  centrals: string;
  products: string;
  companyPackage: string;

  form: FormGroup = new FormGroup({
    name: new FormControl(""),
    email: new FormControl(""),
    approver: new FormControl(""),
    department: new FormControl(""),
    subdepartment: new FormControl(""),
    roles: new FormControl(""),
    centralcosts: new FormControl(""),
    products: new FormControl(""),
    currentPassword: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
    rePassword: new FormControl("", [Validators.required]),
  });

  options: FormGroup;

  constructor(
    public fb: FormBuilder,
    public accountsService: AccountsService,
    public authService: AuthService,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: "auto",
    });
    this.authService.getAppUser();
    setTimeout(() => {
      this.companyPackage = this.authService.getCompanyPackage();
    }, 150);
  }

  ngOnInit(): void {
    this.accountsService.getSignedUser().subscribe((result: any) => {
      this.name = result.name;
      this.mail = result.email;
      this.approver = result.approverStr != null ? result.approverStr : "-";
      this.department =
        result.departmentStr != null ? result.departmentStr : "-";
      this.subdepartment =
        result.subDepartmentStr != null ? result.subDepartmentStr : "-";
      this.role = result.rolesStr != "" ? result.rolesStr : "-";
    });
  }

  save() {
    if (!this.passwordCheck()) {
      this.isPasswordSame = false;
      return;
    }
    this.isPasswordSame = true;
    let passsword = {
      oldPassword: this.form.get("currentPassword").value,
      newPassword: this.form.get("rePassword").value,
    };
    if (this.form.valid) {
      this.accountsService.changePassword(passsword).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    }
  }

  passwordCheck() {
    return (
      this.form.get("password").value === this.form.get("rePassword").value
    );
  }
}
