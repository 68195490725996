import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { SettingsAddComponent } from "./add/settings-add.component";
import { SupersettingsService } from "@planard/@core/backend/service/supersettings.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Supersettings } from "@planard/@core/entities/supersettings";

@Component({
  templateUrl: "./settings.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 10%;
      }
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class SettingsComponent implements OnInit {
  isRoleValid: boolean = false;
  desc;
  file;
  fileUrl = null;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public supersettingsService: SupersettingsService,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public translate: TranslateService,
    public toastr: ToastrService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isSuperAdmin();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  displayedColumns = [
    "description",
    "type",
    "country",
    "createdDate",
    "actions",
  ];

  dataSource = new MatTableDataSource<Supersettings>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.supersettingsService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getUrl(b64Data, type) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: type == 0 ? "application/pdf" : "image/png",
    });

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }

  addDialog() {
    const dialogRef = this.dialog.open(SettingsAddComponent, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.supersettingsService.add(result).subscribe((backendResult) => {
          if (backendResult.isSuccess == true) {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastr.success(data);
                this.ngOnInit();
              });
          } else {
            this.translate
              .get(`messages.${backendResult.messageId}`)
              .subscribe((data: any) => {
                this.toastr.error(data);
              });
          }
        });
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.economicreport").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.supersettingsService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastr.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastr.error(data);
                });
            }
          });
        }
      });
    });
  }
}
