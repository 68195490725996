import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Inject,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { ProductForm } from "@planard/@core/entities/productForm";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ProductFormAddComponent } from "./add/productForm-add.component";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { AuthService } from "@planard/auth/services/auth.service";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./productForm-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe],
})
export class ProductFormNewComponent implements OnInit {
  displayedColumns = ["form", "conversionRate", "actions"];
  dataSource: MatTableDataSource<ProductForm>;
  controls: any[];
  IsEmpty: boolean;

  numberFormat: string;
  decimalFormat: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public productsService: ProductsService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    private numberDecimal: NumberDecimalPipe,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ProductFormNewComponent>,
    @Inject(MAT_DIALOG_DATA) public productId: number
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
    }, 150);
  }

  ngOnInit() {
    this.productsService.listAllForms(this.productId).subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) this.IsEmpty = false;
      else this.IsEmpty = true;

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "form", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              form: new FormControl(entity.form, [
                Validators.required,
                removeSpaces,
              ]),
              conversionRate: new FormControl(entity.conversionRate, [
                Validators.required,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var form = this.getElement(index);
      form[field] = control.value;
      this.productsService.updateForm(form).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(ProductFormAddComponent, {
      width: "500px",
      data: this.productId,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.productform").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.productsService.deleteForm(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
