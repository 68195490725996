import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { BusinessUnitService } from "@planard/@core/backend/service/business-unit.service";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { BusinessUnit } from "@planard/@core/entities/businessUnit";
import { BusinessUnitAddComponent } from "@planard/admin/general/business-unit/add/add.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";
@Component({
  templateUrl: "./business-unit-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class BusinessUnitNewComponent implements OnInit {
  displayedColumns: string[] = ["name", "actions"];
  dataSource: MatTableDataSource<BusinessUnit>;
  controls: any[];
  isRoleValid: boolean;

  firstPage;
  lastPage;
  nextPage;
  itemsPerPage;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public businessUnitService: BusinessUnitService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.businessUnitService.listAll().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;

        /*this.dataSource.paginator._intl.firstPageLabel = this.translate.instant('paginator.firstPageLabel');
        this.dataSource.paginator._intl.lastPageLabel = this.translate.instant('paginator.lastPageLabel');
        this.dataSource.paginator._intl.nextPageLabel = this.translate.instant('paginator.nextPageLabel');
        this.dataSource.paginator._intl.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPageLabel');*/

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        // const sortState: Sort = {active: 'name', direction: 'asc'};
        // this.sort.active = sortState.active;
        // this.sort.direction = sortState.direction;
        // this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var businessUnit = this.getElement(index);
      businessUnit[field] = control.value;

      this.businessUnitService.update(businessUnit).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(BusinessUnitAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.businessunit").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.businessUnitService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }
}
