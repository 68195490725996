import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { ProcessGraph } from "@planard/@core/entities/processGraph";
import { Process } from "@planard/@core/entities/process";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ForecastService } from "@planard/@core/backend/service/forecast.service";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
@Component({
  selector: "sales-drivers",
  templateUrl: "./sales-drivers.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})

export class SalesDriversComponent implements OnInit {
  processList: ProcessGraph[];
  data: ProcessGraph[] = [];
  dataList: Process[] = [];
  guidanceName: string;
  countList = [5, 10, 15, 20];
  periodList = ["All"];
  productList: Products[];
  scenarioList: Scenario[];
  planningPeriodList: GuidancePlanningPeriod[];
  currencyList: EnumCurrency[] = EnumCurrency.values;
  granularityList = [
    { value: 0, label: "Business Unit" },
    { value: 1, label: "Product Family" },
    { value: 2, label: "Product" },
    { value: 3, label: "Form" },
    { value: 4, label: "Channel" },
  ];
  img = "";
  granularity: number = 2;
  scenarioName: string;
  productName: string;
  currencyName: string;
  guidanceId: number;
  currency: number = 0;
  scenarioId: number;
  productId: number;
  period: number[] = [];
  count: number = 5;
  reportScreenshot: ReportScreenshot = {};
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  isRun: boolean;
  periodCompList: any[] = [];
  forecastScenarioId: number;
  years: number[];
  indicatorList = [
    { value: 0, label: "Units" },
    { value: 1, label: "Amount" },
  ];
  indicator: number = 0;
  guidanceIsStart: boolean = null;
  startYear: any;
  endYear: any;
  isRoleValid: boolean;
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  periodGroup: any;
  companyCurrency: string;
  companyNumberFormat: string;

  actualsName;
  currentPlanName;

  isCurrenctYearExist = false;

  topLabel;
  bottomLabel;

  numberFormat: string;
  decimalFormat: string;

  data2top = [];
  data2bottom = [];
  chartData;
  chartDataBottom;
  options;
  optionsBottom;

  countControl = new FormControl("", [Validators.required]);
  scenarioControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  period1Control = new FormControl("", [Validators.required]);
  period2Control = new FormControl("", [Validators.required]);
  indicatorControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  current_year = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  @ViewChild("screen", { static: true }) screen: any;
  constructor(
    public toastrService: ToastrService,
    public scenarioService: ScenarioService,
    public guidanceService: GuidanceService,
    public productsService: ProductsService,
    public forecastService: ForecastService,
    public actualsService: ActualsService,
    private numberDecimal: NumberDecimalPipe,
    private router: Router,
    public authService: AuthService,
    public translate: TranslateService,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    public timelineService: TimelineService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      var companyPackage = this.authService.getCompanyPackage();

      if (companyPackage == "basic") this.isRoleValid = false;

      if (companyPackage == "premium")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isBusinessUnitApprover() ||
          this.authService.isBusinessUnitPlanner();

      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.companyNumberFormat =
        this.authService.getNumberFormat() == "noScale"
          ? ""
          : this.authService.getNumberFormat() == "thousands"
          ? "k"
          : this.authService.getNumberFormat() == "millions"
          ? "m"
          : "n/a";
      this.companyCurrency = this.authService.getCompanyCurrency();
    }, 150);

    this.translate.get("reports.top").subscribe((data: any) => {
      this.topLabel = data;
    });
    this.translate.get("reports.bottom").subscribe((data: any) => {
      this.bottomLabel = data;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.top").subscribe((data: any) => {
        this.topLabel = data;
      });
      this.translate.get("reports.bottom").subscribe((data: any) => {
        this.bottomLabel = data;
      });
    });
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidanceName = result.planningCycleName;
    });
    this.productsService.listAll().subscribe((result: any) => {
      this.productList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      var guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceIsStart = result.start ? true : false;

      if (this.guidanceIsStart) {
        this.guidanceService
          .getPlanningRecord(result.id)
          .subscribe((result2: any) => {
            this.planningPeriodList = result2;
            result2.forEach((element) => {
              if (element.year == this.current_year)
                this.isCurrenctYearExist = true;
              let ele = {
                value: element.id,
                label: `${guidanceName}'${element.year}`,
                isActuals: false,
              };
              this.currentPlanPeriodList.push(ele);
            });
            this.setPeriod();
          });

        this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
          this.guidanceFxRateList = data;
        });
      }
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.scenarioService.listScenariosWithSales().subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.forecastScenarioId = element.id;
          this.scenarioId = element.id;
        }
      });
      this.forecastService
        .listPrices(this.forecastScenarioId)
        .subscribe((result) => {
          this.years = result.years.sort((a, b) => a - b);

          for (var index = 0; index < this.years.length - 1; index++) {
            var bef = this.years[index];
            var aft = this.years[index + 1];
            var ele = { label: `${aft} vs ${bef}`, value: [aft, bef] };
            this.periodCompList.push(ele);
          }
        });
    });
  }

  setPeriod() {
    let type = this.indicator == 0 ? "Volume" : "Amount";
    this.actualsList = [];
    this.startYear = null;
    this.endYear = null;
    this.actualsService.listFilePeriod("Volume").subscribe((data) => {
      data.forEach((element) => {
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });
    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
    }, 1000);
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  setScenario(id: number) {
    for (let sc of Object.keys(this.scenarioList)) {
      if (id == this.scenarioList[sc].id) {
        return this.scenarioList[sc].name;
      }
    }
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario(this.scenarioId)} / ${
      this.granularityList[this.granularity].label
    } / ${this.startYear.label} vs ${this.endYear.label} / ${
      this.indicatorList[this.indicator].label
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Growth Drivers";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  getPlanPeriod(id: number) {
    for (let ele of this.planningPeriodList) {
      if (id == ele.id) {
        return ele.year;
      }
    }
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.countControl.valid == false ||
      this.granularityControl.valid == false ||
      this.period1Control.valid == false ||
      this.period2Control.valid == false ||
      this.indicatorControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.countControl.valid == true &&
      this.granularityControl.valid == true &&
      this.period1Control.valid == true &&
      this.period2Control.valid == true &&
      this.currencyControl.valid == true &&
      this.indicatorControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    this.isRun = false;

    var startactuals: any;
    var startperiodIds: any;

    var endactuals: any;
    var endperiodIds: any;

    if (this.startYear != null) {
      if (this.startYear.isActuals == true) {
        startactuals = this.startYear.value;
        startperiodIds = 0;
      } else {
        startactuals = 0;
        startperiodIds = this.startYear.value;
      }
    }

    if (this.endYear != null) {
      if (this.endYear.isActuals == true) {
        endactuals = this.endYear.value;
        endperiodIds = 0;
      } else {
        endactuals = 0;
        endperiodIds = this.endYear.value;
      }
    }

    //validations
    if (startactuals == 0 && endactuals == 0) {
      var val1 = this.getPlanPeriod(startperiodIds);
      var val2 = this.getPlanPeriod(endperiodIds);
      if (val1 > val2 || val1 == val2) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }
    } else if (startactuals != 0 && endactuals != 0) {
      if (startactuals > endactuals || startactuals == endactuals) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }
    } else if (startactuals != 0 && endactuals == 0) {
      var val2 = this.getPlanPeriod(endperiodIds);
      if (startactuals > val2 || startactuals == val2) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }
    } else if (startactuals == 0 && endactuals != 0) {
      var val1 = this.getPlanPeriod(startperiodIds);
      if (val1 > endactuals || val1 == endactuals) {
        this.translate.get("reports.warning12").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }
    }

    if (this.indicator == 0) this.currency = 0;

    this.forecastService
      .growthDrivers(
        this.count,
        this.guidanceIsStart ? this.scenarioId : 0,
        this.granularity,
        startactuals,
        startperiodIds,
        endactuals,
        endperiodIds,
        this.indicator,
        this.currency
      )
      .subscribe((result) => {
        if (!result) return;
        if (result.data2top.length == 0 || result.data2bottom.length == 0) {
          this.translate.get("reports.warning3").subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }

        this.data2top = result.data2top;
        this.data2bottom = result.data2bottom;

        let bottomColors = [];
        for (let data of this.data2bottom) {
          if (data.value < 0) {
            bottomColors.push("#F34628");
          } else {
            bottomColors.push("#65C149");
          }
        }

        let topColors = [];
        for (let data of this.data2top) {
          if (data.value < 0) {
            topColors.push("#F34628");
          } else {
            topColors.push("#65C149");
          }
        }

        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;

        this.chartData = {
          labels: this.data2top.map(
            (t) => t.product + " " + "(%" + `${Number(t.percent).toFixed(2)})`
          ),
          datasets: [
            {
              data: this.data2top.map((t) => t.value[0] /*.toFixed(2)*/),
              label: "Top Products",
              datalabels: {
                color: "#454545",
                anchor: "start",
                align: "end",
                offset: 5,
                formatter: function (value, context) {
                  //if ( indicator == 1 )
                  return numberDecimal.transform(
                    Number(value),
                    decimalFormat,
                    numberFormat
                  );
                  //return value ;
                },
              },
              backgroundColor: topColors,
            },
          ],
        };

        this.chartDataBottom = {
          labels: this.data2bottom.map((t) => t.product),
          datasets: [
            {
              data: this.data2bottom.map((t) => t.value[0] /*.toFixed(2)*/),
              label: "Bottom Products",
              datalabels: {
                color: "#454545",
                anchor: "start",
                align: "end",
                offset: 5,
                formatter: function (value, context) {
                  return numberDecimal.transform(
                    Number(value),
                    decimalFormat,
                    numberFormat
                  );
                },
              },
              backgroundColor: bottomColors,
            },
          ],
        };

        this.options = {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.topLabel,
          },
          legend: {
            display: false,
            position: "bottom",
          },
          tooltips: {},
          scales: {
            xAxes: [
              {
                stacked: true,
                display: false,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        };

        this.optionsBottom = {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.bottomLabel,
          },
          legend: {
            display: false,
            position: "bottom",
          },
          tooltips: {},
          scales: {
            xAxes: [
              {
                stacked: true,
                display: false,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        };

        this.isRun = true;
      });
  }
}
