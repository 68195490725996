import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GuidanceTarget } from "@planard/@core/entities/guidanceTarget";
import { FxRate } from "@planard/@core/entities/fxRate";
import { TargetAddComponent } from "./add/target-add.component";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./target-edit-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 10%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class TargetEditNewComponent implements OnInit {
  displayedColumns: string[] = [
    "year",
    "salesTarget",
    "costTarget",
    "fxRateId",
    "actions",
  ];
  dataSource: MatTableDataSource<GuidanceTarget>;
  controls: any[];

  yearList = [
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear(),
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 1,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 2,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 3,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 4,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 5,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 6,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 7,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 8,
    new Date(
      new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
    ).getFullYear() + 9,
  ];

  fxRateList: FxRate[];

  guidanceId: number;
  IsEmpty: boolean;

  numberFormat: string;
  decimalFormat: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<TargetEditNewComponent>,
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    private guidanceService: GuidanceService,
    private numberDecimal: NumberDecimalPipe,
    private numberReverseDecimal: NumberDecimalReversePipe,
    public authService: AuthService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.guidanceId = data.guidanceId;

    this.authService.getAppUser();
    setTimeout(() => {
      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
    }, 250);
  }

  formatNumber(value, decimal, number) {
    return this.numberDecimal.transform(value, decimal, number);
  }

  formatNumberReverse(string, decimal, number) {
    return this.numberReverseDecimal.transform(string, decimal, number);
  }

  ngOnInit() {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.guidanceService.listAllFxRates().subscribe((data) => {
      this.fxRateList = data;
    });
    this.guidanceService.getTargetRecord(this.guidanceId).subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (data != null) {
        this.IsEmpty = false;
      } else {
        this.IsEmpty = true;
      }

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "year", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              year: new FormControl(entity.year, [Validators.required]),
              costTarget: new FormControl(
                entity.costTarget /*this.formatNumber(entity.costTarget, this.decimalFormat, this.numberFormat)*/,
                [Validators.required]
              ),
              salesTarget: new FormControl(
                entity.salesTarget /*this.formatNumber(entity.salesTarget, this.decimalFormat, this.numberFormat)*/,
                [Validators.required]
              ),
              fxRateId: new FormControl(entity.fxrateId, [Validators.required]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var target = this.getElement(index);
      target[field] = control.value;

      this.guidanceService.updateTarget(target).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(TargetAddComponent, {
      width: "500px",
      data: {
        guidanceId: this.guidanceId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.target").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.guidanceService.deleteTarget(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
