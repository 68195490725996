import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AgGridAngular } from "ag-grid-angular";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { Scenario } from "@planard/@core/entities/scenario";
import { MatDialog } from "@angular/material/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ProductForm } from "@planard/@core/entities/productForm";
import { PlanService } from "@planard/@core/backend/service/plan.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { FxRate } from "@planard/@core/entities/fxRate";
import { ActualsService } from "@planard/@core/backend/service/actuals.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NumberDecimalReversePipe } from "@planard/@core/pipes/NumberDecimalReversePipe";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import * as XLSX from "xlsx";

@Component({
  templateUrl: "./cost-overview.component.html",
  styleUrls: ["./cost-overview.component.scss"],
  providers: [NumberDecimalPipe, NumberDecimalReversePipe],
})
export class CostOverviewComponent implements OnInit {
  //#region
  @ViewChild("agGrid") agGrid: AgGridAngular;
  private gridApi;
  private gridColumnApi;

  scenarioList: Scenario[];
  scenarioList2Base: Scenario[];
  scenarioCopyList: Scenario[];
  months: string[];
  years: number[];
  guidanceList: Guidance[];
  scenarioGuiList: Scenario[] = [];
  formList: ProductForm[];
  title = "app";
  cellNote: any;
  volume: boolean;
  amount: boolean;
  scenario: Scenario;
  scenarioId: number;
  copyScenarioId: number;
  guidanceId: number;
  percent2coloring: Number = 40;
  sheetName: string = "Costs";
  exportPopup: boolean = false;
  importPopup: boolean = false;
  rulesPopup: boolean = false;
  notesPopup: boolean = false;
  input: boolean;
  notes: boolean;
  guidaneSet: boolean;
  exelPriceData: any;
  exelDiscountData: any;
  salesForecastId: number;
  exelYear: number = 2020;
  exelName: string;
  file: File;
  filelist: any;
  arrayBuffer: any;
  forecastLenght: number;
  value2copy: any;
  increaseValue: number;
  decreaseValue: number;
  noteValue: any;
  file_name: string;
  formConversionRate: number = 1;
  currencyList: EnumCurrency[] = EnumCurrency.values;
  currency: any = 0;
  actualsList: any[] = [];
  currentPlanPeriodList: any[] = [];
  oldPlansPeriodList: any[] = [];
  isPercent: boolean;
  period: any;
  periodGroup: any;
  guidanceName: any;
  exelHeaderList: any[] = [];
  subDeptList: any[];
  subDepartmentId = 0;
  granularity = 0;
  granularityList = [
    { value: 1, label: "Sub Department" },
    { value: 0, label: "Total Company" },
  ];
  actualsName;
  currentPlanName;
  oldPlansName;
  companyCurrency: string;
  companyNumberFormat: string;
  package: string;
  isOpen: boolean = false;

  isCurrenctYearExist = false;
  reportScreenshot: ReportScreenshot = {};
  img = "";
  isSet: boolean;
  imgName: any;
  imgDescription: any;
  guidanceIsStart: any = null;
  isRoleValid: boolean;
  displayTotalBase: any;

  display: any = 0;
  displayList = [
    { value: 0, label: "Cost" },
    { value: 1, label: "Change(%)" },
    { value: 2, label: "Change(Value)" },
  ];

  defaultColDef = {
    sortable: false,
    flex: 1,
    minWidth: 60,
    filter: false,
    resizable: true,
    cellEditor: "numericCellEditor",
  };

  components = { numericCellEditor: this.getNumericCellEditor() };
  columnDefs = [];
  rowData: any;
  fullData: any;
  fullData4Import: any;
  year4actual: number;

  rowDataS: any;
  fullDataS: any;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];

  loadingTemplate: any;
  noRowsTemplate: any;

  currentplanperiods: GuidancePlanningPeriod[];
  oldplanperiods: GuidancePlanningPeriod[] = [];
  allPlansPeriods = [];
  headerName: any;
  isRun: boolean;
  firstYear: any;

  isAdmin;
  isApprover;

  numberFormat: string;
  decimalFormat: string;

  fullYear = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  ).getFullYear();

  @ViewChild("screen", { static: true }) screen: any;

  scenarioControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);
  periodControl = new FormControl("", [Validators.required]);
  displayControl = new FormControl("", [Validators.required]);
  subDeptControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);

  headerPeriodList = [];
  rawData;
  costName = null;
  name = "Angular";
  data2Exel: any;
  headData: any;
  //#endregion

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public toastrService: ToastrService,
    public reportScreenshotService: ReportScreenshotService,
    private captureService: NgxCaptureService,
    private numberDecimal: NumberDecimalPipe,
    public planService: PlanService,
    private router: Router,
    public translate: TranslateService,
    public departmentService: DepartmentService,
    public authService: AuthService,
    public actualsService: ActualsService,
    public productsService: ProductsService,
    public accountsService: AccountsService,
    public guidanceService: GuidanceService,
    public scenarioService: ScenarioService,
    public dialog: MatDialog
  ) {
    this.translate.get("agGrid.report").subscribe((data: any) => {
      this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
    });
    this.translate.get("agGrid.no").subscribe((data: any) => {
      this.noRowsTemplate = `"<span">${data}</span>"`;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("agGrid.report").subscribe((data: any) => {
        this.loadingTemplate = `<span class="ag-overlay-loading-center">${data}</span>`;
      });
      this.translate.get("agGrid.no").subscribe((data: any) => {
        this.noRowsTemplate = `"<span">${data}</span>"`;
      });

      if (this.translate.currentLang == "en") this.costName = "Cost";
      if (this.translate.currentLang == "tr") this.costName = "Masraf";
      this.changeVolume();
    });

    this.authService.getAppUser();
    setTimeout(() => {
      var companyPackage = this.authService.getCompanyPackage();

      if (companyPackage == "basic")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isSupportFunctionPlanner();
      if (companyPackage == "premium")
        this.isRoleValid =
          this.authService.isAdmin() ||
          this.authService.isSupportFunctionApprover() ||
          this.authService.isSupportFunctionPlanner();

      this.isAdmin = this.authService.isAdmin();
      this.isApprover = this.authService.isApprover();
      this.package = this.authService.getCompanyPackage();
      this.departmentService
        .listAllSub /*planService.listApproverUsersSubDepartments*/
        ()
        .subscribe((result: any) => {
          this.subDeptList = result;
        });

      if (!this.isApprover && !this.isAdmin) {
        this.accountsService.getSignedUser().subscribe((result: any) => {
          this.subDeptList.forEach((element) => {
            if (element.id == result.subDepartmentId)
              this.subDeptList = [element];
          });
        });
        this.granularityList = [{ value: 1, label: "Sub Department" }];
      } else {
        this.granularityList = [
          { value: 0, label: "Total Company" },
          { value: 1, label: "Sub Department" },
        ];
      }

      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);

      this.numberFormat = this.authService.getNumberFormat();
      this.decimalFormat = this.authService.getDecimalFormat();
      this.companyNumberFormat =
        this.authService.getNumberFormat() == "noScale"
          ? ""
          : this.authService.getNumberFormat() == "thousands"
          ? "k"
          : this.authService.getNumberFormat() == "millions"
          ? "m"
          : "n/a";
      this.companyCurrency = this.authService.getCompanyCurrency();
    }, 150);

    this.months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }

  ngOnInit() {
    this.scenarioService.listScenariosWithImpact("C").subscribe((result) => {
      this.scenarioList = result;
      result.forEach((element) => {
        if (element.isBase == true) {
          this.scenarioId = element.id;
        }
      });
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.guidanceName = result.planningCycleName;
      this.guidanceId = result.id;
      this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
        this.guidanceFxRateList = data;
      });
      //plan yılları
      this.guidanceService
        .getPlanningRecord(this.guidanceId)
        .subscribe((result2) => {
          this.currentplanperiods = result2;
          result2.forEach((element) => {
            if (element.year == this.fullYear) this.isCurrenctYearExist = true;
            let ele = {
              value: element.id,
              label: `${this.guidanceName}'${element.year}`,
              isActuals: false,
            };
            this.currentPlanPeriodList.push(ele);
            this.headerPeriodList.push(ele);
          });
          this.setPeriod();
        });
    });
  }

  setPeriod() {
    //eski plan yılları
    // this.guidanceService.listAll().subscribe((result) => {
    //   result.forEach((ele) => {
    //     if (ele.id == this.guidanceId) {
    //       return;
    //     }
    //     var guidance = ele;
    //     this.guidanceService.getPlanningRecord(ele.id).subscribe((result) => {
    //       result.forEach((element) => {
    //         this.oldplanperiods.push(element);
    //         if (
    //           element.year == this.fullYear ||
    //           element.year == this.fullYear - 1
    //         ) {
    //           let ele = {
    //             value: element.id,
    //             label: `${guidance.planningCycleName}'${element.year}`,
    //             isActuals: false,
    //           };
    //           this.oldPlansPeriodList.push(ele);
    //           this.headerPeriodList.push(ele);
    //         }
    //       });
    //     });
    //   });
    // });
    //actuals yılları
    let type = "Cost";
    this.actualsList = [];
    this.actualsService.listFilePeriod(type).subscribe((data) => {
      data.forEach((element) => {
        //if ( element.period == this.fullYear  && this.isCurrenctYearExist ) return;
        let ele = {
          value: element.period,
          label: `A'${element.period}`,
          isActuals: true,
        };
        this.actualsList.push(ele);
      });
    });

    //translate
    this.translate.get("reports.Actuals").subscribe((data: any) => {
      this.actualsName = data;
    });
    this.translate.get("reports.Current Plan").subscribe((data: any) => {
      this.currentPlanName = data;
    });
    this.translate.get("reports.Old Plans").subscribe((data: any) => {
      this.oldPlansName = data;
    });
    this.translate.onLangChange.subscribe((lang) => {
      this.translate.get("reports.Actuals").subscribe((data: any) => {
        this.actualsName = data;
      });
      this.translate.get("reports.Current Plan").subscribe((data: any) => {
        this.currentPlanName = data;
      });
      this.translate.get("reports.Old Plans").subscribe((data: any) => {
        this.oldPlansName = data;
      });
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        // {
        //   name: this.oldPlansName, // value plannig id olucak
        //   period: this.oldPlansPeriodList,
        // },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];
      this.period = [...this.currentPlanPeriodList];
      if (this.actualsList[this.actualsList.length - 1])
        this.period.push(this.actualsList[this.actualsList.length - 1]);
    });

    setTimeout(() => {
      this.periodGroup = [
        {
          name: this.actualsName, // value yıl olucak
          period: this.actualsList,
        },
        // {
        //   name: this.oldPlansName, // value plannig id olucak
        //   period: this.oldPlansPeriodList,
        // },
        {
          name: this.currentPlanName, // value plannig id olucak
          period: this.currentPlanPeriodList,
        },
      ];

      // this.oldplanperiods.forEach((element) => {
      //   this.allPlansPeriods.push(element);
      // });
      this.currentplanperiods.forEach((element) => {
        this.allPlansPeriods.push(element);
      });

      this.period = [...this.currentPlanPeriodList];
      if (this.actualsList[this.actualsList.length - 1])
        this.period.push(this.actualsList[this.actualsList.length - 1]);
    }, 1500);
  }

  onDelete() {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setDomLayout("autoHeight");
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  isValid() {
    if (
      this.scenarioControl.valid == false ||
      this.currencyControl.valid == false ||
      this.periodControl.valid == false ||
      this.displayControl.valid == false ||
      this.subDeptControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    } else if (
      this.scenarioControl.valid == true &&
      this.currencyControl.valid == true &&
      this.periodControl.valid == true &&
      this.displayControl.valid == true &&
      this.subDeptControl.valid == true
    ) {
      this.run();
    }
  }

  run() {
    if (this.granularity == 0) this.subDepartmentId = 0;

    var actuals: any = [];
    var periodIds: any = [];

    if (this.period != null) {
      this.period.forEach((element) => {
        if (element.isActuals == true) {
          actuals.push(element.value);
        } else {
          periodIds.push(element.value);
        }
      });
    }

    if (actuals.length == 0) actuals = 0;
    if (periodIds.length == 0) periodIds = 0;

    this.planService
      .costOverview(
        this.scenarioId,
        actuals,
        periodIds,
        this.currency,
        this.subDepartmentId
      )
      .subscribe((result) => {
        this.isRun = true;

        result.data.forEach((element) => {
          if (element.expandable == undefined) {
            element.expandable = false;
          }
          result.years.forEach((year) => {
            if (!element[`total_${year}`]) {
              element[`total_${year}`] = 0;
            }
          });
        });

        var neg = [];
        var pos = [];

        neg = result.years.filter((x) => x < 0).sort((a, b) => b - a);
        pos = result.years.filter((x) => x > 0).sort((a, b) => a - b);

        this.years = [...neg, ...pos];
        var yearsCopyActual = [
          ...neg.map((x) => x * -1),
          ...[this.fullYear],
        ];
        this.year4actual = yearsCopyActual[0];

        let data = [...result.data];
        this.rawData = [...result.data];

        if (this.display == 0) {
          this.fullData = result.data;
          this.fullData4Import = [...result.data];
          this.rowData = [...result.data].filter((x) => x.isVisible);
          this.firstYear = null;
        } else if (this.display == 1) {
          // DISPLAY == GROWTH %
          this.setTotalRowBase(data);
          for (let row of data) {
            let secondVal = 0;
            for (
              let yearIndex = 0;
              yearIndex < this.years.length;
              yearIndex++
            ) {
              // [(V2-V1)/V1] x 100
              if (row[`total_${this.years[yearIndex + 1]}`] != undefined) {
                let val = Number(row[`total_${this.years[yearIndex + 1]}`]);
                row[`total_${this.years[yearIndex + 1]}`] =
                  (Number(((row[`total_${this.years[yearIndex + 1]}`] -
                    (yearIndex == 0
                      ? row[`total_${this.years[yearIndex]}`]
                      : secondVal)) /
                    (yearIndex == 0
                      ? row[`total_${this.years[yearIndex]}`]
                      : secondVal)) *
                  100).toFixed(2));
                // if (row[`total_${this.years[yearIndex]}`] == 0)
                //   row[`total_${this.years[yearIndex + 1]}`] = undefined;
                secondVal = val;
              }
            }
            this.firstYear = this.years[0];
          }
          this.fullData = data;
          this.rowData = [...result.data].filter((x) => x.isVisible);
        } else if (this.display == 2) {
          for (let row of data) {
            let secondVal = 0;
            for (
              let yearIndex = 0;
              yearIndex < this.years.length;
              yearIndex++
            ) {
              // [V2-V1]
              if (row[`total_${this.years[yearIndex + 1]}`] != undefined) {
                let val = row[`total_${this.years[yearIndex + 1]}`];
                row[`total_${this.years[yearIndex + 1]}`] =
                  row[`total_${this.years[yearIndex + 1]}`] -
                  (yearIndex == 0
                    ? row[`total_${this.years[yearIndex]}`]
                    : secondVal); // / row[`total_${this.years[yearIndex]}`]);
                secondVal = val;
              }
            }
            this.firstYear = this.years[0];
          }
          this.fullData = data;
          this.rowData = [...result.data].filter((x) => x.isVisible);
        }
        this.forecastLenght = this.fullData.length;
        this.changeVolume();
        this.setTotalRow();
      });
  }

  setExpandable() {
    for (let line of this.fullData) {
      var id = line.id;
      var isParent = false;
      for (let lin of this.fullData) {
        if (id == lin.parentId) {
          isParent = true;
        }
      }
      line.expandable = isParent;
    }
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.setScenario()} / ${
      this.displayList[this.display].label
    } / ${this.getFxRate(this.currency)}`;
    this.reportScreenshot.reportName = "Cost Overview";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
        }
      });
  }

  setTotalRow() {
    var totalRow = {};
    totalRow["cost"] = "TOTAL : ";

    if (this.display == 1) {
      // 20230502 ortalama hesabını kapattırdılar.
      // for (let yearIndex = 1; yearIndex < this.years.length; yearIndex++) {
      //   totalRow[`total_${this.years[yearIndex]}`] =
      //     totalRow[`total_${this.years[yearIndex]}`] /
      //     this.rawData.filter((x) => x[`parentId`] == 0).length;
      // }
      totalRow = { ...this.displayTotalBase };
      let secondVal = 0;
      for (let yearIndex = 0; yearIndex < this.years.length; yearIndex++) {
        if (totalRow[`total_${this.years[yearIndex + 1]}`] != undefined) {
          let val = totalRow[`total_${this.years[yearIndex + 1]}`];
          totalRow[`total_${this.years[yearIndex + 1]}`] =
            ((totalRow[`total_${this.years[yearIndex + 1]}`] -
              (yearIndex == 0
                ? totalRow[`total_${this.years[yearIndex]}`]
                : secondVal)) /
              (yearIndex == 0
                ? totalRow[`total_${this.years[yearIndex]}`]
                : secondVal)) *
            100;
          if (totalRow[`total_${this.years[yearIndex]}`] == 0)
            totalRow[`total_${this.years[yearIndex + 1]}`] = undefined;
          secondVal = val;
        }
      }
      totalRow[`total_${this.years[0]}`] = undefined;
      this.gridApi.setPinnedBottomRowData([totalRow]);
    } else {
      for (let year of this.years) {
        let total = 0;
        for (let row of this.rawData) {
          if (
            row[`parentId`] == 0 &&
            row[`total_${year}`] != undefined &&
            row[`total_${year}`] != Infinity
          ) {
            total = total + Number(row[`total_${year}`]);
          }
        }
        totalRow[`total_${year}`] = total;
      }
      this.gridApi.setPinnedBottomRowData([totalRow]);
    }
  }

  setTotalRowBase(data) {
    var totalRow = {};
    totalRow["cost"] = "TOTAL : ";

    for (let year of this.years) {
      let total = 0;
      for (let row of data) {
        if (
          row[`parentId`] == 0 &&
          row[`total_${year}`] != undefined &&
          row[`total_${year}`] != Infinity
        ) {
          total = total + Number(row[`total_${year}`]);
        }
      }
      totalRow[`total_${year}`] = total;
    }
    this.displayTotalBase = { ...totalRow };
  }

  getProductDef() {
    return {
      headerName:
        this.costName != null
          ? this.costName
          : this.granularityList[this.granularity]?.label,
      field: "cost",
      lockPosition: true,
      pinned: "left",
      editable: this.cellEditable,
      minWidth: 200,
      visible: false,
      cellRenderer: this.productCellRenderer,
    };
  }

  cellEditable(params) {
    return false;
  }

  productCellRenderer(params) {
    let style = "";
    if (params.data.parentId != 0) style = 'style="padding-left: 10px;"';
    if (params.data.expandable && params.data.selected) {
      if (
        params.data[`costId`] == null &&
        params.data[`subCategoryId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-tree-open" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (params.data.expandable) {
      if (
        params.data[`costId`] == null &&
        params.data[`subCategoryId`] != null
      ) {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          `<span style="color: green;">` +
          params.value +
          `</span>` +
          ``
        );
      } else {
        return (
          `<span class="ag-icon ag-icon-contracted" ` +
          style +
          `></span> ` +
          params.value +
          ``
        );
      }
    } else if (
      !params.data.expandable &&
      params.data[`costId`] == null &&
      params.data[`subCategoryId`] == null &&
      params.data.cost != "TOTAL : "
    ) {
      // product has no child
      return `<span` + style + `></span> ` + params.value + ``;
    } else if (
      !params.data.expandable &&
      params.data[`costId`] == null &&
      params.data.cost != "TOTAL : "
    ) {
      // form has no child
      return (
        `<span ` +
        style +
        `></span> ` +
        `<span style="color: green;">` +
        params.value +
        `</span>` +
        ``
      );
    } else if (params.data.cost == "TOTAL : ") {
      return (
        `<span style="padding-left: 40px;"></span>` +
        `<span style="color: #ff7500;">` +
        params.value +
        `</span>`
      );
    }
    return (
      `<span style="padding-left: 40px;"></span>` +
      `<span style="color: blue;">` +
      params.value +
      `</strong>`
    );
  }

  getYearColumnDef(year) {
    var display = this.display;
    let first = this.firstYear;
    let numberDecimal = this.numberDecimal;
    let decimalFormat = this.decimalFormat;
    let numberFormat = this.numberFormat;
    let headerPeriodList = this.headerPeriodList;
    return {
      type: "rightAligned",
      year: year,
      headerName: `${
        year < 0
          ? "A`" + year * -1
          : headerPeriodList.filter((x) => x.value == year)[0].label
      }`,
      valueGetter: function (params) {
        return Number(params.data[`total_${year}`]).toFixed(2);
      },
      cellStyle: function (params) {
        if (params.data.cost == "TOTAL : ") {
          return { color: "#ff7500" };
        }
      },
      valueFormatter: function (params) {
        if (params.value == undefined || params.value == "NaN") {
          return "n/a";
        }

        if (display == 1) {
          if (year == first) {
            return "n/a";
          }
          return "%" + params.value;
        } else if (display == 2) {
          if (year == first) {
            return "n/a";
          }
        }

        return numberDecimal.transform(
          Number(params.value),
          decimalFormat,
          numberFormat
        );
      },
    };
  }

  getYear(id: number) {
    //year -- id geliyor artık.
    if (id < 0) {
      this.headerName = "";
      this.columnDefs.push(this.getYearColumnDef(id));
    } else {
      this.allPlansPeriods.forEach((ele) => {
        if (id == ele.id) {
          this.guidanceService
            .getById(ele.guidanceId)
            .subscribe((result: any) => {
              this.headerName = result.planningCycleName + " " + ele.year;
              this.columnDefs.push(this.getYearColumnDef(id));
              return;
            });
        }
      });
    }
  }

  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  changeVolume() {
    var dummyList = [];

    this.fullData.forEach((line) => {
      var fake_line = { ...line };
      if (line.subCategoryId == null && line.costId == null) {
        //cat line
        this.translate.get(`category.${line.cost}`).subscribe((data: any) => {
          fake_line.cost = data;
        });
      } else if (line.subCategoryId != null && line.costId == null) {
        // sub cat line
        this.translate
          .get(`subcategory.${line.cost}`)
          .subscribe((data: any) => {
            fake_line.cost = data;
          });
      }
      dummyList.push(fake_line);
    });

    this.fullData = dummyList;
    this.fullData4Import = [...dummyList];
    this.rowData = [...dummyList].filter((x) => x.isVisible);

    this.columnDefs = [];
    this.columnDefs.push(this.getProductDef());
    let colDef = [];
    colDef.push(this.getProductDef());
    this.years.forEach((year) => {
      this.columnDefs.push(this.getYearColumnDef(year));
    });
    this.gridApi.setColumnDefs(colDef);
  }

  setScenario() {
    for (let sc of Object.keys(this.scenarioList)) {
      if (this.scenarioId == this.scenarioList[sc].id) {
        this.file_name = "Cost-" + this.scenarioList[sc].name + ".xlsx";
        return this.scenarioList[sc].name;
      }
    }
  }

  setExportData() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      return;
    }

    let emptyRow: any[];
    let scenario: any[] = ["", "Scenario Name : " + " " + this.setScenario()];
    let guide: any[] = [
      "",
      "Excel User Guide : On This Excel Sheet Change Only Costs. Do not Remove Any Column or Row and Do not Change Their Names.",
    ];

    let header = ["SalesForecastId"];

    let header2 = this.gridColumnApi
      .getAllDisplayedColumns()
      .map(function (col) {
        return col.userProvidedColDef.headerName;
      });

    header2.forEach((element) => {
      header.push(element);
    });

    this.data2Exel = [];
    this.data2Exel.push(header);

    this.fullData.forEach((element) => {
      this.salesForecastId = element.id;

      if (element.subCategoryId != null && element.costId == null) {
        this.exelName = " " + " " + " " + " " + " " + " " + element.cost;
      } else if (element.costId != null) {
        this.exelName =
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          " " +
          element.cost;
      } else {
        this.exelName = element.cost;
      }

      let row: any[] = [this.salesForecastId, this.exelName];
      for (let year of this.years) {
        if ((element[`total_${year}`] || element[`total_${year}`] == 0) && year != this.firstYear) {
          row.push(element[`total_${year}`]);
        } else {
          row.push(0);
        }
      }
      this.data2Exel.push(row);
    });
    this.data2Exel.push(emptyRow);
    this.data2Exel.push(scenario);
    this.data2Exel.push(guide);

    this.export();
  }

  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  //fileName: string = 'forecast.xlsx';

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2Exel, {
      cellStyles: true,
    });

    ws["B1"].s = {};
    ws["B1"].s = { fill: { bgColor: { rgb: "ff6600" } } };

    /*hide salesPriceID column*/
    ws["!cols"] = [];
    ws["!cols"][0] = { hidden: true };
    ws["!cols"][1] = { wpx: 200 };

    ws["!rows"] = [];
    ws["!rows"][0] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 2] = { hpx: 35 };
    ws["!rows"][this.forecastLenght + 3] = { hpx: 35 };

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.sheetName);

    /* save to file */
    XLSX.writeFile(wb, this.file_name);
  }

  onFileAdd(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];

      if ((arraylist.length - 2) % this.forecastLenght === 0) {
        this.fullData4Import = arraylist;
        for (let row in this.fullData) {
          for (let year of this.years) {
            this.fullData[row][`col_${year}_1`] =
              this.fullData4Import[row][`${year} Jan`];
            this.fullData[row][`col_${year}_2`] =
              this.fullData4Import[row][`${year} Feb`];
            this.fullData[row][`col_${year}_3`] =
              this.fullData4Import[row][`${year} Mar`];
            this.fullData[row][`col_${year}_4`] =
              this.fullData4Import[row][`${year} Apr`];
            this.fullData[row][`col_${year}_5`] =
              this.fullData4Import[row][`${year} May`];
            this.fullData[row][`col_${year}_6`] =
              this.fullData4Import[row][`${year} Jun`];
            this.fullData[row][`col_${year}_7`] =
              this.fullData4Import[row][`${year} Jul`];
            this.fullData[row][`col_${year}_8`] =
              this.fullData4Import[row][`${year} Aug`];
            this.fullData[row][`col_${year}_9`] =
              this.fullData4Import[row][`${year} Sep`];
            this.fullData[row][`col_${year}_10`] =
              this.fullData4Import[row][`${year} Oct`];
            this.fullData[row][`col_${year}_11`] =
              this.fullData4Import[row][`${year} Nov`];
            this.fullData[row][`col_${year}_12`] =
              this.fullData4Import[row][`${year} Dec`];
          }
        }
        let row2go = [...this.fullData];
        this.gridApi.setRowData(row2go);
      } else {
        this.toastrService.error(
          "The Excel That Imported is Corrupted or not Match, Export a New Exel and Import Again."
        );
      }
    };
  }

  onCellClicked(event) {
    console.log(event);
    if (event.column.colDef.field != "cost") {
      return;
    }

    var parentId = event.data.id;
    var rows = [...this.fullData];

    let currentRow = rows.filter((row) => row.id == parentId)[0];
    let isOpen = currentRow.selected;

    let currentRows = rows.filter((row) => row.parentId == parentId);
    currentRows.forEach(function (row) {
      row.isVisible = !isOpen;
      if (isOpen) {
        row.selected = false;
        let subRows = rows.filter((r) => r.parentId == row.id);
        subRows.forEach(function (row) {
          row.isVisible = false;
        });
      }
    });
    currentRow.selected = !isOpen;
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  openRows() {
    // rowların hepsini açma
    var rows = [...this.fullData];

    this.isOpen = !this.isOpen;
    var value = this.isOpen;

    rows.forEach(function (row) {
      if (row.parentId != 0) {
        row.isVisible = value;
        row.selected = value;
      } else {
        row.selected = value;
      }
    });
    rows = rows.filter((row) => row.isVisible || row.parentId == 0);
    this.gridApi.setRowData(rows);
  }

  currentContextEvent: any;
  onCellContextMenu(event) {
    this.currentContextEvent = event;
  }

  refreshRowTotal(data, year, month) {
    data[`total_${year}`] = 0;
    for (month = 1; month < 13; month++) {
      data[`total_${year}`] += Number(data[`col_${year}_${month}`]);
    }
    data[`total_${year}`] = data[`total_${year}`].toFixed(2);
  }

  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === "undefined" ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement("input");
      this.eInput.style.width = "100%";
      this.eInput.style.height = "100%";
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      var that = this;
      this.eInput.addEventListener("keypress", function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        //this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log("NumericCellEditor.focusIn()");
    };
    NumericCellEditor.prototype.focusOut = function () {
      console.log("NumericCellEditor.focusOut()");
    };
    return NumericCellEditor;
  }
}
