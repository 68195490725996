<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="div" style="width: 12%">
            <mat-card-title
              >{{ "reports.salesTrend" | translate }} <br />
              <span style="font-size: 15px"
                >{{ companyNumberFormat }} {{ companyCurrency }}</span
              ></mat-card-title
            >
          </div>

          <!-- SCENARIO -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setScenarioLists()"
                [(ngModel)]="scenarioId"
                placeholder="{{ 'reports.scenario' | translate }}"
                [formControl]="scenarioControl"
                required
              >
                <mat-option
                  *ngFor="let o of scenarioList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scenarioControl.hasError('required')">{{
                "reports.scenarioChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- GRANULARITY -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                placeholder="{{ 'reports.granularity' | translate }}"
                [(ngModel)]="granularity"
                [formControl]="granularityControl"
                required
              >
                <mat-option
                  *ngFor="let o of granularityList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="granularityControl.hasError('required')">{{
                "reports.granularityChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- 0 -->
          <!-- BUSINESS UNIT IF GRANULARIY BU -->
          <div class="div" style="width: 10%" *ngIf="granularity == 0">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setProductFamilyBU()"
                placeholder="{{ 'reports.businessUnit' | translate }}"
                [(ngModel)]="businessUnitId"
                multiple
                [formControl]="buControl"
                required
              >
                <mat-option
                  *ngFor="let o of businessUnitList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="buControl.hasError('required')">{{
                "reports.businessUnitChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- PRODUCT FAMILY IF GRANULARIY BU -->
          <div class="div" style="width: 10%" *ngIf="granularity == 0">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="!this.businessUnitId[1]"
                placeholder="{{ 'reports.productFamily' | translate }}"
                [(ngModel)]="productFamilyBUId"
                [formControl]="pfBuControl"
                required
              >
                <mat-option
                  *ngFor="let o of productFamilyBUList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-select
                *ngIf="this.businessUnitId[1]"
                placeholder="{{ 'reports.productFamily' | translate }}"
                [(ngModel)]="productFamilyBUId"
                disabled
              >
                <mat-option
                  *ngFor="let o of productFamilyBUList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="
                  scenarioControl.hasError('required') &&
                  !this.businessUnitId[1]
                "
                >{{ "reports.productFamilyChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <!-- /0 -->

          <!-- 1 -->
          <!-- PRODUCT FAMILY IF GRANULARIY PRODUCT FAMILY -->
          <div class="div" style="width: 10%" *ngIf="granularity == 1">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setProductPF()"
                placeholder="{{ 'reports.productFamily' | translate }}"
                [(ngModel)]="productFamilyId"
                multiple
                [formControl]="pfControl"
                required
              >
                <mat-option
                  *ngFor="let o of productFamilyList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="pfControl.hasError('required')">{{
                "reports.productFamilyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- PRODUCT IF GRANULARIY PRODUCT FAMILY -->
          <div class="div" style="width: 10%" *ngIf="granularity == 1">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="!this.productFamilyId[1]"
                placeholder="{{ 'reports.product' | translate }}"
                [(ngModel)]="productPFId"
                [formControl]="productPfControl"
                required
              >
                <mat-option
                  *ngFor="let o of productPFList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-select
                *ngIf="this.productFamilyId[1]"
                placeholder="{{ 'reports.product' | translate }}"
                [(ngModel)]="productPFId"
                disabled
              >
                <mat-option
                  *ngFor="let o of productPFList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="
                  productPfControl.hasError('required') &&
                  !this.productFamilyId[1]
                "
                >{{ "reports.productChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <!-- /1 -->

          <!-- 2 -->
          <!-- PRODUCT IF GRANULARIY PRODUCT -->
          <div class="div" style="width: 10%" *ngIf="granularity == 2">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setFormP()"
                placeholder="{{ 'reports.product' | translate }}"
                [(ngModel)]="productId"
                multiple
                [formControl]="productControl"
                required
              >
                <mat-option
                  *ngFor="let o of productList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="productControl.hasError('required')">{{
                "reports.productChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- FORM IF GRANULARIY PRODUCT -->
          <div class="div" style="width: 10%" *ngIf="granularity == 2">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="!this.productId[1]"
                placeholder="{{ 'reports.form' | translate }}"
                [(ngModel)]="formPId"
                [formControl]="formControl"
                required
              >
                <mat-option
                  *ngFor="let o of formPList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-select
                *ngIf="this.productId[1]"
                placeholder="Form"
                [(ngModel)]="formPId"
                disabled
              >
                <mat-option
                  *ngFor="let o of formPList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="formControl.hasError('required') && !this.productId[1]"
                >{{ "reports.formChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <!-- /2 -->

          <!-- 3 -->
          <!-- CHANNEL IF GRANULARIY CHANNEL -->
          <div class="div" style="width: 10%" *ngIf="granularity == 3">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setBuC()"
                placeholder="{{ 'reports.channel' | translate }}"
                [(ngModel)]="channelId"
                multiple
                [formControl]="channelControl"
                required
              >
                <mat-option
                  *ngFor="let o of channelList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="channelControl.hasError('required')">{{
                "reports.channelChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- BU IF GRANULARIY CHANNEL -->
          <div class="div" style="width: 10%" *ngIf="granularity == 3">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="!this.channelId[1]"
                placeholder="{{ 'reports.businessUnit' | translate }}"
                [(ngModel)]="businessUnitCId"
                [formControl]="buChControl"
                required
              >
                <mat-option
                  *ngFor="let o of businessUnitCList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-select
                *ngIf="this.channelId[1]"
                placeholder="{{ 'reports.businessUnit' | translate }}"
                [(ngModel)]="businessUnitCId"
                disabled
              >
                <mat-option
                  *ngFor="let o of businessUnitCList"
                  [value]="o.id"
                  value="option"
                >
                  {{ o.name }}
                </mat-option>
              </mat-select>

              <mat-error
                *ngIf="buChControl.hasError('required') && !this.channelId[1]"
                >{{ "reports.businessUnitChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <!-- /3 -->

          <!-- INDICATOR -->
          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                (selectionChange)="setPeriod()"
                [(ngModel)]="indicator"
                placeholder="{{ 'reports.indicator' | translate }}"
                [formControl]="indicatorControl"
                required
              >
                <mat-option
                  *ngFor="let o of indicatorList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="indicatorControl.hasError('required')">{{
                "reports.indicatorChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                [(ngModel)]="timescale"
                placeholder="{{ 'reports.timeScale' | translate }}"
                [formControl]="scaleControl"
                required
              >
                <mat-option
                  *ngFor="let o of timescaleList"
                  [value]="o.value"
                  value="option"
                >
                  <span *ngIf="'reports.' + o.label as variable">{{
                    variable | translate
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="scaleControl.hasError('required')">{{
                "reports.timeScaleChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="timescale == 0"
                [(ngModel)]="period"
                placeholder="{{ 'reports.period' | translate }}"
                [formControl]="periodControl"
                required
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-select
                *ngIf="timescale == 1"
                [(ngModel)]="period"
                placeholder="{{ 'reports.period' | translate }}"
              >
                <mat-optgroup
                  *ngFor="let group of periodGroup"
                  [label]="group.name"
                  disabled
                >
                  <mat-option
                    *ngFor="let period of group.period"
                    [value]="period"
                  >
                    {{ period.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
              <mat-error
                *ngIf="periodControl.hasError('required') && timescale == 0"
                >{{ "reports.periodChoose" | translate }}</mat-error
              >
            </mat-form-field>
          </div>

          <div class="div" style="width: 10%">
            <mat-form-field style="width: 85%">
              <mat-select
                *ngIf="indicator != 0"
                name="currency"
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-select
                *ngIf="indicator == 0"
                name="currency"
                [(ngModel)]="currency"
                placeholder="{{ 'reports.currency' | translate }}"
                [formControl]="currencyControl"
                required
                disabled
              >
                <mat-option [value]="0" value="option">{{
                  "reports.defaultCurrency" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let o of guidanceFxRateList"
                  [value]="o.fxRateId"
                  value="option"
                >
                  {{ getFxRate(o.fxRateId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyControl.hasError('required')">{{
                "reports.currencyChoose" | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="div" style="width: 8%">
            <div style="width: 85%">
              <button color="accent" (click)="isValid()" mat-button>
                {{ "reports.run" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div>
          <div class="div" style="width: 10%"></div>
          <div class="div" style="width: 80%" #screen>
            <canvas
              *ngIf="isRun == true"
              baseChart
              [datasets]="barChartData"
              [labels]="reportData.chartLabels"
              [options]="barChartOptions"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
          </div>
        </div>
        <span *ngIf="isRun != true" style="width: 60%; float: right">{{
          "reports.seeChart" | translate
        }}</span>

        <br />
        <br />
        <br />

        <button (click)="take()" color="accent" mat-raised-button>
          <mat-icon>camera_alt</mat-icon>&nbsp;{{
            "reports.take" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="reset()" color="accent" mat-raised-button>
          <mat-icon>delete_sweep</mat-icon>&nbsp;{{
            "reports.reset" | translate
          }}</button
        >&nbsp;
        <button *ngIf="img" (click)="save()" color="accent" mat-raised-button>
          <mat-icon>save</mat-icon>&nbsp;{{ "reports.save" | translate }}
        </button>

        <br />
        <br />
        <img *ngIf="img" src="{{ img }}" style="width: 50%; height: 50%" />
      </mat-card-content>
    </mat-card>

    <!--<mat-card *ngIf="guidanceIsStart==null">
          <mat-card-content style="min-height: 200px;">
            <div>
              <div class="div" style="width: 50%;">
                <mat-card-title>Sales Trend</mat-card-title>
              </div>
            </div>
            <br>
            <br>
            <span *ngIf="isRun!=true" style="width: 60%; float: right;">Guidance not started yet.</span>
          </mat-card-content>
      </mat-card>-->
  </div>
</div>
