import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { DateAdapter } from "@angular/material/core";
import { ApproverRequestService } from "@planard/@core/backend/service/approverRequest.service";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  templateUrl: "./sales-approvals.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 15%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: initial;
      }
    `,
  ],
})
export class SalesApprovalsComponent implements OnInit {
  displayedColumns: string[] = [
    "type",
    "scenarioStr",
    "submitUser",
    "submitDate",
    "approveUser",
    "ApproveDate",
    "state",
    "actions",
  ];
  dataSource: MatTableDataSource<any>;
  controls: any[];
  isRoleValid: boolean;
  companyPackage: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public approverRequestService: ApproverRequestService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    public translate: TranslateService,
    public authService: AuthService,
    public toastrService: ToastrService
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy

    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isBusinessUnitApprover();
      this.companyPackage = this.authService.getCompanyPackage();
      if (this.isRoleValid == false || this.companyPackage == "basic")
        this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.approverRequestService.listAllSales().subscribe((result) => {
      this.dataSource = new MatTableDataSource(result.data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "type", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = result.data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              type: new FormControl(entity.type, [Validators.required]),
            },
            { updateOn: "blur" }
          ),
        };
      });
    });
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
