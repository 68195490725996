import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { SubdepartmentAddComponent } from "./add/subdepartment-add.component";
import { Department } from "@planard/@core/entities/department";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./subdepartment-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
    `,
  ],
})
export class SubdepartmentNewComponent implements OnInit {
  displayedColumns: string[] = ["name", "departmentId", "actions"];
  dataSource: MatTableDataSource<SubDepartment>;
  controls: any[];
  isRoleValid: boolean;

  departmentList: Department[];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public departmentService: DepartmentService,
    public authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.departmentService.listAll().subscribe((result) => {
      this.departmentList = result;
    });
    this.departmentService.listAllSub().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              departmentId: new FormControl("", [Validators.required]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var subdepartment = this.getElement(index);
      subdepartment[field] = control.value;
      this.departmentService.updateSub(subdepartment).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    const dialogRef = this.dialog.open(SubdepartmentAddComponent, {
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  onDelete(row: any) {
    this.translate.get("delete.subdepartment").subscribe((data: any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.departmentService.deleteSub(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
              this.ngOnInit();
            }
          });
        }
      });
    });
  }

  getDepartment(id: number) {
    if (id != null) {
      for (let dp of Object.keys(this.departmentList)) {
        if (this.departmentList[dp].id === id) {
          return this.departmentList[dp].name;
        }
      }
    }
  }
}
