import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { Scenario } from "@planard/@core/entities/scenario";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { AddScenarioComponent } from "./add/add-scenario.component";
import { Guidance } from "@planard/@core/entities/guidance";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatSelect } from "@angular/material/select";
import { removeSpaces } from "@planard/common/formValidator";

@Component({
  templateUrl: "./scenario-new.component.html",
  styles: [
    `
      .toRight {
        flex: 0 0 5%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: initial;
      }
    `,
  ],
})
export class ScenarioNewComponent implements OnInit {
  displayedColumns: string[] = [
    "name",
    "impactedProducts",
    "impactArea",
    "description",
    "actions",
  ];
  dataSource: MatTableDataSource<Scenario>;
  controls: any[];
  guidance: Guidance;
  guidanceStart: boolean;
  isRoleValid: boolean;
  impactAreaList = ["P", "V", "C"];
  productList: Products[];
  companyPackage: string;
  scenariocount: number;
  lang;

  allProducts: boolean;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRefs: ChangeDetectorRef,
    public scenarioService: ScenarioService,
    public guidanceService: GuidanceService,
    public productsService: ProductsService,
    public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    public toastrService: ToastrService
  ) {
    this.lang = this.translate.currentLang;

    translate.onLangChange.subscribe((lang) => {
      this.lang = this.translate.currentLang;
      this.ngOnInit();
    });

    this.authService.getAppUser();
    setTimeout(() => {
      this.isRoleValid = this.authService.isAdmin();
      this.companyPackage = this.authService.getCompanyPackage();
      if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
    }, 150);
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((result) => {
      this.guidance = result;
      if (this.guidance.start != null) {
        this.guidanceStart = true;
      } else {
        this.guidanceStart = false;
      }
    });
    this.productsService.listAll().subscribe((result) => {
      this.productList = result;
    });
    this.scenarioService.listAll().subscribe((data) => {
      data.forEach((element) => {
        if (this.lang == "tr") {
          if (element.impactArea == "PVC")
            element.imapctAreaStr = "Fiyat, Hacim, Masraf";
          if (element.impactArea == "PV")
            element.imapctAreaStr = "Fiyat, Hacim";
          if (element.impactArea == "PC")
            element.imapctAreaStr = "Fiyat, Masraf";
          if (element.impactArea == "VC")
            element.imapctAreaStr = "Hacim, Masraf";
          if (element.impactArea == "P") element.imapctAreaStr = "Fiyat";
          if (element.impactArea == "V") element.imapctAreaStr = "Hacim";
          if (element.impactArea == "C") element.imapctAreaStr = "Masraf";
        } else {
          if (element.impactArea == "PVC")
            element.imapctAreaStr = "Price, Volume, Cost";
          if (element.impactArea == "PV")
            element.imapctAreaStr = "Price, Volume";
          if (element.impactArea == "PC") element.imapctAreaStr = "Price, Cost";
          if (element.impactArea == "VC")
            element.imapctAreaStr = "Volume, Cost";
          if (element.impactArea == "P") element.imapctAreaStr = "Price";
          if (element.impactArea == "V") element.imapctAreaStr = "Volume";
          if (element.impactArea == "C") element.imapctAreaStr = "Cost";
        }

        element.impactArea = this.getImpactAreaEdit(element.impactArea);
      });

      this.dataSource = new MatTableDataSource(data);
      this.scenariocount = data.length;

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string" &&
          data[sortHeaderId] != "impactArea"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              impactedProducts: new FormControl(entity.impactedProducts, [
                Validators.required,
              ]),
              impactArea: new FormControl(entity.impactArea, [
                Validators.required,
              ]),
              description: new FormControl(entity.description, [
                Validators.required,
                removeSpaces,
              ]),
            },
            { updateOn: "change" }
          ),
        };
      });
    });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      var scenario = this.getElement(index);
      scenario[field] = control.value;

      if (control.value == null || control.value == []) {
        this.translate.get("scenario.value").subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }

      var price = false;
      var volume = false;
      var cost = false;

      if (scenario.impactArea.includes("P")) price = true;
      if (scenario.impactArea.includes("V")) volume = true;
      if (scenario.impactArea.includes("C")) cost = true;

      var newImpactArea;
      if (price && volume && cost) newImpactArea = "PVC";
      if (price && volume && !cost) newImpactArea = "PV";
      if (price && !volume && cost) newImpactArea = "PC";
      if (!price && volume && cost) newImpactArea = "VC";
      if (price && !volume && !cost) newImpactArea = "P";
      if (!price && volume && !cost) newImpactArea = "V";
      if (!price && !volume && cost) newImpactArea = "C";

      scenario.impactArea = newImpactArea;
      if (scenario.impactArea == null) return;

      this.scenarioService.update(scenario).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.startCycleAuto();
              this.toastrService.success(data);
            });
          this.ngOnInit();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDialog() {
    if (
      (this.companyPackage == "premium" && this.scenariocount < 8) ||
      (this.companyPackage == "basic" && this.scenariocount < 3)
    ) {
      const dialogRef = this.dialog.open(AddScenarioComponent, {
        width: "500px",
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ngOnInit();
        }
      });
    } else {
      this.translate.get("scenario.max").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  onDelete(row: any) {
    this.translate.get("delete.scenario").subscribe((data: any) => {
      if (row.name == "Base Scenario") {
        this.translate.get("scenario.cannotDelete").subscribe((data: any) => {
          this.toastrService.info(data);
        });
        return;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.scenarioService.delete(row.id).subscribe((result) => {
            if (result.isSuccess) {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.success(data);
                });
              this.ngOnInit();
            } else {
              this.translate
                .get(`messages.${result.messageId}`)
                .subscribe((data: any) => {
                  this.toastrService.error(data);
                });
            }
          });
        }
      });
    });
  }

  /*getModelImpactArea(impact: string){
    var Arr = impact.split("");
    var impactArea = [];
    Arr.forEach(ele => {
      if ( ele == 'P' ){
        impactArea.push(EnumImpactArea.PRICE);
      }else if ( ele == 'V' ){
        impactArea.push(EnumImpactArea.VOLUME);
      }else if ( ele == 'C' ){
        impactArea.push(EnumImpactArea.COST);
      }
    });
    return impactArea;
  }*/

  /*getImpactArea(impact: string){
    var Arr = impact.split("");
    var impactArea = [];
    Arr.forEach(ele => {
      if ( ele == 'P' ){
        impactArea.push('Price');
      }else if ( ele == 'V' ){
        impactArea.push('Volume');
      }else if ( ele == 'C' ){
        impactArea.push('Cost');
      }
    });
    var Str = impactArea.join(', ');
    return impactArea;
  }*/

  getImpactAreaEdit(impact: string): Array<string> {
    return impact.split("");
  }

  outToastr(msg: string) {
    this.translate.get(`${msg}`).subscribe((data: any) => {
      this.toastrService.error(data);
    });
  }

  selectAll(index, select: MatSelect) {
    var control = this.getControl(index, "impactedProducts");
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    control.setValue(selectedIds);
    this.allProducts = true;
  }

  deselectOne(id: number, select: MatSelect) {
    var control = this.getControl(id, "impactedProducts");
    if (this.isAll(control) == true) {
      this.allProducts = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      control.setValue(selectedIds);
    }
  }

  isAll(selectedChannels: any) {
    if (selectedChannels.length == this.productList.length) return true;
    else return false;
  }

  deselectAll(id) {
    var control = this.getControl(id, "impactedProducts");
    let selectedIds = [];
    control.setValue(selectedIds);
  }

  startCycleAuto() {
    let body = { guidanceId: this.guidance.id };
    this.guidanceService.startGuidance(body).subscribe();
  }

  getProduct(areas: any) {
    if (areas.includes("P") || areas.includes("V")) {
      return true;
    } else return false;
  }
}
