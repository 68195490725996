import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Process } from "@planard/@core/entities/process";
import { TimelineService } from "@planard/@core/backend/service/timeline.service";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { EnumTask } from "@planard/@core/enums/EnumTask";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { Role } from "@planard/@core/entities/role";
import { AuthService } from "@planard/auth/services/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-annotation";
@Component({
  templateUrl: "./timeline.component.html",
})
export class TimelineComponent implements OnInit {
  taskList: EnumTask[] = EnumTask.values;
  processList: Process[];
  data: any[] = [];
  dataList: Process[] = [];
  guidanceName: string;
  day: number;
  infdata: [];
  today: Date;
  dependencyList: Process[];
  dependency: string;
  todayDay: any;
  plantingDays;
  guidanceIsStart: boolean;
  run: boolean;
  roleList: Role[];
  MS_PER_DAY = 1000 * 60 * 60 * 24;
  data1 = [{}]; // kişinin kendi taskları gelicek.
  chartData;
  options;
  colorArr = [];
  days: number;
  companyPackage: string;

  PRICE_PLANNING;
  FORECAST_PLANNING;
  COST_PLANNING;
  CENTRAL_COST_PLANNING;
  RISK_OPPORTUNITIES;
  SALES_NARRATIVES;
  COST_NARRATIVES;
  PRICE_APPROVAL;
  FORECAST_APPROVAL;
  COST_APPROVAL;
  CENTRAL_COST_APPROVAL;

  StartDate;
  EndDate;
  RemainingDays;
  Dependency;
  ProcessRoles;

  name: string;
  all: string;
  own: string;
  todayS: string;

  January;
  February;
  March;
  April;
  May;
  June;
  July;
  August;
  September;
  October;
  November;
  December;

  constructor(
    public dialog: MatDialog,
    public toastrService: ToastrService,
    public roleService: RoleService,
    public guidanceService: GuidanceService,
    public timelineService: TimelineService,
    public translate: TranslateService,
    public authService: AuthService,
    private router: Router
  ) {
    this.authService.getAppUser();
    setTimeout(() => {
      this.companyPackage = this.authService.getCompanyPackage();
      if (this.companyPackage == "basic") this.router.navigate(["auth/404"]);
    }, 150);

    this.translate.get("instructionsTimeline.name").subscribe((data: any) => {
      this.name = data;
    });

    this.translate.get("instructionsTimeline.all").subscribe((data: any) => {
      this.all = data;
    });

    this.translate.get("instructionsTimeline.own").subscribe((data: any) => {
      this.own = data;
    });

    this.translate.get("instructionsTimeline.today").subscribe((data: any) => {
      this.todayS = data;
    });

    this.translate.get("enums.tasks.PRICE_PLANNING").subscribe((data: any) => {
      this.PRICE_PLANNING = data;
    });

    this.translate
      .get("enums.tasks.FORECAST_PLANNING")
      .subscribe((data: any) => {
        this.FORECAST_PLANNING = data;
      });

    this.translate.get("enums.tasks.COST_PLANNING").subscribe((data: any) => {
      this.COST_PLANNING = data;
    });

    this.translate
      .get("enums.tasks.CENTRAL_COST_PLANNING")
      .subscribe((data: any) => {
        this.CENTRAL_COST_PLANNING = data;
      });

    this.translate
      .get("enums.tasks.RISK_OPPORTUNITIES")
      .subscribe((data: any) => {
        this.RISK_OPPORTUNITIES = data;
      });

    this.translate
      .get("enums.tasks.SALES_NARRATIVES")
      .subscribe((data: any) => {
        this.SALES_NARRATIVES = data;
      });

    this.translate.get("enums.tasks.COST_NARRATIVES").subscribe((data: any) => {
      this.COST_NARRATIVES = data;
    });

    this.translate.get("enums.tasks.PRICE_APPROVAL").subscribe((data: any) => {
      this.PRICE_APPROVAL = data;
    });

    this.translate
      .get("enums.tasks.FORECAST_APPROVAL")
      .subscribe((data: any) => {
        this.FORECAST_APPROVAL = data;
      });

    this.translate.get("enums.tasks.COST_APPROVAL").subscribe((data: any) => {
      this.COST_APPROVAL = data;
    });

    this.translate
      .get("enums.tasks.CENTRAL_COST_APPROVAL")
      .subscribe((data: any) => {
        this.CENTRAL_COST_APPROVAL = data;
      });

    this.translate.get("timeline.startDate").subscribe((data: any) => {
      this.StartDate = data;
    });

    this.translate.get("timeline.endDate").subscribe((data: any) => {
      this.EndDate = data;
    });

    this.translate.get("timeline.RemainingDays").subscribe((data: any) => {
      this.RemainingDays = data;
    });

    this.translate.get("timeline.Dependency").subscribe((data: any) => {
      this.Dependency = data;
    });

    this.translate.get("timeline.Process Roles").subscribe((data: any) => {
      this.ProcessRoles = data;
    });

    this.translate.get("months.January").subscribe((data: any) => {
      this.January = data;
    });

    this.translate.get("months.February").subscribe((data: any) => {
      this.February = data;
    });

    this.translate.get("months.March").subscribe((data: any) => {
      this.March = data;
    });

    this.translate.get("months.April").subscribe((data: any) => {
      this.April = data;
    });

    this.translate.get("months.May").subscribe((data: any) => {
      this.May = data;
    });

    this.translate.get("months.June").subscribe((data: any) => {
      this.June = data;
    });

    this.translate.get("months.July").subscribe((data: any) => {
      this.July = data;
    });

    this.translate.get("months.August").subscribe((data: any) => {
      this.August = data;
    });

    this.translate.get("months.September").subscribe((data: any) => {
      this.September = data;
    });

    this.translate.get("months.October").subscribe((data: any) => {
      this.October = data;
    });

    this.translate.get("months.November").subscribe((data: any) => {
      this.November = data;
    });

    this.translate.get("months.December").subscribe((data: any) => {
      this.December = data;
    });

    translate.onLangChange.subscribe((lang) => {
      this.translate.get("instructionsTimeline.name").subscribe((data: any) => {
        this.name = data;
      });

      this.translate.get("instructionsTimeline.all").subscribe((data: any) => {
        this.all = data;
      });

      this.translate.get("instructionsTimeline.own").subscribe((data: any) => {
        this.own = data;
      });

      this.translate
        .get("instructionsTimeline.today")
        .subscribe((data: any) => {
          this.todayS = data;
        });

      this.translate
        .get("enums.tasks.PRICE_PLANNING")
        .subscribe((data: any) => {
          this.PRICE_PLANNING = data;
        });

      this.translate
        .get("enums.tasks.FORECAST_PLANNING")
        .subscribe((data: any) => {
          this.FORECAST_PLANNING = data;
        });

      this.translate.get("enums.tasks.COST_PLANNING").subscribe((data: any) => {
        this.COST_PLANNING = data;
      });

      this.translate
        .get("enums.tasks.CENTRAL_COST_PLANNING")
        .subscribe((data: any) => {
          this.CENTRAL_COST_PLANNING = data;
        });

      this.translate
        .get("enums.tasks.RISK_OPPORTUNITIES")
        .subscribe((data: any) => {
          this.RISK_OPPORTUNITIES = data;
        });

      this.translate
        .get("enums.tasks.SALES_NARRATIVES")
        .subscribe((data: any) => {
          this.SALES_NARRATIVES = data;
        });

      this.translate
        .get("enums.tasks.COST_NARRATIVES")
        .subscribe((data: any) => {
          this.COST_NARRATIVES = data;
        });

      this.translate
        .get("enums.tasks.PRICE_APPROVAL")
        .subscribe((data: any) => {
          this.PRICE_APPROVAL = data;
        });

      this.translate
        .get("enums.tasks.FORECAST_APPROVAL")
        .subscribe((data: any) => {
          this.FORECAST_APPROVAL = data;
        });

      this.translate.get("enums.tasks.COST_APPROVAL").subscribe((data: any) => {
        this.COST_APPROVAL = data;
      });

      this.translate
        .get("enums.tasks.CENTRAL_COST_APPROVAL")
        .subscribe((data: any) => {
          this.CENTRAL_COST_APPROVAL = data;
        });

      this.translate.get("timeline.startDate").subscribe((data: any) => {
        this.StartDate = data;
      });

      this.translate.get("timeline.endDate").subscribe((data: any) => {
        this.EndDate = data;
      });

      this.translate.get("timeline.RemainingDays").subscribe((data: any) => {
        this.RemainingDays = data;
      });

      this.translate.get("timeline.Dependency").subscribe((data: any) => {
        this.Dependency = data;
      });

      this.translate.get("timeline.Process Roles").subscribe((data: any) => {
        this.ProcessRoles = data;
      });

      this.translate.get("months.January").subscribe((data: any) => {
        this.January = data;
      });

      this.translate.get("months.February").subscribe((data: any) => {
        this.February = data;
      });

      this.translate.get("months.March").subscribe((data: any) => {
        this.March = data;
      });

      this.translate.get("months.April").subscribe((data: any) => {
        this.April = data;
      });

      this.translate.get("months.May").subscribe((data: any) => {
        this.May = data;
      });

      this.translate.get("months.June").subscribe((data: any) => {
        this.June = data;
      });

      this.translate.get("months.July").subscribe((data: any) => {
        this.July = data;
      });

      this.translate.get("months.August").subscribe((data: any) => {
        this.August = data;
      });

      this.translate.get("months.September").subscribe((data: any) => {
        this.September = data;
      });

      this.translate.get("months.October").subscribe((data: any) => {
        this.October = data;
      });

      this.translate.get("months.November").subscribe((data: any) => {
        this.November = data;
      });

      this.translate.get("months.December").subscribe((data: any) => {
        this.December = data;
      });

      this.createChart();
    });
  }

  ngOnInit() {
    this.guidanceService.getGuidance().subscribe((data) => {
      this.guidanceName = data.planningCycleName;
      this.guidanceIsStart = data.start ? true : false;
      if (this.guidanceIsStart == true) {
        this.getData();
      }
    });
  }

  getData() {
    this.roleService.list().subscribe((result) => {
      this.roleList = result;
    });
    this.timelineService.listUserProcesses().subscribe((result) => {
      this.dependencyList = result;
      this.data = result.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
      this.data.forEach((element) => {
        if (element.isOwn == true) {
          this.colorArr.push("orange");
        } else {
          this.colorArr.push("lightblue");
        }
      });
    });
    setTimeout(() => {
      this.plantingDays = new Date(this.data[0].startDate);
      this.days = this.dateDiffInDays(
        new Date(this.plantingDays),
        new Date(this.data[this.data.length - 1].endDate)
      );
      this.createChart();
    }, 1500);
  }

  createChart() {
    const that = this;
    this.chartData = {
      labels: this.data.map((t) =>
        t.customTask == null
          ? t.task == EnumTask.PRICE_PLANNING
            ? `${that.PRICE_PLANNING}`
            : t.task == EnumTask.FORECAST_PLANNING
            ? `${that.FORECAST_PLANNING}`
            : t.task == EnumTask.RISK_OPPORTUNITIES
            ? `${that.RISK_OPPORTUNITIES}`
            : t.task == EnumTask.SALES_NARRATIVES
            ? `${that.SALES_NARRATIVES}`
            : t.task == EnumTask.PRICE_APPROVAL
            ? `${that.PRICE_APPROVAL}`
            : t.task == EnumTask.FORECAST_APPROVAL
            ? `${that.FORECAST_APPROVAL}`
            : t.task == EnumTask.CENTRAL_COST_PLANNING
            ? `${that.CENTRAL_COST_PLANNING}`
            : t.task == EnumTask.COST_PLANNING
            ? `${that.COST_PLANNING}`
            : t.task == EnumTask.COST_NARRATIVES
            ? `${that.COST_NARRATIVES}`
            : t.task == EnumTask.CENTRAL_COST_APPROVAL
            ? `${that.CENTRAL_COST_APPROVAL}`
            : t.task == EnumTask.COST_APPROVAL
            ? `${that.COST_APPROVAL}`
            : t.task
          : t.customTask
      ),
      datasets: [
        {
          data: this.data.map((t) => {
            return this.dateDiffInDays(
              new Date(this.plantingDays),
              new Date(t.startDate)
            );
          }),
          label: "",
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "rgba(63,103,126,0)",
          hoverBackgroundColor: "rgba(50,90,100,0)",
        },
        {
          data: this.data.map((t) => {
            return this.dateDiffInDays(
              new Date(t.startDate),
              new Date(t.endDate)
            );
          }),
          label: "do not show",
          barThickness: 30,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: this.colorArr,
          fillStyle: "lightblue",
        },
        {
          data: this.data1,
          label: `${that.all}`,
          barThickness: 30,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "lightblue",
        },
        {
          data: this.data1,
          label: `${that.own}`,
          barThickness: 30,
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
          backgroundColor: "orange",
        },
        {
          label: `${that.todayS}`,
          xAxisID: "02",
          barPercentage: 0,
          //null,
          data: this.data.map((t) => {
            return this.dateDiffInDays(
              new Date(this.plantingDays),
              new Date(t.startDate)
            );
          }),
          backgroundColor: "red",
          datalabels: {
            color: "#025ced",
            formatter: function (value, context) {
              return "";
            },
          },
        },
      ],
    };

    this.options = {
      maintainAspectRatio: true,
      title: {
        display: true,
        fontSize: 17,
        text: that.name + " " + this.guidanceName,
      },
      legend: {
        onClick: false,
        position: "bottom",
        labels: {
          filter: function (item, chart) {
            return !item.text.includes("do not show");
          },
        },
      },
      tooltips: {
        mode: "index",
        callbacks: {
          label: function (tooltipItem) {
            let label = "";
            let months = [
              "",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            if (tooltipItem.datasetIndex == 1) {
              var start = new Date(that.data[tooltipItem.index].startDate);
              label += `${that.StartDate}: ${start.getDate()} ${that.getMonth(
                start
              )} ${start.getFullYear()} ,`;

              var end = new Date(that.data[tooltipItem.index].endDate);
              label += `${that.EndDate}: ${end.getDate()} ${that.getMonth(
                end
              )} ${end.getFullYear()} ,`;

              var rem = that.dateDiffInDays(
                new Date(that.data[tooltipItem.index].startDate),
                new Date(that.data[tooltipItem.index].endDate)
              );
              label += `${that.RemainingDays}:  ${rem} ,`;

              var depent = that.getDepent(
                that.data[tooltipItem.index].dependentProcessId
              );
              label += `${that.Dependency}: ${
                depent == undefined ? "-" : depent
              } ,`;
              label += `${that.ProcessRoles}: ${
                that.isAll(that.data[tooltipItem.index].selectedProcessRoles)
                  ? "All"
                  : that.data[tooltipItem.index].processRolesStr != ""
                  ? that.getRoles(that.data[tooltipItem.index].processRolesStr)
                  : "-"
              }`;

              return label;
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            id: "x-axis-0",
            max: this.days,
            ticks: {
              callback: function (value, index, values) {
                var date = new Date(that.plantingDays);
                var days: number = value * (1000 * 60 * 60 * 24);
                var newDate: any = date.getTime() + days;
                var finalDate: Date = new Date(newDate);
                this.days = values[values.length - 1];
                if (value.toFixed(2).split(".")[1] != "00") return "";
                return that.getDate(finalDate);
              },
            },
            distribution: "series",
            gridLines: {
              drawOnChartArea: true,
            },
          },
          {
            id: "02",
            type: "linear",
            gridLines: {
              drawBorder: false,
              color: "red",
            },
            position: "top",
            ticks: {
              scaleOverride: true,
              fontColor: "red",
              beginAtZero: true,
              stepSize: 1,
              suggestedMin: 0,
              suggestedMax: this.days,
              //steps : 70,
              callback: function (value, index, values) {
                var date = new Date(that.plantingDays);
                var days: number = value * (1000 * 60 * 60 * 24);
                var newDate: any = date.getTime() + days;
                var finalDate: Date = new Date(newDate);

                return new Date(finalDate).getDate() == new Date().getDate() &&
                  new Date(finalDate).getMonth() == new Date().getMonth() &&
                  new Date(finalDate).getFullYear() == new Date().getFullYear()
                  ? `${that.todayS}`
                  : null;
              },
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
    };

    this.run = true;
  }

  getMonth(date) {
    return date.getMonth() == 0
      ? `${this.January}`
      : date.getMonth() == 1
      ? `${this.February}`
      : date.getMonth() == 2
      ? `${this.March}`
      : date.getMonth() == 3
      ? `${this.April}`
      : date.getMonth() == 4
      ? `${this.May}`
      : date.getMonth() == 5
      ? `${this.June}`
      : date.getMonth() == 6
      ? `${this.July}`
      : date.getMonth() == 7
      ? `${this.August}`
      : date.getMonth() == 8
      ? `${this.September}`
      : date.getMonth() == 9
      ? `${this.October}`
      : date.getMonth() == 10
      ? `${this.November}`
      : date.getMonth() == 11
      ? `${this.December}`
      : date.getMonth();
  }

  getDate(date) {
    return (
      ("0" + date.getDate()).substr(-2) +
      " " +
      (date.getMonth() == 0
        ? `${this.January}`
        : date.getMonth() == 1
        ? `${this.February}`
        : date.getMonth() == 2
        ? `${this.March}`
        : date.getMonth() == 3
        ? `${this.April}`
        : date.getMonth() == 4
        ? `${this.May}`
        : date.getMonth() == 5
        ? `${this.June}`
        : date.getMonth() == 6
        ? `${this.July}`
        : date.getMonth() == 7
        ? `${this.August}`
        : date.getMonth() == 8
        ? `${this.September}`
        : date.getMonth() == 9
        ? `${this.October}`
        : date.getMonth() == 10
        ? `${this.November}`
        : date.getMonth() == 11
        ? `${this.December}`
        : date.getMonth()) +
      " " +
      date.getFullYear()
    );
  }

  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }

  getDepent(id: any) {
    if (id != null) {
      for (let dp of Object.keys(this.dependencyList)) {
        if (this.dependencyList[dp].id === id) {
          return this.dependencyList[dp].customTask == null
            ? this.dependencyList[dp].task == EnumTask.PRICE_PLANNING
              ? `${this.PRICE_PLANNING}`
              : this.dependencyList[dp].task == EnumTask.FORECAST_PLANNING
              ? `${this.FORECAST_PLANNING}`
              : this.dependencyList[dp].task == EnumTask.RISK_OPPORTUNITIES
              ? `${this.RISK_OPPORTUNITIES}`
              : this.dependencyList[dp].task == EnumTask.SALES_NARRATIVES
              ? `${this.SALES_NARRATIVES}`
              : this.dependencyList[dp].task == EnumTask.PRICE_APPROVAL
              ? `${this.PRICE_APPROVAL}`
              : this.dependencyList[dp].task == EnumTask.FORECAST_APPROVAL
              ? `${this.FORECAST_APPROVAL}`
              : this.dependencyList[dp].task == EnumTask.CENTRAL_COST_PLANNING
              ? `${this.CENTRAL_COST_PLANNING}`
              : this.dependencyList[dp].task == EnumTask.COST_PLANNING
              ? `${this.COST_PLANNING}`
              : this.dependencyList[dp].task == EnumTask.COST_NARRATIVES
              ? `${this.COST_NARRATIVES}`
              : this.dependencyList[dp].task == EnumTask.CENTRAL_COST_APPROVAL
              ? `${this.CENTRAL_COST_APPROVAL}`
              : this.dependencyList[dp].task == EnumTask.COST_APPROVAL
              ? `${this.COST_APPROVAL}`
              : "-"
            : this.dependencyList[dp].customTask;
        }
      }
    }
  }

  isAll(roles: any) {
    if (roles.length == this.roleList.length) {
      return true;
    } else {
      return false;
    }
  }

  getRoles(roles) {
    var roleArr = roles.split(", ");
    var newRoles = [];
    roleArr.forEach((element) => {
      this.translate.get(`processRoles.${element}`).subscribe((data: any) => {
        newRoles.push(data);
      });
    });
    return newRoles.join(", ");
  }
}
